import React from "react";

import { Grid } from "@material-ui/core";

import Completed from "./Completed";
import Live from "./Live";
import Upcoming from "./Upcoming";

export default function Leaderboard() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Upcoming />
      </Grid>
      <Grid item xs={12} md={4}>
        <Live />
      </Grid>
      <Grid item xs={12} md={4}>
        <Completed />
      </Grid>
    </Grid>
  );
}
