import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  makeStyles,
  createStyles,
  Theme,
  Box,
  Grid,
  Typography,
  Hidden,
} from "@material-ui/core";

import { ArrowBackRounded } from "@material-ui/icons";

import { listLeagues, fetchLeagueInfo } from "../../_redux/league";

import { ThumbsDown, ThumbsUp } from "../../_assets/Icons";
import { Tabs, Tab } from "../../Components";
import HeaderRight from "./HeaderRight";
import HeaderLeft from "./HeaderLeft";
import UnjoinedStrategy from "./UnjoinedStrategy";
import JoinedStrategy from "./JoinedStrategy";

import { useTypedSelector } from "../../_redux/rootReducer";
import { league } from "../../_redux/league/types";
import {
  makeLiveStrategiesForCategorySelector,
  makeLiveJoinedStrategiesForcategorySelector,
} from "../../_redux/league/selectors";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: "white",
      width: "100%",
    },
    backLink: {
      display: "flex",
      textDecoration: "none",
      color: "inherit",
    },
    lableText: {
      fontWeight: "bold",
    },
    tabRoot: {
      fontWeight: theme.typography.fontWeightMedium,
      color: "#576078",
    },
    tabSelected: {
      fontWeight: theme.typography.fontWeightBold,
      color: "white",
    },
    tabIcon: {
      marginLeft: theme.spacing(1),
    },
  })
);

function LiveLeagueDetail() {
  const classes = useStyles();
  const dispatch: AppDispatch = useDispatch();
  const { category } = useParams();
  const { t } = useTranslation("league");

  const [value, setValue] = useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(listLeagues());
  }, [dispatch]);

  const selectStrategies = useMemo(makeLiveStrategiesForCategorySelector, []);
  const selectJoinedStrategies = useMemo(
    makeLiveJoinedStrategiesForcategorySelector,
    []
  );
  const strategies = useTypedSelector((state) =>
    selectStrategies(state, category)
  );
  const joinedStrategies = useTypedSelector((state) =>
    selectJoinedStrategies(state, category)
  );
  const selectedStrat = useMemo<league | undefined>(() => strategies?.[value], [
    strategies,
    value,
  ]);
  const getIsJoined = useCallback(
    (strat: league | undefined) =>
      joinedStrategies.some((jStrat) => jStrat._id === strat?._id),
    [joinedStrategies]
  );
  const isJoined = useMemo(() => getIsJoined(selectedStrat), [
    getIsJoined,
    selectedStrat,
  ]);

  useEffect(() => {
    strategies.forEach((strat) => dispatch(fetchLeagueInfo(strat._id)));
  }, [dispatch, strategies]);

  const titleText = useMemo(() => {
    if (joinedStrategies.length === 0)
      return t("Oops! You are not a participant today");
    return t("liveLeaguePlayingWithCount", { count: joinedStrategies.length });
  }, [joinedStrategies.length, t]);

  if (!selectedStrat) return null;
  return (
    <div className={classes.root}>
      <Box display="flex" justifyContent="center" mb={{ xs: 2, md: 0 }}>
        <Link to="/leagues" className={classes.backLink}>
          <Box clone mr={1}>
            <ArrowBackRounded />
          </Box>
          <Typography>{t('Back to leagues')}</Typography>
        </Link>
      </Box>
      <Grid container>
        {/* Header Left */}
        <Grid item xs={12} sm={6} md={3}>
          <HeaderLeft strategy={selectedStrat} />
        </Grid>
        {/* Center title */}
        <Box clone order={{ xs: 3, md: 2 }}>
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="100%"
              py={1}
              color={joinedStrategies.length === 0 ? "#FC6E4F" : "inherit"}
            >
              <Hidden xsDown>
                <Typography variant="h5" align="center">
                  {titleText}
                </Typography>
              </Hidden>
              <Hidden smUp>
                <Typography variant="h6">{titleText}</Typography>
              </Hidden>
            </Box>
          </Grid>
        </Box>
        {/* Header Right */}
        <Box clone order={{ xs: 2, md: 3 }}>
          <Grid item xs={12} sm={6} md={3}>
            {isJoined && <HeaderRight strategyId={selectedStrat._id} />}
          </Grid>
        </Box>
      </Grid>
      <Tabs centered value={value} onChange={handleChange}>
        {strategies.map((x) => (
          <Tab
            key={x._id}
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
            label={
              <Box display="flex" alignItems="center">
                {`${t('translation:strategy')} ${x.strategies}`}
                {getIsJoined(x) ? (
                  <ThumbsUp fontSize="small" className={classes.tabIcon} />
                ) : (
                  <ThumbsDown fontSize="small" className={classes.tabIcon} />
                )}
              </Box>
            }
          />
        ))}
      </Tabs>
      {strategies.map((x, index) => (
        <div key={x._id} role="tabpanel" hidden={value !== index}>
          {getIsJoined(x) ? (
            <JoinedStrategy key={x._id} strategyId={x._id} />
          ) : (
            <UnjoinedStrategy key={x._id} strategyId={x._id} />
          )}
        </div>
      ))}
    </div>
  );
}

export default React.memo(LiveLeagueDetail);
