import React from "react";

import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function Twitter(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"> */}
        <path
          d="M10,8A10,10,0,1,0,20,18,10,10,0,0,0,10,8Zm4.911,6.851L13.27,22.585c-.121.548-.448.681-.9.423l-2.5-1.843L8.661,22.327a.631.631,0,0,1-.5.246l.177-2.544,4.633-4.185c.2-.177-.044-.278-.31-.1l-5.726,3.6-2.468-.77c-.536-.169-.548-.536.113-.794l9.641-3.718C14.665,13.9,15.056,14.173,14.911,14.851Z"
          transform="translate(0 -8)"
        />
      {/* </svg> */}
    </SvgIcon>
  );
}
