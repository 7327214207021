import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  makeStyles,
  createStyles,
  Theme,
  Box,
  Typography,
  Grid,
} from "@material-ui/core";

import { AnimatedPaper, Button } from "../../Components";

import { useTypedSelector } from "../../_redux/rootReducer";
import { makeLeagueInfoSelector } from "../../_redux/league/selectors";

interface UnjoinedLeagueProps {
  strategyId: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: theme.spacing(8, 0),
    },
    paperRoot: {
      color: "#fff",
      display: "flex",
      padding: theme.spacing(4),
    },
    fontBold: {
      fontWeight: theme.typography.fontWeightBold,
    },
  })
);

const UnjoinedStrategy: React.FC<UnjoinedLeagueProps> = (props) => {
  const { strategyId } = props;
  const classes = useStyles();
  const { t } = useTranslation("league");

  const selectLeagueInfo = useMemo(makeLeagueInfoSelector, []);
  const leagueInfo = useTypedSelector((state) =>
    selectLeagueInfo(state, strategyId)
  );

  const data = useMemo(
    () => [
      { label: "Participants", value: leagueInfo?.participants },
      { label: "Currencies", value: leagueInfo?.currencies },
      { label: "Transactions", value: leagueInfo?.transaction.length },
    ],
    [leagueInfo]
  );

  return (
    <div className={classes.root}>
      <Grid item xs={12} md={8} lg={6}>
        <AnimatedPaper classes={{ root: classes.paperRoot }}>
          <Grid container spacing={2}>
            {data.map(({ label, value }) => (
              <Grid key={label} item xs={12} sm={4}>
                <Typography
                  align="center"
                  variant="h5"
                  className={classes.fontBold}
                >
                  {t('Total')}
                  <br />
                  {t(label)}
                </Typography>
                <Box mt={{ xs: 1, sm: 4 }}>
                  <Typography
                    align="center"
                    variant="h4"
                    color="secondary"
                    className={classes.fontBold}
                  >
                    {value}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </AnimatedPaper>
      </Grid>
      <Box mt={4}>
        <Link to="/leagues" style={{ textDecoration: "none" }}>
          <Button variant="outlined" color="secondary" size="large">
            {t("Buy Tickets for upcoming leagues")}
          </Button>
        </Link>
      </Box>
    </div>
  );
};

export default React.memo(UnjoinedStrategy);
