import React, { useCallback, useMemo } from "react";
import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";

import {
  makeStyles,
  createStyles,
  Theme,
  Typography,
  InputAdornment,
  Button,
  DialogActions,
  DialogContent,
  Box,
} from "@material-ui/core";

import { LeagueInfo } from "../../../_redux/league/types";

import { Dialog } from "../../../Components";
import TextField from "./components/TextField";

import { SellFormValues } from "./index";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: theme.typography.fontWeightBold,
      color: "#fff",
    },
    content: {
      minWidth: 300,
    },
    adornment: { color: "#E4E7EA80" },
    actions: {
      justifyContent: "center",
    },
  })
);

interface SellDialogProps {
  sellCurr: string | null;
  handleClose: () => void;
  form: FormikProps<SellFormValues>;
  stratInfo: LeagueInfo;
  errors: { [x: string]: string };
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

const SellDialog: React.FC<SellDialogProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("league");
  const {
    handleClose,
    form,
    sellCurr,
    stratInfo,
    errors,
    activeStep,
    setActiveStep,
  } = props;
  const { baseCurrency = 'USD' } = stratInfo;

  const {
    handleSubmit,
    getFieldProps,
    isSubmitting,
    values,
    errors: formErrors,
    touched,
  } = form;
  const getError = useCallback(
    (field: keyof typeof form.initialValues) =>
      !!(formErrors[field] && touched[field]),
    [form, formErrors, touched]
  );
  const getHelperText = useCallback(
    (field: keyof typeof form.initialValues) =>
      touched[field] && formErrors[field],
    [form, formErrors, touched]
  );

  const currency = useMemo(
    () => stratInfo.currenciesData?.find((x) => x.alias === sellCurr) ?? null,
    [sellCurr, stratInfo.currenciesData]
  );
  const receive = useMemo(() => {
    if (baseCurrency === "USD")
      return Number(values.sell) * (currency?.price ?? 1);
    const baseCrrPrice =
      stratInfo.currenciesData?.find((x) => x.symbol === baseCurrency)?.price ??
      1;
    return (Number(values.sell) * (currency?.price ?? 1)) / baseCrrPrice;
  }, [baseCurrency, currency, stratInfo.currenciesData, values.sell]);

  const renderInput = useMemo(
    () => (
      <>
        {/* How much to sell? */}
        <TextField
          margin="normal"
          label={t("How much to sell?")}
          color="secondary"
          InputProps={{
            endAdornment: (
              <InputAdornment
                disableTypography
                className={classes.adornment}
                position="end"
              >
                <Typography variant="body2">
                  {t("Max")} {currency?.holding ?? 0} ({currency?.symbol})
                </Typography>
              </InputAdornment>
            ),
          }}
          fullWidth
          {...getFieldProps("sell")}
          error={getError("sell")}
          helperText={getHelperText("sell")}
        />
        {/* You will receive (BTC) */}
        <TextField
          margin="normal"
          label={`${t("You will receive")} (${currency?.symbol})`}
          color="secondary"
          helperText={t("This is approximate value")}
          disabled
          value={receive}
          InputProps={{
            endAdornment: (
              <InputAdornment disableTypography position="end">
                <Typography color="error" variant="body2">
                  1 {currency?.symbol} = ${(currency?.price ?? 0).toFixed(2)}
                </Typography>
              </InputAdornment>
            ),
          }}
          fullWidth
        />
      </>
    ),
    [
      classes.adornment,
      currency,
      getError,
      getFieldProps,
      getHelperText,
      receive,
      t,
    ]
  );
  const renderInputActions = useMemo(
    () => (
      <Button
        variant="contained"
        color="secondary"
        onClick={() => values.sell && setActiveStep(1)}
      >
        {t("translation:Proceed")}
      </Button>
    ),
    [setActiveStep, t, values.sell]
  );

  const renderConfirmation = useMemo(() => {
    return (
      <Typography>
        {t("Proceed with selling of", {
          sellAmount: values.sell,
          sellCurr,
          receiveAmount: receive.toFixed(baseCurrency === "USD" ? 2 : 8),
          baseCurrency,
        })}
      </Typography>
    );
  }, [baseCurrency, receive, sellCurr, t, values.sell]);
  const renderConfirmationActions = useMemo(
    () => (
      <>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setActiveStep(0)}
        >
          {t("translation:Cancel")}
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          disabled={isSubmitting}
        >
          {t("Sell now")}
        </Button>
      </>
    ),
    [isSubmitting, setActiveStep, t]
  );

  return (
    <Dialog
      open={!!sellCurr}
      title={
        <Typography variant="h5" className={classes.title} align="center">
          {t("SellCurrency", { currency: currency?.name ?? "" })}
        </Typography>
      }
      handleClose={handleClose}
    >
      <form onSubmit={handleSubmit}>
        <DialogContent className={classes.content}>
          {activeStep === 0 ? renderInput : renderConfirmation}
        </DialogContent>
        <DialogActions className={classes.actions}>
          {activeStep === 0 ? renderInputActions : renderConfirmationActions}
        </DialogActions>
        {/* Non Field Error */}
        <Typography align="center" color="error" variant="body2">
          {errors.msg}
        </Typography>
      </form>
      <Box mb={4} mt={2}>
        <Typography align="center" color="secondary" variant="body2">
          {t("Selling terms")}
        </Typography>
      </Box>
    </Dialog>
  );
};

export default React.memo(SellDialog);
