import React from "react";

import { Box, Grid } from "@material-ui/core";

import Summary from "./components/Summary";
import Leaderboard from "../../Common/LeaderBoard";

export default function GridThree() {
  return (
    <Box clone order={{ xs: 2, lg: 3 }} display="flex" flexDirection="column">
      <Grid item xs={12} sm={6} lg={3}>
        {/* hide on lg & larger */}
        <Box display={{ xs: "block", lg: "none" }} mb={2}>
          <Summary />
        </Box>
        <Leaderboard />
      </Grid>
    </Box>
  );
}
