import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { format } from "../../../_utils/date";

import {
  makeStyles,
  withStyles,
  createStyles,
  Theme,
  TableCell as MUITableCell,
  TableRow,
  Typography,
  Box,
  Avatar,
} from "@material-ui/core";

import { Ireferral } from "../../../_redux/auth/types";

const TableCell = withStyles(
  createStyles({
    root: {
      whiteSpace: "nowrap",
    },
  })
)(MUITableCell);

interface RowProps {
  row: Ireferral;
  index: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: (props: RowProps) => ({
      backgroundColor: props.index % 2 === 0 ? "#06022066" : "#130D4A66",
      "& > *": {
        borderBottom: "unset",
      },
    }),
    titleText: {
      color: "#fff",
      fontWeight: theme.typography.fontWeightBold,
    },
    subtitleText: {
      color: "#ffffff80",
    },
    fontBold: {
      fontWeight: theme.typography.fontWeightBold,
    },
  })
);

const Row: React.FC<RowProps> = (props) => {
  const { row } = props;
  const classes = useStyles(props);
  const { t } = useTranslation("referrals");

  const sale = useMemo(() => {
    const rate =
      row.referralType === "Level1"
        ? 12.5
        : row.referralType === "Level2"
        ? 7.5
        : 5;
    return (row.amount * 100) / rate;
  }, [row.amount, row.referralType]);
  return (
    <TableRow className={classes.root}>
      {/* Details */}
      <TableCell component="th" scope="row">
        <Box display="flex">
          <Avatar
            alt={row.userDetail?.[0].name}
            src={row.userDetail?.[0].profileImage ?? undefined}
          >
            {row.userDetail?.[0].name?.[0]}
          </Avatar>
          <Box ml={2}>
            <Typography className={classes.titleText}>
              {row.userDetail?.[0].name}
            </Typography>
            {/* <Typography variant="body2" className={classes.subtitleText}>
              Joined - {format(new Date(row.joinDate), "d MMM yyyy")}
            </Typography> */}
          </Box>
        </Box>
      </TableCell>
      {/* Sale */}
      <TableCell align="right">
        <Typography className={classes.titleText}>${sale}</Typography>
        <Typography variant="body2" color="secondary">
          USD
        </Typography>
      </TableCell>
      {/* Mode */}
      <TableCell>
        <Typography className={classes.titleText}>{t("Wallet")}</Typography>
      </TableCell>
      {/* Earned */}
      <TableCell align="right">
        <Typography className={classes.titleText}>${row.amount}</Typography>
        {/* <Typography variant="body2" className={classes.subtitleText}>
          {format(new Date(row.createdAt), "d MMM yyyy")}
        </Typography> */}
      </TableCell>
      {/* Status */}
      <TableCell className={classes.titleText}>
        <Typography className={classes.fontBold} color="error">
          {t("Pending")}
        </Typography>
        <Typography variant="body2" className={classes.subtitleText}>
          {t("ETADate", {
            date: format(new Date("2020-10-21"), "dd MMM yyyy"),
          })}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default React.memo(Row);
