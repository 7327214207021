export const RECEIVE_LEADERBOARD_DATA = "RECEIVE_LEADERBOARD_DATA";
export const RECEIVE_WINNERS_DATA = "RECEIVE_WINNERS_DATA";

export type leagueCategory = "Bronze" | "Silver" | "Gold";

export interface LeaderboardItem {
  _id: string;
  leagueCategory: leagueCategory;
  endDate: string;
  userId: {
    _id: string;
    name: string;
    profileImg: string;
  };
  strategyId: string;
  netWorth: number;
  tradeCount: number;
  rank: number;
  __v: number;
}

export interface LeagueWinnerItem {
  _id: string;
  leagueCategory: leagueCategory;
  endDate: string;
  userId: {
    _id: string;
    name: string;
    profileImg: string;
  };
  strategyId: string;
  netWorth: number;
  tradeCount: number;
  rank: number;
  __v: number;
}

interface ReceiveLeaderboardDataAction {
  type: typeof RECEIVE_LEADERBOARD_DATA;
  data: LeaderboardItem[];
  leagueCategory: leagueCategory;
  date: string;
}

interface ReceiveWinnersDataAction {
  type: typeof RECEIVE_WINNERS_DATA;
  data: {
    bronze: LeagueWinnerItem[];
    silver: LeagueWinnerItem[];
    gold: LeagueWinnerItem[];
  };
}

export type LeaderboardActionTypes =
  | ReceiveLeaderboardDataAction
  | ReceiveWinnersDataAction;
