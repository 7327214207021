import React, { useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

import { useTypedSelector } from "../../_redux/rootReducer";
import { signUp } from "../../_redux/auth";
import { selectRefCodeRquired } from "../../_redux/auth/selectors";

// MUI
import {
  Box,
  Typography,
  InputAdornment,
  Button,
  Link,
} from "@material-ui/core";
// Icons
import PersonOutline from "@material-ui/icons/PersonOutline";
import LockOutlined from "@material-ui/icons/LockOutlined";

import { TextField as CustomTextField } from "../../Components";

import useStylesBase from "./styles";

export default function Signup() {
  const classesBase = useStylesBase();
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const [errors, setErrors] = useState<ObjectStringKey>({});
  const { t } = useTranslation("auth");

  const refCodeRequired = useTypedSelector(selectRefCodeRquired);

  const form = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      passwordConfirm: "",
      refcode: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(),
      email: Yup.string()
        .email(
          t("translation:invalidMessage", { field: t("translation:email") })
        )
        .required(
          t("translation:requiredMessage", { field: t("translation:email") })
        ),
      password: Yup.string().required(
        t("translation:requiredMessage", { field: t("translation:password") })
      ),
      passwordConfirm: Yup.string()
        .oneOf([Yup.ref("password")], t("passwordsMismatch"))
        .required(
          t("translation:requiredMessage", { field: t("confirmPassword") })
        ),
      refcode: refCodeRequired
        ? Yup.string().required(
            t("translation:requiredMessage", { field: t("referralCode") })
          )
        : Yup.string().notRequired(),
    }),
    onSubmit: async (values) => {
      // console.log(values);
      const error = await dispatch(signUp(values));
      // console.log(error);
      if (error) return setErrors(error);
      history.push({
        pathname: "/auth/verify",
        state: { email: values.email },
      });
    },
  });

  const {
    errors: formErrors,
    touched,
    getFieldProps,
    handleSubmit,
    isSubmitting,
  } = form;
  const getError = (field: keyof typeof form.initialValues) =>
    !!(formErrors[field] && touched[field]);
  const getHelperText = (field: keyof typeof form.initialValues) =>
    touched[field] && formErrors[field];

  return (
    <form className={classesBase.root} onSubmit={handleSubmit} noValidate>
      <Typography className={classesBase.title} component="h1" align="center">
        {t("signUp")}
      </Typography>
      {/* Name */}
      <CustomTextField
        fullWidth
        variant="outlined"
        margin="dense"
        placeholder={t("yourName")}
        autoComplete="name"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PersonOutline />
            </InputAdornment>
          ),
        }}
        error={getError("name")}
        helperText={getHelperText("name")}
        {...getFieldProps("name")}
      />
      {/* Email */}
      <CustomTextField
        fullWidth
        variant="outlined"
        margin="dense"
        placeholder={t("translation:email")}
        autoComplete="email"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PersonOutline />
            </InputAdornment>
          ),
        }}
        error={getError("email")}
        helperText={getHelperText("email")}
        {...getFieldProps("email")}
      />
      {/* Password */}
      <CustomTextField
        fullWidth
        type="password"
        variant="outlined"
        margin="dense"
        placeholder={t("translation:password")}
        autoComplete="new-password"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockOutlined />
            </InputAdornment>
          ),
        }}
        error={getError("password")}
        helperText={getHelperText("password")}
        {...getFieldProps("password")}
      />
      {/* Confirm Password */}
      <CustomTextField
        fullWidth
        type="password"
        variant="outlined"
        margin="dense"
        placeholder={t("confirmPassword")}
        autoComplete="new-password"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockOutlined />
            </InputAdornment>
          ),
        }}
        error={getError("passwordConfirm")}
        helperText={getHelperText("passwordConfirm")}
        {...getFieldProps("passwordConfirm")}
      />
      {/* Referral Code (Optional) */}
      <CustomTextField
        fullWidth
        variant="outlined"
        margin="dense"
        placeholder={t("referralCode")}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PersonOutline />
            </InputAdornment>
          ),
        }}
        error={getError("refcode")}
        helperText={getHelperText("refcode")}
        {...getFieldProps("refcode")}
      />
      {/* Non Field Error */}
      <Box my={4} width="100%">
        <Typography align="center" color="error" variant="body2">
          {errors.msg}
        </Typography>
        {/* Submit */}
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disableElevation
          disabled={isSubmitting}
          className={classesBase.button}
        >
          {t("signUp")}
        </Button>
      </Box>
      <Typography align="center">{t("forgotPassword")}?</Typography>
      <Typography align="center">
        {t("haveAccount")}
        <Link
          component={RouterLink}
          to="/auth/signin"
          className={classesBase.link}
        >
          {t("login")}
        </Link>
      </Typography>
    </form>
  );
}
