import React from "react";
interface TabPanelProps {
  children: React.ReactNode;
  index: any;
  value: any;
}

function CategoryTabPanel(props: TabPanelProps) {
  const { value, index, children } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`completed-leagues-tabpanel-${index}`}
      aria-labelledby={`completed-leagues-tab-${index}`}
    >
      {children}
    </div>
  );
}

export default React.memo(CategoryTabPanel);
