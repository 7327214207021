import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  createStyles,
  makeStyles,
  Theme,
  Typography,
  Box,
  Avatar,
} from "@material-ui/core";
import { FileCopy, Share, PersonRounded } from "@material-ui/icons";

import { useTypedSelector as useSelector } from "../../_redux/rootReducer";
import {
  selectUserRefCode,
  selectCurrentSeasonReferrals,
  selectUserImg,
} from "../../_redux/auth/selectors";

import { Button } from "../../Components";
import TierAvatars from "./TierAvatars";
import RefShareDialog from "./RefShareDialog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatarContainer: {
      margin: theme.spacing(2, 0),
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      height: 240,
      width: 240,
      backgroundColor: "#fff",
      cursor: "pointer",
    },
    fontBold: {
      fontWeight: theme.typography.fontWeightBold,
    },
  })
);

const GridOne: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation("referrals");

  const refCode = useSelector(selectUserRefCode);
  const referrals = useSelector(selectCurrentSeasonReferrals);
  const userImg = useSelector(selectUserImg);

  const [copyText, setCopyText] = useState<string>(t("Copy Code"));
  const [refShareOpen, setRefShareOpen] = useState(false);
  const openRefShare = useCallback(() => setRefShareOpen(true), []);
  const closeRefShare = useCallback(() => setRefShareOpen(false), []);

  const handleCopy = useCallback(() => {
    if (!refCode) return;
    navigator.clipboard.writeText(refCode);
    setCopyText(t("Code Copied"));
    setTimeout(() => {
      setCopyText(t("Copy Code"));
    }, 2000);
  }, [refCode, t]);

  return (
    <div>
      {/* user Avatar */}
      <div className={classes.avatarContainer}>
        <Avatar className={classes.avatar} src={userImg} alt="User profile pic">
          <Box color="#FC6E4F">
            <PersonRounded fontSize="large" />
          </Box>
        </Avatar>
      </div>
      {/* Ref code display */}
      <div>
        <Typography align="center" color="textSecondary">
          {t('My Referral Code')}
        </Typography>
        <Typography
          align="center"
          color="textPrimary"
          className={classes.fontBold}
          variant="h4"
        >
          {refCode}
        </Typography>
        <RefShareDialog open={refShareOpen} onClose={closeRefShare} />
      </div>
      {/* buttons */}
      <Box display="flex" flexWrap="wrap" justifyContent="space-evenly" my={4}>
        <Box my={0.5}>
          <Button
            color="secondary"
            variant="outlined"
            shape="pill"
            startIcon={<FileCopy />}
            onClick={handleCopy}
          >
            {copyText}
          </Button>
        </Box>
        <Box color="#FC6E4F" my={0.5}>
          <Button
            color="inherit"
            variant="outlined"
            shape="pill"
            startIcon={<Share />}
            onClick={openRefShare}
          >
            {t('Share Code')}
          </Button>
        </Box>
      </Box>
      {/* Tier avatars */}
      <Box px={3}>
        <Box my={2}>
          <TierAvatars
            title={t("TierCount", {count: 3})}
            rate={5}
            referrals={referrals?.level3 ?? []}
          />
        </Box>
        <Box my={2}>
          <TierAvatars
            title={t("TierCount", {count: 2})}
            rate={7.5}
            referrals={referrals?.level2 ?? []}
          />
        </Box>
        <Box my={2}>
          <TierAvatars
            title={t("TierCount", {count: 1})}
            rate={12.5}
            referrals={referrals?.level1 ?? []}
          />
        </Box>
      </Box>
    </div>
  );
};

export default React.memo(GridOne);
