import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import Color from "color";

import { makeStyles, createStyles, Theme } from "@material-ui/core";

import { useTypedSelector as useSelector } from "../../../_redux/rootReducer";
import { getBaData } from "../../../_redux/auth";
import { makeBaDataForSeasonSelector } from "../../../_redux/auth/selectors";

import { Paper } from "../../../Components";

import Header from "./Header";
import PaperTopInfo from "./PaperTopInfo";
import NoDataDisplay from "./NoDataDisplay";
import BusinessAssociateTable from "./BusinessAssociateTable";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      marginTop: theme.spacing(4),
    },
    paperRoot: {
      padding: theme.spacing(2, 4),
      backgroundColor: Color(theme.palette.primary.main).alpha(0.4).string(),
    },
  })
);

const BusinessAssociate: React.FC = () => {
  const classes = useStyles();
  const dispatch: AppDispatch = useDispatch();

  const selectBaDataForSeason = useMemo(makeBaDataForSeasonSelector, []);

  const [selectedSeason, setSelectedSeason] = useState("All");

  const baData = useSelector((state) =>
    selectBaDataForSeason(state, selectedSeason)
  );

  useEffect(() => {
    if (selectedSeason) dispatch(getBaData(selectedSeason));
  }, [selectedSeason, dispatch]);

  return (
    <div className={classes.root}>
      <Header baData={baData} selectedSeason={selectedSeason} />
      <Paper className={classes.paperRoot}>
        <PaperTopInfo
          baData={baData}
          selectedSeason={selectedSeason}
          setSelectedSeason={setSelectedSeason}
        />
        {baData === null ? (
          <NoDataDisplay />
        ) : (
          <BusinessAssociateTable data={baData} />
        )}
      </Paper>
    </div>
  );
};

export default React.memo(BusinessAssociate);
