import React, { useCallback, useMemo, useState } from "react";
import { animated, useSpring } from "react-spring";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  makeStyles,
  createStyles,
  Theme,
  useMediaQuery,
  Box,
  Typography,
  Grid,
  Divider,
  Hidden,
} from "@material-ui/core";

import {
  AssignmentRounded,
  ArrowUpwardRounded,
  ArrowDownwardRounded,
  ExpandMoreRounded,
} from "@material-ui/icons";

import { LeagueInfo } from "../../../_redux/league/types";
import { convertBaseCurrency } from "../../../_redux/league";

import BaseCurrencyChange from "./components/BaseCurrencyChange";
import TransactionsDialog from "./Transactions";
import LeaderboardDialog from "./Leaderboard";

const AnimatedTypography = animated(Typography);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#4043ff4d",
      borderRadius: 6,
      padding: theme.spacing(1, 2),
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(1, 5),
      },
    },
    secondaryText: {
      opacity: 0.7,
    },
    primaryText: {
      fontWeight: theme.typography.fontWeightBold,
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    divider: {
      backgroundColor: "#ffffff80",
      margin: theme.spacing(1, 0),
    },
    baseCrrContainer: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
    viewTransactionContainer: {
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
    },
  })
);

interface HeaderProps {
  stratInfo: LeagueInfo;
  emitGetLeaguesDetail: () => void;
}
const Header: React.FC<HeaderProps> = (props) => {
  const dispatch: AppDispatch = useDispatch();
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:600px)");
  const { stratInfo, emitGetLeaguesDetail } = props;
  const { balance = 0, netWorth = 100, baseCurrency, rank = 0 } = stratInfo;
  const { t } = useTranslation("league");

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);
  const [leaderboardOpen, setLeaderboardOpen] = useState(false);

  const handleOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = useCallback(() => setAnchorEl(null), []);

  const handleMenuItemClick = useCallback(
    async (event: React.MouseEvent<HTMLElement>, alias: string) => {
      console.log(event, alias);
      const [res, success] = await dispatch(
        convertBaseCurrency({
          baseCurrency: stratInfo.baseCurrency,
          convertCurrency: alias,
          strategyId: stratInfo._id,
        })
      );
      if (!success) return console.log(res);
      setAnchorEl(null);
      emitGetLeaguesDetail();
    },
    [dispatch, emitGetLeaguesDetail, stratInfo._id, stratInfo.baseCurrency]
  );

  const handleTransactionDialogOpen = useCallback(() => setOpen(true), []);
  const handleTransactionDialogClose = useCallback(() => setOpen(false), []);
  const handleLeaderboardDialogOpen = useCallback(
    () => setLeaderboardOpen(true),
    []
  );
  const handleLeaderboardDialogClose = useCallback(
    () => setLeaderboardOpen(false),
    []
  );

  const animatedProps = useSpring({ balance, netWorth });

  const labelVariant = useMemo(() => (isMobile ? "caption" : "body2"), [
    isMobile,
  ]);
  const valueVariant = useMemo(() => (isMobile ? "h6" : "h4"), [isMobile]);

  return (
    <Grid container className={classes.root}>
      {/* Base Currency */}
      <Grid item xs={6} sm={3}>
        <Typography variant={labelVariant} className={classes.secondaryText}>
          {t('Base Currency')}
        </Typography>
        <div className={classes.baseCrrContainer} onClick={handleOpen}>
          <Typography variant={valueVariant} className={classes.primaryText}>
            {baseCurrency}
          </Typography>
          <ExpandMoreRounded fontSize="small" color="secondary" />
        </div>
        <BaseCurrencyChange
          anchorEl={anchorEl}
          handleClose={handleClose}
          handleClick={handleMenuItemClick}
          stratInfo={stratInfo}
        />
      </Grid>
      {/* Wallet Balance */}
      <Grid item xs={6} sm={3}>
        <Typography variant={labelVariant} className={classes.secondaryText}>
          {t('Wallet Balance')} ({baseCurrency})
        </Typography>
        <AnimatedTypography
          variant={valueVariant}
          className={classes.primaryText}
        >
          {animatedProps.balance.interpolate(
            (val) => `${baseCurrency === "USD" ? "$" : ""}${val.toFixed(2)}`
          )}
        </AnimatedTypography>
        <div
          className={classes.viewTransactionContainer}
          onClick={handleTransactionDialogOpen}
        >
          <AssignmentRounded
            fontSize="small"
            color="secondary"
            className={classes.icon}
          />
          <Typography variant={labelVariant} className={classes.secondaryText}>
            {t('View Transactions')}
          </Typography>
        </div>
      </Grid>
      <Hidden smUp>
        <Grid item xs={12}>
          <Divider className={classes.divider} />
        </Grid>
      </Hidden>
      {/* Strategy Rank */}
      <Grid item xs={6} sm={3}>
        <Typography variant={labelVariant} className={classes.secondaryText}>
          {t('Strategy Rank')}
        </Typography>
        <Box display="flex" alignItems="center">
          <Typography variant={valueVariant} className={classes.primaryText}>
            {rank}
          </Typography>
          <ArrowUpwardRounded fontSize="small" color="secondary" />
        </Box>
        <div
          className={classes.viewTransactionContainer}
          onClick={handleLeaderboardDialogOpen}
        >
          <AssignmentRounded
            fontSize="small"
            color="secondary"
            className={classes.icon}
          />
          <Typography variant="body2" className={classes.secondaryText}>
            {t('View Leaderboard')}
          </Typography>
        </div>
      </Grid>
      {/* Networth Status */}
      <Grid item xs={6} sm={3}>
        <Typography variant="body2" className={classes.secondaryText}>
          {t('Networth Status')}
        </Typography>
        <Box display="flex" alignItems="center">
          <AnimatedTypography
            variant={valueVariant}
            className={classes.primaryText}
          >
            {animatedProps.netWorth.interpolate((val) => `${val.toFixed(4)}%`)}
          </AnimatedTypography>
          {netWorth > 0 && (
            <ArrowUpwardRounded fontSize="small" color="secondary" />
          )}
          {netWorth < 0 && (
            <ArrowDownwardRounded fontSize="small" color="error" />
          )}
        </Box>
      </Grid>
      {/* Dialogs */}
      <TransactionsDialog
        open={open}
        handleClose={handleTransactionDialogClose}
        strategyId={stratInfo._id}
        stratInfo={stratInfo}
      />
      <LeaderboardDialog
        open={leaderboardOpen}
        handleClose={handleLeaderboardDialogClose}
        strategyId={stratInfo._id}
        stratInfo={stratInfo}
      />
    </Grid>
  );
};

export default React.memo(Header);
