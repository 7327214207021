import React from "react";
import { animated, useSpring } from "react-spring";

import {
  createStyles,
  makeStyles,
  Theme,
  Typography,
  Box,
  Avatar,
} from "@material-ui/core";
import { AvatarGroup } from "@material-ui/lab";
import { AnimatedPaper } from "../../Components";

import { Ireferral } from "../../_redux/auth/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      color: "white",
      padding: theme.spacing(1, 3),
      alignItems: "center",
      justifyContent: "space-between",
      backgroundColor: "#4043FF80",
    },
    subtitleText: {
      color: "#ffffffb3",
    },
    fontBold: { fontWeight: theme.typography.fontWeightBold },
  })
);

const AnimatedTypography = animated(Typography);

interface TierAvatarsProps {
  title: string;
  rate: number;
  referrals: Ireferral[];
}

const TierAvatars: React.FC<TierAvatarsProps> = (props) => {
  const { title, referrals } = props;
  const classes = useStyles();

  const animatedProps = useSpring({
    rate: props.referrals.length,
    from: { rate: 0 },
  });

  return (
    <AnimatedPaper className={classes.root}>
      <Box>
        <Typography variant="body2" className={classes.subtitleText}>
          {title}
        </Typography>
        <AnimatedTypography variant="h5" className={classes.fontBold}>
          {animatedProps.rate.interpolate((val) => ~~val)}
        </AnimatedTypography>
      </Box>
      <AvatarGroup max={5}>
        {referrals.map((ref) => (
          <Avatar
            key={ref.rferralId}
            alt={ref.userDetail?.[0].name}
            src={ref.userDetail?.[0].profileImage ?? undefined}
          >
            {(ref.userDetail?.[0].name?.[0] ?? "").toUpperCase()}
          </Avatar>
        ))}
      </AvatarGroup>
    </AnimatedPaper>
  );
};

export default React.memo(TierAvatars);
