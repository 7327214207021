import React from "react";
import clsx from "clsx";

import {
  makeStyles,
  createStyles,
  Theme,
  TableCell,
  TableRow,
  Typography,
  Avatar,
  Box,
} from "@material-ui/core";

import { LeaderBoardItem } from "../../../../_redux/league/types";

interface RowProps {
  row: LeaderBoardItem;
  index: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: (props: RowProps) => ({
      backgroundColor: props.index % 2 === 0 ? "#06022066" : "#130D4A66",
      "& > *": {
        borderBottom: "unset",
      },
    }),
    titleText: {
      color: "#fff",
      fontWeight: theme.typography.fontWeightBold,
    },
    capitalize: { textTransform: "capitalize" },
  })
);

const Row: React.FC<RowProps> = (props) => {
  const { row } = props;
  const classes = useStyles(props);

  return (
    <TableRow className={classes.root}>
      {/* Img & Name */}
      <TableCell component="th" scope="row">
        <Box display="flex">
          <Box mr={2}>
            <Avatar src={row.userId.profileImg} alt="user avatar">
              {row.userId.name}
            </Avatar>
          </Box>
          <Typography className={clsx(classes.titleText, classes.capitalize)}>
            {row.userId.name}
          </Typography>
        </Box>
      </TableCell>
      {/* Networth */}
      <TableCell align="right">
        <Typography className={classes.titleText}>{row.netWorth}</Typography>
      </TableCell>
      {/* Rank */}
      <TableCell align="right">
        <Typography className={classes.titleText}>{row.currentRank}</Typography>
      </TableCell>
    </TableRow>
  );
};

export default React.memo(Row);
