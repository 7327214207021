import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core";

import LoaderImg from "../_assets/loader.svg";

const LoadingIndicator: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img src={LoaderImg} alt="Loading Indicator" />
    </div>
  );
};

export default React.memo(LoadingIndicator);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
  })
);
