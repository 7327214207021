import { createStyles, withStyles, Theme, Tab } from "@material-ui/core";

export const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: "none",
      color: theme.palette.secondary.main,
      fontWeight: theme.typography.fontWeightLight,
      fontSize: "1rem",
      minWidth: "unset",
      padding: theme.spacing(0, 2),
      "&:focus": {
        opacity: 1,
      },
    },
    selected: {
      fontWeight: theme.typography.fontWeightBold,
      color: "#4043FF",
    },
  })
)(Tab);

export default StyledTab;
