import React from "react";
import { useTranslation } from "react-i18next";

import {
  makeStyles,
  withStyles,
  createStyles,
  Theme,
  TableCell as MUITableCell,
  TableRow,
  Typography,
  Box,
} from "@material-ui/core";
import { Star as StarIcon } from "@material-ui/icons";

import { format } from "../../../../_utils/date";
import { IHLevel } from "../../../../_redux/auth/types";

const TableCell = withStyles(
  createStyles({
    root: {
      whiteSpace: "nowrap",
    },
  })
)(MUITableCell);

interface RowProps {
  row: IHLevel;
  index: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: (props: RowProps) => ({
      backgroundColor: props.index % 2 === 0 ? "#06022066" : "#130D4A66",
      "& > *": {
        borderBottom: "unset",
      },
    }),
    titleText: {
      // color: "#fff",
      fontWeight: theme.typography.fontWeightBold,
    },
    subtitleText: {
      color: "#ffffff80",
    },
    fontBold: {
      fontWeight: theme.typography.fontWeightBold,
    },
  })
);

const Row: React.FC<RowProps> = (props) => {
  const { row, index } = props;
  const classes = useStyles(props);
  const { t } = useTranslation("referrals");

  return (
    <TableRow className={classes.root}>
      {/* Details */}
      <TableCell component="th" scope="row">
        <Box display="flex" alignItems="center">
          <Typography color="secondary" className={classes.titleText}>
            {index}
          </Typography>
          <Box ml={1}>
            <StarIcon color="secondary" fontSize="large" />
          </Box>
          <Box ml={2}>
            <Typography className={classes.titleText}>{row.level}</Typography>
            <Typography variant="body2" className={classes.subtitleText}>
              {t('AchievedOnDate', {date: format(new Date('2020-08-21'), "d MMM yyyy")})}
            </Typography>
          </Box>
        </Box>
      </TableCell>
      {/* Sale */}
      <TableCell align="right">
        <Typography className={classes.titleText}>${row.saleAmount}</Typography>
        <Typography variant="body2" color="secondary">
          USD
        </Typography>
      </TableCell>
      {/* Additional % */}
      <TableCell align="center">
        <Typography className={classes.titleText}>{row.Percentage}%</Typography>
      </TableCell>
      {/* Earned */}
      <TableCell align="right">
        <Typography className={classes.titleText}>
          ${row.EarnedAmount}
        </Typography>
        {/* <Typography variant="body2" className={classes.subtitleText}>
          {format(new Date(row.createdAt), "d MMM yyyy")}
        </Typography> */}
      </TableCell>
      {/* Status */}
      <TableCell className={classes.titleText}>
        <Typography className={classes.fontBold} color="error">
          {t('Pending')}
        </Typography>
        <Typography variant="body2" className={classes.subtitleText}>
          {t('ETADate', {date: format(new Date('2020-10-21'), "d MMM yyyy")})}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default React.memo(Row);
