import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

import { makeStyles, createStyles, Theme } from "@material-ui/core";

import { SocketContext } from "../../../SocketProvider";

import { useTypedSelector } from "../../../_redux/rootReducer";
import { selectUserId } from "../../../_redux/auth/selectors";
import {
  makeStrategyByIdSelector,
  makeLeagueInfoSelector,
} from "../../../_redux/league/selectors";
import {
  buyTrade,
  receiveCurrencyList,
  sellTrade,
} from "../../../_redux/league";
import { Currency } from "../../../_redux/league/types";

import Header from "./Header";
import Table from "./Table";
import BuyDialog from "./BuyDialog";
import SellDialog from "./SellDialog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3, 0),
    },
  })
);

export interface BuyFormValues {
  purchase: string;
}

export interface SellFormValues {
  sell: string;
}

interface JoinedStrategyProps {
  strategyId: string;
}

const JoinedStrategy: React.FC<JoinedStrategyProps> = (props) => {
  const { strategyId } = props;
  const classes = useStyles();
  const dispatch: AppDispatch = useDispatch();

  const { leagueDetailSocket } = useContext(SocketContext);

  const [errors, setErrors] = useState<{ [x: string]: string }>({});
  const timeout = useRef<number>();

  const [buyActiveStep, setBuyActiveStep] = useState(0);
  const [buyCurr, setBuyCurr] = useState<string | null>(null);
  const openBuy = useCallback((currency) => setBuyCurr(currency), []);
  const closeBuy = useCallback(() => {
    setBuyCurr(null);
    setBuyActiveStep(0);
  }, []);

  const [sellActiveStep, setSellActiveStep] = useState(0);
  const [sellCurr, setSellCurr] = useState<string | null>(null);
  const openSell = useCallback((currency) => setSellCurr(currency), []);
  const closeSell = useCallback(() => {
    setSellCurr(null);
    setSellActiveStep(0);
  }, []);

  const selectStrategy = useMemo(makeStrategyByIdSelector, []);
  const selectStrategyInfo = useMemo(makeLeagueInfoSelector, []);
  const userId = useTypedSelector(selectUserId);
  const strategy = useTypedSelector((state) =>
    selectStrategy(state, strategyId)
  );
  const stratInfo = useTypedSelector((state) =>
    selectStrategyInfo(state, strategyId)
  );

  const emitGetLeaguesDetail = useCallback(() => {
    if (!strategy) return;
    const data = {
      userId,
      strategyId,
      leagueAmount: strategy.leagueAmount,
    };
    leagueDetailSocket.emit(
      "getLeaguesDetail",
      data,
      (res: {
        cD: Currency[];
        netWorth?: number;
        balance?: number;
        baseCurrency?: string;
        rank?: number;
      }) => {
        // console.log({res});
        const { cD: currencies, netWorth, balance, baseCurrency, rank } = res;
        dispatch(
          receiveCurrencyList({
            strategyId,
            currencies,
            netWorth,
            balance,
            baseCurrency,
            rank,
          })
        );
        clearTimeout(timeout.current);
        timeout.current = window.setTimeout(emitGetLeaguesDetail, 1000);
      }
    );
  }, [dispatch, leagueDetailSocket, strategy, strategyId, userId]);

  const buyForm = useFormik<BuyFormValues>({
    initialValues: {
      purchase: "",
    },
    validationSchema: Yup.object().shape({
      purchase: Yup.number().required().min(0),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (!buyCurr) return;
      setErrors({});
      const [res, success] = await dispatch(
        buyTrade({
          strategyId,
          currencyTrade: {
            currency: buyCurr,
            purchase: Number(values.purchase),
          },
        })
      );
      // console.log(success);
      if (!success) return setErrors(res);
      // success
      closeBuy();
      emitGetLeaguesDetail();
      resetForm();
    },
  });
  const sellForm = useFormik<SellFormValues>({
    initialValues: {
      sell: "",
    },
    validationSchema: Yup.object().shape({
      sell: Yup.number().required().min(0),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (!sellCurr) return;
      setErrors({});
      const [res, success] = await dispatch(
        sellTrade({
          strategyId,
          currencyTrade: {
            currency: sellCurr,
            sell: Number(values.sell),
          },
        })
      );
      // console.log(success);
      if (!success) return setErrors(res);
      // success
      closeSell();
      emitGetLeaguesDetail();
      resetForm();
    },
  });

  useEffect(() => {
    emitGetLeaguesDetail();
    return () => clearTimeout(timeout.current);
  }, [emitGetLeaguesDetail]);

  // useEffect(() => {
  //   const interval = setInterval(emitGetLeaguesDetail, 1 * 1000);
  //   return () => clearInterval(interval);
  // }, [emitGetLeaguesDetail]);

  if (!stratInfo) return null;
  return (
    <div className={classes.root}>
      <Header
        stratInfo={stratInfo}
        emitGetLeaguesDetail={emitGetLeaguesDetail}
      />
      <Table openBuy={openBuy} openSell={openSell} stratInfo={stratInfo} />
      <BuyDialog
        buyCurr={buyCurr}
        handleClose={closeBuy}
        form={buyForm}
        stratInfo={stratInfo}
        errors={errors}
        activeStep={buyActiveStep}
        setActiveStep={setBuyActiveStep}
      />
      <SellDialog
        sellCurr={sellCurr}
        handleClose={closeSell}
        form={sellForm}
        stratInfo={stratInfo}
        errors={errors}
        activeStep={sellActiveStep}
        setActiveStep={setSellActiveStep}
      />
    </div>
  );
};

export default React.memo(JoinedStrategy);
