import React, { useMemo } from "react";
import { format, Locale } from "date-fns";
import { enUS, ja } from "date-fns/locale";
import { useTranslation } from "react-i18next";

import {
  makeStyles,
  createStyles,
  Theme,
  Avatar,
  Box,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";

import { LeagueWinnerItem } from "../../../_redux/leaderboard/types";

import GoldImg from "../../../_assets/Images/Gold.png";
import GoldImg2x from "../../../_assets/Images/Gold@2x.png";
import SilverImg from "../../../_assets/Images/Silver.png";
import SilverImg2x from "../../../_assets/Images/Silver@2x.png";
import BronzeImg from "../../../_assets/Images/Bronze.png";
import BronzeImg2x from "../../../_assets/Images/Bronze@2x.png";

const locales: { [key: string]: Locale } = { enUS, ja };

interface ITableRowProps {
  item: LeagueWinnerItem;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableCell: {
      borderBottomColor: "#FC6E4F",
    },
  })
);

export default function UserRow(props: ITableRowProps) {
  const { item } = props;
  const classes = useStyles();
  const { t, i18n } = useTranslation("leaderboard");

  const [src, srcSet] = useMemo(() => {
    if (item.leagueCategory === "Gold")
      return [GoldImg, `${GoldImg}, ${GoldImg2x} 2x`];
    if (item.leagueCategory === "Silver")
      return [SilverImg, `${SilverImg}, ${SilverImg2x} 2x`];
    if (item.leagueCategory === "Bronze")
      return [BronzeImg, `${BronzeImg}, ${BronzeImg2x} 2x`];
    return [undefined, undefined];
  }, [item.leagueCategory]);

  return (
    <TableRow>
      {/* League */}
      <TableCell align="center" className={classes.tableCell}>
        <img
          srcSet={srcSet}
          src={src}
          alt={`${item.leagueCategory} League Logo`}
        />
      </TableCell>
      {/* Winner */}
      <TableCell className={classes.tableCell} align="center">
        <Box display="flex">
          <Avatar src={item.userId.profileImg} />
          <Box color="white" ml={2}>
            <Typography>{item.userId.name}</Typography>
            <Box color="#576078">
              <Typography variant="body2">
                {t(`translation:${item.leagueCategory}`)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </TableCell>
      {/* Played */}
      <TableCell className={classes.tableCell} align="center">
        <Box color="#4043FF">
          <Typography>
            {t("countTrades", { count: item.tradeCount })}
          </Typography>
        </Box>
      </TableCell>
      {/* Date */}
      <TableCell className={classes.tableCell} align="center">
        <Box color="#3BE4CE">
          <Typography>
            {t("wonOnDate", {
              date: format(new Date(item.endDate), "d MMM yy", {
                locale: locales[i18n.language],
              }),
            })}
          </Typography>
        </Box>
      </TableCell>
    </TableRow>
  );
}
