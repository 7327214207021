import * as types from "./types";
import { saveState } from "../localStorage";
import callApi from "../../_utils/callApi";
import { setAuthTokenCookie, removeAuthTokenCookie } from "../../_utils/cookie";
import { getToken } from "./selectors";
import { selectCurrentSeason } from "../league/selectors";

interface signInRes {
  response: {
    Token: {
      _id: string;
      token: string;
    };
  };
}
export const signIn =
  (values: object): AppThunk<Promise<ObjectStringKey | null>> =>
  async (dispatch) => {
    try {
      const [jsonResponse, error] = await callApi<signInRes>({
        endpoint: "v1/login",
        method: "POST",
        body: values,
      });
      if (error) return jsonResponse;
      const { token } = jsonResponse.response.Token;
      setAuthTokenCookie(token);
      dispatch({
        type: types.RECEIVE_AUTH_TOKEN,
        token,
      });
      saveState("token", token);
      return null;
    } catch (err) {
      return {
        msg: String(err),
      };
    }
  };

export const signUp =
  (values: object): AppThunk<Promise<ObjectStringKey | null>> =>
  async () => {
    try {
      const [jsonResponse, error] = await callApi({
        endpoint: "v1/user/rergistration",
        method: "POST",
        body: values,
      });
      if (error) return jsonResponse;
      return null;
    } catch (err) {
      return {
        msg: String(err),
      };
    }
  };

export const verify =
  (values: any): AppThunk<Promise<ObjectStringKey | null>> =>
  async (dispatch) => {
    try {
      const [jsonResponse, error] = await callApi({
        endpoint: "v1/user/verification",
        method: "POST",
        body: values,
      });
      if (error) return jsonResponse;
      const { token } = jsonResponse.response.Token;
      setAuthTokenCookie(token);
      dispatch({
        type: types.RECEIVE_AUTH_TOKEN,
        token,
      });
      saveState("token", token);
      return null;
    } catch (err) {
      return {
        msg: String(err),
      };
    }
  };

export const requestPasswordReset =
  (values: any): AppThunk<Promise<ObjectStringKey | null>> =>
  async () => {
    try {
      const [jsonResponse, error] = await callApi({
        endpoint: "v1/user/reset",
        method: "POST",
        body: values,
      });
      if (error) return jsonResponse;
      return null;
    } catch (err) {
      return {
        msg: String(err),
      };
    }
  };

export const confirmPasswordReset =
  (values: any): AppThunk<Promise<ObjectStringKey | null>> =>
  async () => {
    try {
      const [jsonResponse, error] = await callApi({
        endpoint: "v1/user/password",
        method: "PUT",
        body: values,
      });
      if (error) return jsonResponse;
      return null;
    } catch (err) {
      return {
        msg: String(err),
      };
    }
  };

export const logout = (): AppThunk => (dispatch) => {
  saveState("token", null);
  removeAuthTokenCookie();
  dispatch<types.AuthActionTypes>({
    type: types.LOGOUT,
  });
};

export const getWallet = (): AppThunk => async (dispatch, getState) => {
  const token = getToken(getState());
  try {
    const [jsonResponse, error] = await callApi({
      endpoint: "v1/user/getWallet",
      token,
    });
    if (error) return jsonResponse;
    const { data } = jsonResponse.response;
    dispatch<types.AuthActionTypes>({
      type: types.RECEIVE_WALLET_DATA,
      data,
    });
    return null;
  } catch (err) {
    return {
      msg: String(err),
    };
  }
};

export const getProfile =
  (): AppThunk<Promise<null | ObjectStringKey>> =>
  async (dispatch, getState) => {
    const token = getToken(getState());
    try {
      const [jsonResponse, error] = await callApi({
        endpoint: "v1/user/profile",
        token,
      });
      if (error) return jsonResponse;
      const { data } = jsonResponse.response;
      dispatch<types.AuthActionTypes>({
        type: types.RECEIVE_PROFILE_DATA,
        data,
      });
      return null;
    } catch (err) {
      return {
        msg: String(err),
      };
    }
  };

export const updateProfile =
  (formData: FormData): AppThunk<Promise<null | ObjectStringKey>> =>
  async (dispatch, getState) => {
    const token = getToken(getState());
    try {
      const [jsonResponse, error] = await callApi({
        endpoint: "v1/user/update",
        token,
        method: "PUT",
        body: formData,
      });
      if (error) return jsonResponse;
      const { data } = jsonResponse.response;
      dispatch<types.AuthActionTypes>({
        type: types.RECEIVE_PROFILE_IMAGE,
        data,
      });
      return null;
    } catch (err) {
      return {
        msg: String(err),
      };
    }
  };

export const getWalletTransactions =
  (): AppThunk<Promise<null | ObjectStringKey>> =>
  async (dispatch, getState) => {
    const token = getToken(getState());
    try {
      const [jsonResponse, error] = await callApi({
        endpoint: "v1/user/walletTransaction",
        token,
      });
      if (error) return jsonResponse;
      const { data } = jsonResponse.response;
      dispatch<types.AuthActionTypes>({
        type: types.RECEIVE_WALLET_TRANSACTIONS,
        data,
      });
      return null;
    } catch (err) {
      return {
        msg: String(err),
      };
    }
  };

export const getReferrals =
  (season = ""): AppThunk<Promise<null | ObjectStringKey>> =>
  async (dispatch, getState) => {
    const state = getState();
    const token = getToken(state);
    if (!season) season = selectCurrentSeason(state)?.season ?? "";

    try {
      const [jsonResponse, error] = await callApi({
        endpoint: "v1/user/ref",
        token,
        params: { season },
      });
      if (error) return jsonResponse;
      const { data } = jsonResponse.response;
      dispatch<types.AuthActionTypes>({
        type: types.RECEIVE_REFERRALS,
        data,
        season,
      });
      return null;
    } catch (err) {
      return {
        msg: String(err),
      };
    }
  };

interface SocialSignInValues {
  name: string;
  email: string;
  picture: string;
  id: string;
  accessToken: string;
  userID: string;
  graphDomain: "facebook";
  mode: "facebook" | "google";
}
export const socialSignIn =
  (values: SocialSignInValues): AppThunk<Promise<ObjectStringKey | null>> =>
  async (dispatch) => {
    try {
      const [jsonResponse, error] = await callApi<signInRes>({
        endpoint: "v1/isSocialUser",
        method: "POST",
        body: values,
      });
      if (error) return jsonResponse;
      const { token } = jsonResponse.response.Token;
      setAuthTokenCookie(token);
      dispatch({
        type: types.RECEIVE_AUTH_TOKEN,
        token,
      });
      saveState("token", token);
      return null;
    } catch (err) {
      return {
        msg: String(err),
      };
    }
  };

interface SocialSignUpValues extends SocialSignInValues {
  refcode: string;
}
export const socialSignUp =
  (values: SocialSignUpValues): AppThunk<Promise<ObjectStringKey | null>> =>
  async (dispatch) => {
    try {
      const [jsonResponse, error] = await callApi<signInRes>({
        endpoint: "v1/social",
        method: "POST",
        body: values,
      });
      if (error) return jsonResponse;
      const { token } = jsonResponse.response.Token;
      setAuthTokenCookie(token);
      dispatch({
        type: types.RECEIVE_AUTH_TOKEN,
        token,
      });
      saveState("token", token);
      return null;
    } catch (err) {
      return {
        msg: String(err),
      };
    }
  };

export const getRefStatus =
  (): AppThunk<Promise<null | ObjectStringKey>> =>
  async (dispatch, getState) => {
    const token = getToken(getState());
    interface RefStatus {
      response: {
        data: {
          isReferral: boolean;
        };
      };
    }
    try {
      const [jsonResponse, error] = await callApi<RefStatus>({
        endpoint: "v1/getRefStatus",
        token,
      });
      if (error) return jsonResponse;
      const { isReferral } = jsonResponse.response.data;
      dispatch<types.AuthActionTypes>({
        type: types.RECEIVE_REF_STATUS,
        refCodeRequired: isReferral,
      });
      return null;
    } catch (err) {
      return {
        msg: String(err),
      };
    }
  };

export const getBaData =
  (season: string): AppThunk<Promise<null | ObjectStringKey>> =>
  async (dispatch, getState) => {
    const token = getToken(getState());
    interface BaDataRes {
      response: {
        data: types.BaData[];
      };
    }
    try {
      const [jsonResponse, error] = await callApi<BaDataRes>({
        endpoint: "v1/user/userBI",
        token,
        method: "POST",
        body: { season },
      });
      if (error) return jsonResponse;
      const { data } = jsonResponse.response;
      // console.log(jsonResponse);
      dispatch<types.AuthActionTypes>({
        type: types.RECEIVE_BA_DATA,
        data: data[0],
        season,
      });
      return null;
    } catch (err) {
      return {
        msg: String(err),
      };
    }
  };

export const getBaSeasonTemplate =
  (season: string): AppThunk<Promise<null | ObjectStringKey>> =>
  async (dispatch, getState) => {
    const token = getToken(getState());
    interface RefStatus {
      response: { data: types.BaTemplateItem[] };
    }
    try {
      const [jsonResponse, error] = await callApi<RefStatus>({
        endpoint: "v1/admin/seasonTemplate",
        token,
        method: "POST",
        body: { season: season !== "All" ? season : "" },
      });
      if (error) return jsonResponse;
      const { data } = jsonResponse.response;
      dispatch<types.AuthActionTypes>({
        type: types.RECEIVE_SEASON_TEMPLATE,
        season,
        data,
      });
      return null;
    } catch (err) {
      return {
        msg: String(err),
      };
    }
  };
