import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  createStyles,
  makeStyles,
  Theme,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableContainer,
} from "@material-ui/core";
import { fetchLeagueTransactions } from "../../../../_redux/league";
import { LeagueInfo } from "../../../../_redux/league/types";

import { Paper } from "../../../../Components";

import TableHead from "./TableHead";
import Row from "./Row";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperRoot: { color: "#fff" },
    tableContainer: {
      backgroundColor: "transparent",
    },
  })
);

interface TransactionsDialogProps {
  open: boolean;
  handleClose: () => void;
  strategyId: string;
  stratInfo: LeagueInfo;
}

const TransactionsDialog: React.FC<TransactionsDialogProps> = (props) => {
  const { open, handleClose, strategyId, stratInfo } = props;
  const dispatch: AppDispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation("league");

  useEffect(() => {
    if (open) dispatch(fetchLeagueTransactions(strategyId));
  }, [dispatch, open, strategyId]);

  useEffect(() => {
    dispatch(fetchLeagueTransactions(strategyId));
  }, [dispatch, strategyId]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="transaction-dialog-title"
      PaperComponent={Paper}
      PaperProps={{ classes: { root: classes.paperRoot } }}
      fullWidth
    >
      <DialogTitle id="transaction-dialog-title">{t('Transactions List')}</DialogTitle>
      <DialogContent>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table>
            <TableHead />
            <TableBody>
              {stratInfo.transactionsData?.map((x, i) => (
                <Row key={x._id} row={x} index={i} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t('translation:OK')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(TransactionsDialog);
