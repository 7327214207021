import React, { useMemo, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import {
  makeStyles,
  createStyles,
  Theme,
  Box,
  Typography,
  Grid,
  Divider,
} from "@material-ui/core";

import { useTypedSelector as useSelector } from "../../../../_redux/rootReducer";
import { Currency, CurrencyHistoryItem } from "../../../../_redux/league/types";
import { fetchCurrencyHistory } from "../../../../_redux/league";
import { makeCurrencyHistoryForSymbolSelector } from "../../../../_redux/league/selectors";

import { format } from "../../../../_utils/date";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      margin: theme.spacing(2, 0),
      backgroundColor: "#ffffff80",
    },
    titleText: {
      color: "#fff",
      fontWeight: theme.typography.fontWeightBold,
    },
    subtitleText: {
      color: "#ffffff80",
    },
  })
);

interface RowExpandedProps {
  row: Currency;
}

const RowExpanded: React.FC<RowExpandedProps> = (props) => {
  const { row } = props;
  const classes = useStyles();
  const { t } = useTranslation("league");
  const dispatch: AppDispatch = useDispatch();

  const selectCurrencyHistory = useMemo(
    makeCurrencyHistoryForSymbolSelector,
    []
  );
  const currencyHistory = useSelector((state) =>
    selectCurrencyHistory(state, row.symbol)
  );
  console.log({ currencyHistory });

  useEffect(() => {
    console.log("row.symbol", row.symbol);
    dispatch(fetchCurrencyHistory(row.symbol));
  }, [dispatch, row.symbol]);

  const valuesToDisplay = useMemo(
    () => [
      { label: t("Market Cap"), value: row.quotes?.USD.market_cap ?? 0 },
      { label: t("Volume Last 24 hr"), value: row.volume24HOUR ?? 0 },
      {
        label: t("Change Last 24 hr"),
        value: row.quotes?.USD.percent_change_24h ?? 0,
      },
      {
        label: t("Change Last 7 days"),
        value: row.quotes?.USD.percent_change_7d ?? 0,
      },
    ],
    [row.quotes, row.volume24HOUR, t]
  );

  return (
    <Grid container>
      {/* Divider */}
      <Grid item xs={12}>
        <Divider className={classes.divider} />
      </Grid>
      {/* Left content */}
      <Grid item xs={12} md={6}>
        <Box marginX={5}>
          {/* Title */}
          <Typography className={classes.titleText} variant="h5">
            ${row.price}
          </Typography>
          <Typography
            color={
              (row.quotes?.USD.percent_change_24h ?? 0) < 0
                ? "error"
                : "secondary"
            }
          >
            {row.quotes?.USD.percent_change_24h.toFixed(2)}%
          </Typography>
          {/* Label Values */}
          <Box display="flex" flexWrap="wrap" my={4}>
            {valuesToDisplay.map(({ label, value }) => (
              <Box flexBasis="50%" my={2} key={label}>
                <ExpandedLabelValue label={label} value={value} />
              </Box>
            ))}
          </Box>
        </Box>
      </Grid>
      {/* Right Grpah */}
      <Grid item xs={12} md={6}>
        <Graph data={currencyHistory} />
      </Grid>
    </Grid>
  );
};

export default React.memo(RowExpanded);

const ExpandedLabelValue: React.FC<{ label: string; value: number }> = (
  props
) => {
  const { label, value } = props;
  const classes = useStyles();

  return (
    <Box color="#fff">
      <Typography variant="body2" className={classes.subtitleText}>
        {label}
      </Typography>
      <Typography className={classes.titleText}>{value}</Typography>
    </Box>
  );
};

const data = [
  { name: "11:00", uv: 4000 },
  { name: "11:30", uv: 3000 },
  { name: "12:00", uv: 2000 },
  { name: "12:30", uv: 2780 },
  { name: "13:00", uv: 1890 },
  { name: "13:30", uv: 2390 },
  { name: "14:00", uv: 3490 },
  { name: "14:30", uv: 3680 },
  { name: "15:00", uv: 3840 },
];

interface TooltipContentProps {
  active: boolean;
  payload: [{ name: string; value: string }];
  label: string;
}

const TooltipContent: React.FC<TooltipContentProps> = ({
  active,
  payload,
  label,
}) => {
  if (!active) return null;
  // console.log({ active, payload, label });
  return (
    <div style={{ backgroundColor: "#3BE4CE", padding: 8 }}>
      <Typography
        variant="body2"
        style={{ color: "white", fontWeight: "bold" }}
      >
        {payload?.[0]?.value}
      </Typography>
    </div>
  );
};

const Graph: React.FC<{ data: CurrencyHistoryItem[] }> = React.memo((props) => {
  const { data } = props;
  const interpolatedData = useMemo(
    () => data.map((x) => ({ ...x, time: format(x.time * 1000, "hh:mm") })),
    [data]
  );
  return (
    <ResponsiveContainer width="100%" minHeight={300}>
      <LineChart
        data={interpolatedData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis dataKey="time" />
        <YAxis />
        <Tooltip content={TooltipContent} />
        <Line dataKey="high" stroke="#fff" />
      </LineChart>
    </ResponsiveContainer>
  );
});
