import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

export default withStyles((theme) => ({
  root: {
    borderRadius: 5,
    "& .MuiInputBase-root": {
      color: "#E4E7EA80",
    },
    "& label": {
      color: "#E4E7EA80",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#E4E7EA80",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottomColor: "#E4E7EA",
    },
    "& .MuiFormHelperText-root": { color: "#E4E7EA80" },
  },
}))(TextField);
