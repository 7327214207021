import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import {
  makeStyles,
  createStyles,
  Theme,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Box,
  Typography,
} from "@material-ui/core";

import { getWalletTransactions } from "../../../_redux/auth";
import { useTypedSelector } from "../../../_redux/rootReducer";
import { selectWalletTransactions } from "../../../_redux/auth/selectors";

import { AnimatedPaper, Paper, LoadingIndicator } from "../../../Components";
import TableHead from "./Head";
import Row from "./Row";

import Mascot4 from "../../../_assets/Images/Mascot4.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    noTransactionImgContainer: {
      display: "flex",
      height: "100%",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    root: {
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(0, 5),
      },
    },
    tableContainer: {
      backgroundColor: "transparent",
    },
    paginationSelectIcon: {
      color: theme.palette.text.primary,
    },
  })
);

const LeagueTable: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchWalletBal = async () => {
      setLoading(true);
      await dispatch(getWalletTransactions());
      setLoading(false);
    };
    fetchWalletBal();
  }, [dispatch]);

  const transactions = useTypedSelector(selectWalletTransactions);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);
  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    },
    []
  );

  const rows = useMemo(
    () =>
      transactions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page, rowsPerPage, transactions]
  );

  if (loading) return <LoadingIndicator />;
  if (transactions.length === 0) {
    return (
      <>
        <Box my={{ xs: 2, sm: 3, md: 4 }}>
          <Typography variant="h6" align="center">
            {t("Keep track for all of your expenses here")}
          </Typography>
        </Box>
        <div className={classes.noTransactionImgContainer}>
          <img src={Mascot4} alt="mascot" />
        </div>
      </>
    );
  }
  return (
    <div className={classes.root}>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead />
          <Box height={20} />
          <TableBody component={AnimatedPaper} scale={1.02}>
            {rows.map((row, i) => (
              <Row key={row._id} row={row} index={i} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 20, 50]}
        component="div"
        count={transactions.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        SelectProps={{
          classes: { icon: classes.paginationSelectIcon },
        }}
      />
    </div>
  );
};

export default React.memo(LeagueTable);
