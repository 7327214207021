import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import ReactGA from "react-ga";
import CssBaseline from "@material-ui/core/CssBaseline";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./i18n";

import configureStore from "./_redux/store/configureStore";
const store = configureStore();

const trackingId = "UA-112047524-4";
ReactGA.initialize(trackingId, {
  // debug: process.env.NODE_ENV === "development",
  testMode: process.env.NODE_ENV === "test",
});
// ReactGA.set({
//   // any data that is relevant to the user session
//   // that you would like to track with google analytics
// })

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <CssBaseline />
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
