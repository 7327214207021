import React from "react";

import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function Twitter(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 20.003 19.998">
      {/* <svg xmlns="http://www.w3.org/2000/svg" width="20.003" height="19.998"> */}
      <path
        d="M1581,17552a10,10,0,1,1,10-10A10.013,10.013,0,0,1,1581,17552Zm2.009-9.9c1.106,0,1.106,1.047,1.106,1.811v3.326h2.114l0-3.754c0-1.689-.308-3.25-2.542-3.25a2.24,2.24,0,0,0-2.009,1.105h-.031v-.934h-2.035v6.832h2.119v-3.383C1581.738,17542.984,1581.889,17542.1,1583.01,17542.1Zm-6.841-1.7h0v6.832h2.123v-6.832Zm1.057-3.4a1.233,1.233,0,1,0,1.229,1.229A1.23,1.23,0,0,0,1577.227,17537Z"
        transform="translate(-1570.998 -17532.002)"
      />
      {/* </svg> */}
    </SvgIcon>
  );
}
