import React, { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

import { listLeagues, buyLegaues } from "../../../_redux/league";
import { getWallet } from "../../../_redux/auth";
import CustomPaper from "../CustomPaper";

import LeagueList from "./LeaguesList";
import StrategySelect from "./StrategySelect";
import Checkout from "./Checkout";
import Success from "./Success";

import { BuyTicketFormValues } from "./data";

export default function Upcoming() {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation("league");

  const steps = useMemo(
    () => [t("League List"), t("Buying Tickets"), t("Checkout"), t("Success")],
    [t]
  );

  const form = useFormik<BuyTicketFormValues>({
    initialValues: {
      activeStep: 0,
      category: "",
      date: new Date(),
      leagueId: [],
      OrderUniqueID: "",
      paymentMode: "wallet",
      ticketIds: [],
    },
    validationSchema: Yup.object().shape({
      leagueId: Yup.array().of(Yup.string()).required(),
      // OrderUniqueID: Yup.string().required(),
      paymentMode: Yup.string().oneOf(["wallet"]).required(),
    }),
    onSubmit: async (values, { setFieldValue, setErrors }) => {
      const [res, success] = await dispatch(buyLegaues(values));
      if (!success) return setErrors(res);
      const { ticketId } = res.response.data;
      setFieldValue("activeStep", 3, false);
      setFieldValue(
        "ticketIds",
        ticketId.map(
          (x: { _id: string; strategies: string; ticket: string }) => x.ticket
        ),
        false
      );
      dispatch(listLeagues());
      dispatch(getWallet());
    },
  });

  const { values, resetForm } = form;

  const handleReset = useCallback(() => resetForm(), [resetForm]);

  const stepContent = useMemo(() => {
    const { activeStep } = form.values;
    if (activeStep === 0) return <LeagueList form={form} />;
    if (activeStep === 1) return <StrategySelect form={form} />;
    if (activeStep === 2) return <Checkout form={form} />;
    if (activeStep === 3)
      return (
        <Success ticketIds={form.values.ticketIds} handleReset={handleReset} />
      );
    return null;
  }, [form, handleReset]);

  const titles = useMemo(() => {
    const { activeStep } = values;
    if (activeStep === 0) return [t("Upcoming Leagues")];
    return [t("Upcoming Leagues"), steps[activeStep]];
  }, [steps, t, values]);

  return (
    <CustomPaper
      titles={titles}
      handleReset={handleReset}
      indicatorColor="#FC6E4F"
    >
      {stepContent}
    </CustomPaper>
  );
}
