import React from "react";
import { format } from "../../../_utils/date";
import { useTranslation } from "react-i18next";

import {
  makeStyles,
  createStyles,
  Theme,
  TableCell as MUITableCell,
  TableRow,
  Typography,
  withStyles,
} from "@material-ui/core";

import { IwalletTransaction } from "../../../_redux/auth/types";

const TableCell = withStyles(
  createStyles({
    root: {
      whiteSpace: "nowrap",
    },
  })
)(MUITableCell);

interface RowProps {
  row: IwalletTransaction;
  index: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: (props: RowProps) => ({
      backgroundColor: props.index % 2 === 0 ? "#06022066" : "#130D4A66",
      "& > *": {
        borderBottom: "unset",
      },
    }),
    titleText: {
      color: "#fff",
      fontWeight: theme.typography.fontWeightBold,
    },
    subtitleText: {
      color: "#ffffff80",
    },
  })
);

const Row: React.FC<RowProps> = (props) => {
  const { row } = props;
  const classes = useStyles(props);
  const { t } = useTranslation();

  return (
    <TableRow className={classes.root}>
      {/* Details */}
      <TableCell component="th" scope="row">
        <Typography className={classes.titleText}>
          {t('Ticket Purchased')}{" "}
          <Typography
            variant="body2"
            component="span"
            className={classes.subtitleText}
          >
            ({row.ticketId.map((x) => x.ticket).join(", ")})
          </Typography>
        </Typography>
        <Typography variant="body2" className={classes.subtitleText}>
          ID#{row.OrderUniqueID}
        </Typography>
      </TableCell>
      {/* Amount / Status */}
      <TableCell align="right">
        <Typography className={classes.titleText}>
          ${row.boughtAmount}
        </Typography>
        <Typography variant="body2" color="error">
          -{row.deductAmount} BTC
        </Typography>
      </TableCell>
      {/* Date */}
      <TableCell>
        <Typography className={classes.titleText}>
          {format(new Date(row.createdAt), "dd MMM yyyy")}
        </Typography>
        <Typography variant="body2" className={classes.subtitleText}>
          {format(new Date(row.createdAt), "hh:mm aa")}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default React.memo(Row);
