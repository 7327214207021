import React, { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  createStyles,
  makeStyles,
  Theme,
  Box,
  Typography,
} from "@material-ui/core";

import { useTypedSelector } from "../../../_redux/rootReducer";
import { league } from "../../../_redux/league/types";
import {
  makeLiveStrategiesForCategorySelector,
  makeLiveJoinedStrategiesForcategorySelector,
} from "../../../_redux/league/selectors";

import { Tabs, Tab, Button, AnimatedPaper } from "../../../Components";
import { ThumbsDown, ThumbsUp } from "../../../_assets/Icons";
import AnimatedSvg1 from "../../../_assets/AnimatedSvgs/SVG1";

import LeagueInfo from "./LeagueInfo";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabRoot: {
      fontWeight: theme.typography.fontWeightMedium,
      color: "#576078",
    },
    tabSelected: {
      fontWeight: theme.typography.fontWeightBold,
      color: "white",
    },
    tabIcon: {
      marginLeft: theme.spacing(1),
    },
    infoRoot: {
      padding: theme.spacing(2),
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(2, 4),
      },
    },
  })
);

type LeagueCategory = "Bronze" | "Silver" | "Gold";
interface TabPanelProps {
  children?: React.ReactNode;
  category: LeagueCategory;
}

export default function LeagueTabPanel(props: TabPanelProps) {
  const { children, category, ...other } = props;
  const classes = useStyles();
  const { t } = useTranslation("dashboard");

  const [value, setValue] = useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const selectStrategies = useMemo(makeLiveStrategiesForCategorySelector, []);
  const selectJoinedStrategies = useMemo(
    makeLiveJoinedStrategiesForcategorySelector,
    []
  );
  const strategies = useTypedSelector((state) =>
    selectStrategies(state, category)
  );
  const joinedStrategies = useTypedSelector((state) =>
    selectJoinedStrategies(state, category)
  );
  const getIsJoined = useCallback(
    (strat: league | undefined) =>
      joinedStrategies.some((jStrat) => jStrat._id === strat?._id),
    [joinedStrategies]
  );

  return (
    <div {...other}>
      <Box px={{ xs: 2, lg: 4 }}>
        <Tabs value={value} onChange={handleChange}>
          {strategies.map((x) => (
            <Tab
              key={x._id}
              classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
              label={
                <Box display="flex" alignItems="center">
                  {`${t("translation:strategy")} ${x.strategies}`}
                  {getIsJoined(x) ? (
                    <ThumbsUp fontSize="small" className={classes.tabIcon} />
                  ) : (
                    <ThumbsDown fontSize="small" className={classes.tabIcon} />
                  )}
                </Box>
              }
            />
          ))}
        </Tabs>
      </Box>
      <Box py={1} height="100%">
        <AnimatedPaper className={classes.infoRoot} scale={1.02}>
          {strategies.map((x, index) => (
            <div key={x._id} role="tabpanel" hidden={value !== index}>
              {getIsJoined(x) ? (
                <LeagueInfo strategyId={x._id} />
              ) : (
                <NoDataDisplay />
              )}
            </div>
          ))}
          {strategies.length === 0 && <NoDataDisplay />}
          <Box display="flex" justifyContent="center">
            <Link
              to={`/leagues/live/${category}`}
              style={{ textDecoration: "none" }}
            >
              <Button color="secondary" shape="pill">
                {t("goToLeague")}
              </Button>
            </Link>
          </Box>
        </AnimatedPaper>
      </Box>
    </div>
  );
}

const NoDataDisplay = () => {
  const { t } = useTranslation("dashboard");
  return (
    <Box display="flex" flexDirection="column" alignItems="center" py={1}>
      <Typography
        align="center"
        style={{ fontWeight: "bold", marginBottom: 8 }}
      >
        {t('liveLeagueNoDataMsg')}
      </Typography>
      <AnimatedSvg1 />
    </Box>
  );
};
