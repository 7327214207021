import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  makeStyles,
  createStyles,
  Theme,
  Typography,
  Box,
} from "@material-ui/core";
import {
  AccountBalanceWallet,
  AddCircle,
  AttachMoneyRounded,
} from "@material-ui/icons";

import { useTypedSelector } from "../../_redux/rootReducer";
import { selectWalletBalance } from "../../_redux/auth/selectors";

import { Paper, Button } from "../../Components";

import TransactionTable from "./TransactionTable";
import AddFundsDialog from "./AddFundsDialog";
import WithdrawFundsDialog from "./WithdrawFundsDialog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
      minHeight: "100%",
      display: "flex",
      flexDirection: "column",
    },
    sectionHeader: {
      color: "#fff",
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
      marginBottom: theme.spacing(4),
    },
    subtitleText: { color: "#ffffffb3" },
    titleText: { fontWeight: theme.typography.fontWeightBold },
  })
);

const GridTwo: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  
  const walletBalance = useTypedSelector(selectWalletBalance);

  const [addFundsOpen, setAddFundsOpen] = useState(false);
  const openAddFunds = useCallback(() => setAddFundsOpen(true), []);
  const closeAddFunds = useCallback(() => setAddFundsOpen(false), []);

  const [withdrawFundsOpen, setWithdrawFundsOpen] = useState(false);
  const openWithdrawFunds = useCallback(() => setWithdrawFundsOpen(true), []);
  const closeWithdrawFunds = useCallback(() => setWithdrawFundsOpen(false), []);

  return (
    <Paper className={classes.root}>
      <div className={classes.sectionHeader}>
        {/* balance */}
        <Box display="flex" alignItems="center" mb={{ xs: 2, sm: 0 }}>
          <AccountBalanceWallet fontSize="large" />
          <Box ml={1}>
            <Typography variant="body2" className={classes.subtitleText}>
              {t('Current Balance')}
            </Typography>
            <Typography variant="h5" className={classes.titleText}>
              {walletBalance.toFixed(8)}
              <Typography component="span" variant="body2" color="secondary">
                BTC
              </Typography>
            </Typography>
          </Box>
        </Box>
        {/* actions */}
        <Box
          display="flex"
          alignItems="center"
          flexWrap="wrap"
          justifyContent="center"
        >
          <Box ml={2} my={0.5}>
            <Button
              color="secondary"
              shape="pill"
              startIcon={<AddCircle />}
              onClick={openAddFunds}
            >
              {t('Add Fund')}
            </Button>
          </Box>
          <Box ml={2} my={0.5} color="#FC6E4F">
            <Button
              color="inherit"
              shape="pill"
              startIcon={<AttachMoneyRounded />}
              onClick={openWithdrawFunds}
            >
              {t('Withdraw')}
            </Button>
          </Box>
        </Box>
      </div>
      <TransactionTable />
      <AddFundsDialog open={addFundsOpen} handleClose={closeAddFunds} />
      <WithdrawFundsDialog
        open={withdrawFundsOpen}
        handleClose={closeWithdrawFunds}
      />
    </Paper>
  );
};

export default React.memo(GridTwo);
