import React from "react";
import { Redirect, Switch, Route, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { getIsLoggedIn } from "./_redux/auth/selectors";

import AppLayout from "./Layouts/AppLayout";
import {
  Dashboard,
  Leaderboard,
  Leagues,
  LiveLeagueDetail,
  Account,
  Referrals,
  BusinessAssociate,
} from "./Views";
import SocketProvider from "./SocketProvider";

const AppRoutes: React.FC = () => {
  const isLoggedIn = useSelector(getIsLoggedIn);
  const location = useLocation();

  if (!isLoggedIn)
    return <Redirect to={`/auth?nextUrl=${location.pathname}`} />;
  return (
    <SocketProvider>
      <AppLayout>
        <Switch>
          <Route path="/dashboard" exact>
            <Dashboard />
          </Route>
          <Route path="/leagues" exact>
            <Leagues />
          </Route>
          <Route path="/leagues/live/:category" exact>
            <LiveLeagueDetail />
          </Route>
          <Route path="/leaderboard" exact>
            <Leaderboard />
          </Route>
          <Route path="/account" exact>
            <Account />
          </Route>
          <Route path="/referrals" exact>
            <Referrals />
          </Route>
          <Route path="/business-associate" exact>
            <BusinessAssociate />
          </Route>
          <Redirect exact from="/" to="/dashboard" />
        </Switch>
      </AppLayout>
    </SocketProvider>
  );
};

export default React.memo(AppRoutes);
