import React, { useCallback, useState } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import {
  createStyles,
  makeStyles,
  Theme,
  Typography,
  Box,
  Menu,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import { Translate as TranslateIcon } from "@material-ui/icons";

import { Twitter, Telegram, Linkedin, Facebook } from "../_assets/Icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: theme.zIndex.appBar,
      backgroundColor: "#130D4A",
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(0.5, 0),
      position: "fixed",
      bottom: 0,
      left: 0,
      right: 0,
      [theme.breakpoints.up("sm")]: {
        flexDirection: "row",
      },
    },
    column: {
      [theme.breakpoints.up("sm")]: {
        flex: "1 1 0",
      },
    },
    copyText: {
      color: "#576078",
    },
    iconsContainer: {
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
    },
    socialLink: {
      color: "#bdc3ca",
      margin: theme.spacing(0, 0.5),
    },
  })
);

const languages = [
  { label: "English", key: "en" },
  { label: "日本人", key: "ja" },
];

export default function Footer() {
  const classes = useStyles();
  const { i18n, t } = useTranslation();

  const [lngSelectOpen, setLngSelectOpen] = useState<null | HTMLElement>(null);
  const openLngSelect = useCallback(
    (event: React.MouseEvent<HTMLElement>) =>
      setLngSelectOpen(event.currentTarget),
    []
  );
  const closeLngSelect = useCallback(() => setLngSelectOpen(null), []);

  return (
    <div className={classes.root}>
      <Box
        className={classes.column}
        display={{ xs: "none", sm: "flex" }}
        justifyContent="center"
      >
        <IconButton size="small" onClick={openLngSelect} color="secondary">
          <TranslateIcon />
        </IconButton>
        <Menu
          open={!!lngSelectOpen}
          anchorEl={lngSelectOpen}
          onClose={closeLngSelect}
        >
          {languages.map((lng) => (
            <MenuItem
              key={lng.key}
              onClick={() => {
                i18n.changeLanguage(lng.key);
                closeLngSelect();
              }}
            >
              {lng.label}
            </MenuItem>
          ))}
        </Menu>
      </Box>
      <div className={classes.column}>
        <Typography align="center" className={classes.copyText}>
          © {t("copyrightText")}
        </Typography>
      </div>
      {/* Social Links */}
      <div className={clsx(classes.column, classes.iconsContainer)}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://twitter.com/PlayCTLOfficial"
          className={classes.socialLink}
        >
          <Twitter color="inherit" />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://t.me/PlayCTL"
          className={classes.socialLink}
        >
          <Telegram color="inherit" />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/company/playctl/"
          className={classes.socialLink}
        >
          <Linkedin color="inherit" />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.facebook.com/PlayCTLOfficial"
          className={classes.socialLink}
        >
          <Facebook color="inherit" />
        </a>
      </div>
    </div>
  );
}
