// @ts-nocheck
import React from "react";
import SocialLogin from "react-social-login";

import Button from "./Button";

class SocialButton extends React.Component {
  render() {
    const { triggerLogin, children, ...otherProps } = this.props;
    return (
      <Button
        variant="outlined"
        color="secondary"
        onClick={triggerLogin}
        {...otherProps}
      >
        {children}
      </Button>
    );
  }
}

export default SocialLogin(SocialButton);
