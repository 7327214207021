import React from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { getIsLoggedIn } from "./_redux/auth/selectors";

import AuthLayout from "./Layouts/AuthLayout";
import {
  Login,
  Signup,
  Verify,
  RequestPasswordReset,
  ConfirmPasswordReset,
  SocialSignup,
} from "./Views";

const AuthRoutes: React.FC = () => {
  const isLoggedIn = useSelector(getIsLoggedIn);
  const location = useLocation();

  if (isLoggedIn) {
    const nextUrl = new URLSearchParams(location.search).get("nextUrl");
    if (nextUrl) return <Redirect to={nextUrl} />;
    return <Redirect to="/" />;
  }
  return (
    <AuthLayout>
      <Switch>
        <Route path="/auth/signin" exact>
          <Login />
        </Route>
        <Route path="/auth/signup" exact>
          <Signup />
        </Route>
        <Route path="/auth/social" exact>
          <SocialSignup />
        </Route>
        <Route path="/auth/verify" exact>
          <Verify />
        </Route>
        <Route path="/auth/reset-password" exact>
          <RequestPasswordReset />
        </Route>
        <Route path="/auth/reset-password-confirm" exact>
          <ConfirmPasswordReset />
        </Route>
        <Redirect from="/auth" exact to={`/auth/signin${location.search}`} />
      </Switch>
    </AuthLayout>
  );
};

export default React.memo(AuthRoutes);
