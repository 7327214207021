import { Paper, createStyles, Theme, withStyles } from "@material-ui/core";

export default withStyles((theme: Theme) =>
  createStyles({
    root: {
      boxShadow: "0px 3px 6px #00000099",
      borderRadius: 6,
    },
  })
)(Paper);
