import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  createStyles,
  makeStyles,
  Theme,
  Typography,
  Box,
  MenuItem,
  Menu,
} from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";

import { leagueCategory } from "../../_redux/leaderboard/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerAction: { cursor: "pointer" },
    fontBold: {
      fontWeight: "bold",
    },
  })
);

const categroies: leagueCategory[] = ["Bronze", "Silver", "Gold"];

interface CategorySelectProps {
  category: leagueCategory;
  setCategory: React.Dispatch<React.SetStateAction<leagueCategory>>;
}

const CategorySelect: React.FC<CategorySelectProps> = (props) => {
  const { category, setCategory } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = useCallback(() => setAnchorEl(null), []);

  const handleClick = useCallback(
    (event, cat: leagueCategory) => {
      setCategory(cat);
      setAnchorEl(null);
    },
    [setCategory]
  );

  return (
    <>
      <div className={classes.headerAction} onClick={handleOpen}>
        <Typography variant="body2" color="textSecondary">
          {t('category')}
        </Typography>
        <Box display="flex" alignItems="center">
          <Typography className={classes.fontBold}>{t(category)}</Typography>
          <KeyboardArrowDown fontSize="small" color="secondary" />
        </Box>
      </div>
      <Menu
        id="category-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {categroies.map((cat) => (
          <MenuItem
            key={cat}
            disabled={cat === category}
            selected={cat === category}
            onClick={(e) => handleClick(e, cat)}
          >
            {t(cat)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default React.memo(CategorySelect);
