import React from "react";
import { useTranslation } from "react-i18next";
import {
  makeStyles,
  createStyles,
  Theme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

import { LeagueWinnerItem } from "../../../_redux/leaderboard/types";

import { Paper } from "../../../Components";
import UserRow from "./UserRow";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      boxShadow: "unset",
    },
    table: {
      minWidth: "100%",
    },
    headerCell: {
      color: "#576078",
      fontSize: "1.125rem",
      fontWeight: theme.typography.fontWeightBold,
      borderBottom: "unset",
    },
  })
);

interface FinalistsListTableProps {
  winners: LeagueWinnerItem[];
}
const FinalistsListTable: React.FC<FinalistsListTableProps> = (props) => {
  const classes = useStyles();
  const { winners } = props;
  const { t } = useTranslation("leaderboard");

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.headerCell} align="center">
              {t('League')}
            </TableCell>
            <TableCell className={classes.headerCell} align="center">
              {t('Winner')}
            </TableCell>
            <TableCell className={classes.headerCell} align="center">
              {t('Played')}
            </TableCell>
            <TableCell className={classes.headerCell} align="center">
              {t('translation:Date')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {winners.map((winner) => (
            <UserRow key={winner._id} item={winner} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default React.memo(FinalistsListTable);
