import { format as dfFormat } from "date-fns";
import { enUS, ja } from "date-fns/locale";
import i18n from "../i18n";

const locales: { [key: string]: Locale } = { enUS, ja };

export const format: typeof dfFormat = (date, formatString, options) => {
  const locale = locales[i18n.language] ?? enUS;
  return dfFormat(date, formatString, { locale, ...options });
};
