import React, { ReactNode, useMemo } from "react";

import {
  createStyles,
  makeStyles,
  Theme,
  Typography,
  IconButton,
} from "@material-ui/core";
import { MoreHoriz, KeyboardArrowRight } from "@material-ui/icons";
import { Paper } from "../../Components";

interface ICustomPaperProps {
  title?: string;
  titles?: string[];
  handleReset?: (e: React.MouseEvent) => void;
  indicatorColor: string;
  children: ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
      color: "white",
    },
    header: {
      display: "flex",
      alignItems: "center",
      marginBottom: theme.spacing(3),
    },
    indicator: (props: ICustomPaperProps) => ({
      width: 12,
      height: 12,
      borderRadius: 6,
      background: `${props.indicatorColor} 0% 0% no-repeat padding-box`,
      marginRight: theme.spacing(1),
    }),
    grow: {
      flexGrow: 1,
    },
    titlesContainer: {
      display: "flex",
      flexGrow: 1,
    },
    firstTitle: {
      cursor: "pointer",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    actionIconButton: {
      color: "#748AA1",
    },
  })
);

export default function CustomPaper(props: ICustomPaperProps) {
  const { title, titles, handleReset, children } = props;
  const classes = useStyles(props);

  const renderTitle = useMemo(() => {
    if (title || titles?.length === 1) {
      return (
        <Typography variant="h6" className={classes.grow}>
          {title ?? titles![0]}
        </Typography>
      );
    }
    if (titles)
      return (
        <div className={classes.titlesContainer}>
          {titles.map((t, i, arr) => (
            <React.Fragment key={i}>
              <Typography
                onClick={i === 0 ? handleReset : undefined}
                className={i === 0 ? classes.firstTitle : undefined}
              >
                {t}
              </Typography>
              {i < arr.length - 1 && <KeyboardArrowRight htmlColor="#FC6E4F" />}
            </React.Fragment>
          ))}
        </div>
      );
    return null;
  }, [
    classes.firstTitle,
    classes.grow,
    classes.titlesContainer,
    handleReset,
    title,
    titles,
  ]);

  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <div className={classes.indicator} />
        {renderTitle}
        <IconButton className={classes.actionIconButton}>
          <MoreHoriz />
        </IconButton>
      </div>
      {children}
    </Paper>
  );
}
