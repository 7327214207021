import React, { useMemo } from "react";
import { format } from "../../_utils/date";
import { useTranslation } from "react-i18next";

import {
  makeStyles,
  createStyles,
  Theme,
  Avatar,
  Box,
  Typography,
  IconButton,
} from "@material-ui/core";
import { AddAPhoto, EditRounded } from "@material-ui/icons";

import { useTypedSelector } from "../../_redux/rootReducer";
import {
  selectUserImg,
  selectUserName,
  selectUserjoiningDate,
  selectUserEmail,
} from "../../_redux/auth/selectors";

import ImageUpload from "./ImageUpload";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "center",
    },
    avatarContainer: {
      margin: theme.spacing(2, 0),
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      height: 240,
      width: 240,
      backgroundColor: "#fff",
      cursor: "pointer",
    },
    editIcon: {
      backgroundColor: "#ff542e",
      top: -24,
      "&:hover": {
        backgroundColor: "#FC6E4F",
      },
    },
    titleText: {
      color: "#fff",
      fontWeight: theme.typography.fontWeightBold,
    },
    subtitleText: {
      color: "#ffffffb3",
    },
    labelText: {
      color: "#FC6E4F",
      fontWeight: theme.typography.fontWeightBold,
    },
    valueText: {
      color: theme.palette.secondary.main,
    },
  })
);

const GridOne: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const userImg = useTypedSelector(selectUserImg);
  const userName = useTypedSelector(selectUserName);
  const userEmail = useTypedSelector(selectUserEmail);
  const joiningDate = useTypedSelector(selectUserjoiningDate);

  const joiningDateDisplay = useMemo(() => {
    if (!joiningDate) return "";
    return format(new Date(joiningDate), "d MMM yyyy");
  }, [joiningDate]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className={classes.root}>
      <div>
        <div className={classes.avatarContainer}>
          <Avatar
            className={classes.avatar}
            src={userImg}
            alt="User profile pic"
            onClick={handleOpen}
          >
            <Box color="#FC6E4F">
              <AddAPhoto fontSize="large" />
            </Box>
          </Avatar>
          <Box color="white">
            <IconButton
              onClick={handleOpen}
              color="inherit"
              className={classes.editIcon}
            >
              <EditRounded />
            </IconButton>
          </Box>
        </div>
        <ImageUpload open={open} handleClose={handleClose} />
        <div>
          <Typography className={classes.subtitleText}>{t('Welcome')}</Typography>
          <Typography className={classes.titleText} variant="h4">
            {userName}
          </Typography>
        </div>
        <Box my={2}>
          <Typography className={classes.labelText}>
            {t('Joined on')}:{" "}
            <Typography
              className={classes.valueText}
              variant="body2"
              component="span"
            >
              {joiningDateDisplay}
            </Typography>
          </Typography>
          <Typography className={classes.labelText}>
            {t('Email ID')}:{" "}
            <Typography
              className={classes.valueText}
              variant="body2"
              component="span"
            >
              {userEmail}
            </Typography>
          </Typography>
        </Box>
      </div>
    </div>
  );
};

export default React.memo(GridOne);
