import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { differenceInMinutes } from "date-fns";
import { useTranslation } from "react-i18next";

import {
  createStyles,
  makeStyles,
  Theme,
  Box,
  IconButton,
  Typography,
} from "@material-ui/core";
import { ArrowForwardIosRounded } from "@material-ui/icons";
import { AnimatedPaper } from "../../../Components";

import { useTypedSelector } from "../../../_redux/rootReducer";
import {
  makeLiveStrategiesForCategorySelector,
  makeLiveJoinedStrategiesForcategorySelector,
} from "../../../_redux/league/selectors";

interface LeagueCardProps {
  category: "Bronze" | "Silver" | "Gold";
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      textDecoration: "none",
    },
    root: {
      padding: theme.spacing(2, 3),
      backgroundColor: "#060220",
      color: "white",
    },
    subtitle: {
      fontWeight: 600,
    },
    info: {
      fontWeight: theme.typography.fontWeightBold,
    },
  })
);

function LeagueCard(props: LeagueCardProps) {
  const { category } = props;
  const classes = useStyles();
  const { t } = useTranslation("league");

  const selectLiveStrats = useMemo(makeLiveStrategiesForCategorySelector, []);
  const liveStarts = useTypedSelector((state) =>
    selectLiveStrats(state, category)
  );
  const selectJoinedStrats = useMemo(
    makeLiveJoinedStrategiesForcategorySelector,
    []
  );
  const joinedStrats = useTypedSelector((state) =>
    selectJoinedStrats(state, category)
  );

  const timeRemaining = useMemo(() => {
    if (liveStarts.length === 0) return "";
    const endTime = new Date(liveStarts[0].endDate);
    let finalString = "";
    let diffMin = differenceInMinutes(endTime, new Date());
    // days
    const diffDays = ~~(diffMin / (24 * 60));
    diffMin = diffMin % (24 * 60);
    if (diffDays > 0)
      finalString += `${diffDays} day${diffDays > 1 ? "s" : ""} `;
    // hours
    const diffHours = ~~(diffMin / 60);
    diffMin = diffMin % 60;
    if (diffHours > 0)
      finalString += `${diffHours} hour${diffHours > 1 ? "s" : ""} `;
    // minutes
    if (diffMin > 0) finalString += `${diffMin} min${diffMin > 1 ? "s" : ""} `;
    return finalString + "left";
  }, [liveStarts]);

  const infoText = useMemo(() => {
    if (joinedStrats.length === 0)
      return t("You are not playing in this league");
    return t("You are playing with", { count: joinedStrats.length });
  }, [joinedStrats.length, t]);

  if (liveStarts.length === 0) return null;
  return (
    <Link to={`/leagues/live/${category}`} className={classes.wrapper}>
      <AnimatedPaper className={classes.root}>
        <Box display="flex" mb={2}>
          <Box flexGrow={1}>
            <Typography>{t(`translation:${category}`)}</Typography>
            <Typography className={classes.subtitle} variant="body2">
              {timeRemaining}
            </Typography>
          </Box>
          <IconButton color="secondary">
            <ArrowForwardIosRounded fontSize="large" />
          </IconButton>
        </Box>
        <Typography
          align="center"
          className={classes.info}
          color={joinedStrats.length === 0 ? "error" : "secondary"}
        >
          {infoText}
        </Typography>
      </AnimatedPaper>
    </Link>
  );
}

export default React.memo(LeagueCard);
