import React from "react";
import { useTranslation } from "react-i18next";

import { Box } from "@material-ui/core";

import { LeagueWinnerItem } from "../../../_redux/leaderboard/types";

import { Button } from "../../../Components";
import UserItem from "./UserItem";

interface FinalistsListBigProps {
  winners: LeagueWinnerItem[];
}
const FinalistsListBig: React.FC<FinalistsListBigProps> = (props) => {
  const { winners } = props;
  const { t } = useTranslation("leaderboard");

  return (
    <div>
      <Box display="flex" justifyContent="space-evenly" flexWrap="wrap">
        {winners.map((win) => (
          <UserItem key={win._id} user={win} />
        ))}
      </Box>
      <Box mt={2} display="flex" justifyContent="center">
        <Button color="secondary" shape="pill">
          {t('View All')}
        </Button>
      </Box>
    </div>
  );
};

export default React.memo(FinalistsListBig);
