import { createSelector } from "reselect";
import { format, isPast, isFuture, isEqual } from "date-fns";

import { league, LeagueInfo } from "./types";

type category = "Bronze" | "Silver" | "Gold";

export const makeLeaguesForCategorySelector = () =>
  createSelector(
    (state: RootState) => state.league,
    (_: RootState, category: category) => category,
    (league, category) =>
      league.leagues.find((x) => x.leaguesSeason?.leagueCategory === category)
  );

export const makeStrategiesForDateAndCategory = () =>
  createSelector(
    (state: RootState, category: category) =>
      makeLeaguesForCategorySelector()(state, category),
    (_, __, date: Date) => format(date, "yyyy-MM-dd"),
    (leagues, date) =>
      leagues?.leagueLookup.filter((x) => x.startDate.startsWith(date)) ?? []
  );

export const makeLiveStrategiesForCategorySelector = () =>
  createSelector(
    (state: RootState, category: category) =>
      makeLeaguesForCategorySelector()(state, category),
    (leagues) =>
      leagues?.leagueLookup.filter(
        (x) => isPast(new Date(x.startDate)) && isFuture(new Date(x.endDate))
      ) ?? []
  );

export const makeCompletedStrategiesForCategorySelector = () =>
  createSelector(
    (state: RootState, category: category) =>
      makeLeaguesForCategorySelector()(state, category),
    (leagues) =>
      leagues?.leagueLookup.filter((x) => isPast(new Date(x.endDate))) ?? []
  );

export const selectJoinedStrategies = (state: RootState) =>
  state.league.joinedLeagues;

export const makeJoinedStrategiesForCategorySelector = () =>
  createSelector(
    selectJoinedStrategies,
    (_: RootState, category: category) => category,
    (joinedLeagues, category) =>
      joinedLeagues.filter((x) => x.leagueCategory === category)
  );

export const makeJoinedStrategiesForDateAndCategory = () =>
  createSelector(
    (state: RootState, category: category) =>
      makeJoinedStrategiesForCategorySelector()(state, category),
    (_, __, date) => format(date, "yyyy-MM-dd"),
    (joinedLeagues, date) =>
      joinedLeagues.filter((x) => x.startDate.startsWith(date))
  );

export const makeLiveJoinedStrategiesForcategorySelector = () =>
  createSelector(
    (state: RootState, category: category) =>
      makeJoinedStrategiesForCategorySelector()(state, category),
    (joinedLeagues) =>
      joinedLeagues.filter(
        (x) => isPast(new Date(x.startDate)) && isFuture(new Date(x.endDate))
      )
  );

export const selectAllLiveJoinedStrategies = createSelector(
  selectJoinedStrategies,
  (joinedLeagues) =>
    joinedLeagues.filter(
      (x) => isPast(new Date(x.startDate)) && isFuture(new Date(x.endDate))
    )
);

export const selectNextJoinedLeagues = createSelector<
  RootState,
  league[],
  [Date | null, league[]]
>(selectJoinedStrategies, (joinedLeagues) => {
  const next = joinedLeagues.find((x) => isFuture(new Date(x.startDate)));
  if (!next) return [null, []];
  const nextDate: Date = new Date(next.startDate);
  const nextLeagues = joinedLeagues.filter((x) =>
    isEqual(nextDate, new Date(x.startDate))
  );
  return [nextDate, nextLeagues];
});

export const selectAllUpcomingStrategies = createSelector(
  (state: RootState) =>
    state.league.leagues.reduce(
      (acc: league[], crr) => [...acc, ...crr.leagueLookup],
      []
    ),
  (leagues) => leagues.filter((x) => isFuture(new Date(x.startDate)))
);

export const selectAllJoinedUpcomingStrategies = createSelector(
  selectJoinedStrategies,
  (joined) => joined.filter((x) => isFuture(new Date(x.startDate)))
);

export const makeLeagueInfoSelector = () =>
  createSelector<
    RootState,
    string,
    { [id: string]: LeagueInfo },
    string,
    LeagueInfo | null
  >(
    (state: RootState) => state.league.leagueInfos,
    (_: RootState, id: string) => id,
    (leagueInfos, id) => leagueInfos?.[id] ?? null
  );

export const makeStrategyByIdSelector = () =>
  createSelector(
    (state: RootState) => state.league.leagues,
    (_: RootState, id: string) => id,
    (leagues, id) =>
      leagues
        .reduce((acc: league[], crr) => [...acc, ...crr.leagueLookup], [])
        .find((x) => x._id === id) ?? null
  );

export const makeNetworthHistorySelector = () =>
  createSelector(
    (state: RootState) => state.league.networthHistory,
    (_: RootState, strategyId: string) => strategyId,
    (history, id) => history?.[id] ?? null
  );

export const selectCurrentSeason = (state: RootState) =>
  state.league.currentSeason;

export const selectAllCurrencyHistory = (state: RootState) =>
  state.league.currencyHistory;

export const makeCurrencyHistoryForSymbolSelector = () =>
  createSelector(
    selectAllCurrencyHistory,
    (_: RootState, symbol: string) => symbol,
    (currencyHistory, symbol) => currencyHistory[symbol] ?? []
  );
