import { createSelector } from "reselect";
import { selectCurrentSeason } from "../league/selectors";
import { BaData } from "./types";

export const getIsLoggedIn = (state: RootState) => !!state.auth.token;
export const getToken = (state: RootState) => state.auth.token;

export const selectWalletBalance = (state: RootState) =>
  state.auth.walletBTC ?? 0;
export const selectOrderId = (state: RootState) => state.auth.orderId;
export const selectBtcRate = (state: RootState) => state.auth.BTCRate;

export const selectUserEmail = (state: RootState) => state.auth.email ?? "";
export const selectUserName = (state: RootState) => state.auth.name ?? "";
export const selectUserId = (state: RootState) => state.auth._id;
export const selectUserImg = (state: RootState) =>
  state.auth.profileImg ?? undefined;
export const selectUserjoiningDate = ({ auth }: RootState) => auth.joiningDate;
export const selectUserRefCode = ({ auth }: RootState) => auth.refcode;

export const selectWalletTransactions = ({ auth }: RootState) =>
  auth.walletTransactions;

export const selectAllReferrals = ({ auth }: RootState) => auth.referrals;
export const selectCurrentSeasonReferrals = createSelector(
  selectAllReferrals,
  selectCurrentSeason,
  (refs, crrSeason) => {
    const res = refs[crrSeason?.season ?? ""];
    return res as typeof res | undefined;
  }
);
export const makeReferralsForSeasonSelector = () =>
  createSelector(
    selectAllReferrals,
    (_: RootState, season: string) => season,
    (refs, season) => {
      const res = refs[season];
      return res as typeof res | undefined;
    }
  );

export const selectRefCodeRquired = (state: RootState) =>
  state.auth.refCodeRequired;

export const selectBaData = (state: RootState) => state.auth.baData;

export const selectCurrentSeasonBaData = createSelector(
  selectBaData,
  selectCurrentSeason,
  (baData, crrSeason) =>
    (baData[crrSeason?.season ?? ""] ?? null) as BaData | null
);

export const makeBaDataForSeasonSelector = () =>
  createSelector<
    RootState,
    string,
    { [season: string]: BaData },
    string,
    BaData | null
  >(
    selectBaData,
    (_: RootState, season: string) => season,
    (baData, season) => baData[season] ?? null
  );

export const selectAllSeasonsTemplate = (state: RootState) =>
  state.auth.seasonTemplate;

export const selectCurrentSeasonTemplate = createSelector(
  selectAllSeasonsTemplate,
  selectCurrentSeason,
  (templates, crrSeason) => templates[crrSeason?.season ?? ""] ?? []
);

export const makeTemplateForSeasonSelector = () =>
  createSelector(
    selectAllSeasonsTemplate,
    (_: RootState, season: string) => season,
    (templates, season) => templates[season] ?? []
  );
