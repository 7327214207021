import React, { ReactNode } from "react";
import socketIOClient from "socket.io-client";

const leagueDetailSocket = socketIOClient.connect(
  `${process.env.REACT_APP_API_ROOT}leaguDetail`
);

const sockets = { leagueDetailSocket };
export const SocketContext = React.createContext(sockets);
SocketContext.displayName = "SocketContext";

const SocketProvider: React.FC<{ children: ReactNode }> = (props) => {
  return (
    <SocketContext.Provider value={sockets}>
      {props.children}
    </SocketContext.Provider>
  );
};

export default React.memo(SocketProvider);
