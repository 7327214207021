import React, { useCallback, useMemo, useState } from "react";

import {
  makeStyles,
  createStyles,
  Theme,
  TablePagination,
  Table,
  TableBody,
  TableContainer,
  Paper,
} from "@material-ui/core";

import { LeagueInfo } from "../../../../_redux/league/types";

import TableHead from "./Head";
import Row from "./Row";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(0, 5),
      },
    },
    tableContainer: {
      backgroundColor: "transparent",
    },
    paginationSelectIcon: {
      color: theme.palette.text.primary,
    },
  })
);

interface LeagueTableProps {
  openBuy: (arg0: string) => void;
  openSell: (arg0: string) => void;
  stratInfo: LeagueInfo;
}

const LeagueTable: React.FC<LeagueTableProps> = (props) => {
  const classes = useStyles();
  const { openBuy, openSell, stratInfo } = props;
  const { currenciesData, baseCurrency } = stratInfo;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);
  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    },
    []
  );

  const currencies = useMemo(
    () =>
      currenciesData
        ?.filter((x) => x.symbol !== baseCurrency)
        .sort((a, b) => (b.volume24HOURTO ?? 0) - (a.volume24HOURTO ?? 0)) ??
      [],
    [baseCurrency, currenciesData]
  );

  const rows = useMemo(
    () =>
      currencies.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [currencies, page, rowsPerPage]
  );

  return (
    <div className={classes.root}>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table aria-label="collapsible table">
          <TableHead />
          <TableBody>
            {rows.map((row, i) => (
              <Row
                key={row.alias}
                row={row}
                index={i}
                onBuy={openBuy}
                onSell={openSell}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 20, 50]}
        component="div"
        count={currencies.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        SelectProps={{
          classes: { icon: classes.paginationSelectIcon },
        }}
      />
    </div>
  );
};

export default React.memo(LeagueTable);
