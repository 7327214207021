import React, { useCallback, useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { addHours, isBefore } from "date-fns";
import {
  BarChart,
  Bar,
  ReferenceLine,
  XAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import {
  Box,
  Typography,
  createStyles,
  makeStyles,
  Theme,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";

import { useTypedSelector } from "../../_redux/rootReducer";
import {
  LeagueCategory,
  NetWorthHistoryItem,
  league,
} from "../../_redux/league/types";
import {
  makeNetworthHistorySelector,
  makeLiveJoinedStrategiesForcategorySelector,
} from "../../_redux/league/selectors";

import { format } from "../../_utils/date";

import { AnimatedPaper } from "../../Components";

const categroies: LeagueCategory[] = ["Bronze", "Silver", "Gold"];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    graphPaper: {
      padding: theme.spacing(2),
      backgroundColor: "#4043FF",
    },
    dropdownControl: {
      display: "flex",
      alignItems: "center",
      margin: theme.spacing(0, 1),
      cursor: "pointer",
    },
  })
);

const LiveLeagueGraph: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  // Category Anchor
  const [categoryAnchorEl, setCategoryAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const handleCategoryOpen = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setCategoryAnchorEl(event.currentTarget);
    },
    []
  );
  const handleCategoryClose = useCallback(() => setCategoryAnchorEl(null), []);
  // Strat Anchor
  const [stratAnchorEl, setStratAnchorEl] = useState<null | HTMLElement>(null);
  const handleStratOpen = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setStratAnchorEl(event.currentTarget);
    },
    []
  );
  const handleStratClose = useCallback(() => setStratAnchorEl(null), []);

  const [category, setCategory] = useState<LeagueCategory>("Bronze");
  const handleCategorySelect = useCallback((cat: LeagueCategory) => {
    setCategory(cat);
    setCategoryAnchorEl(null);
  }, []);

  const [strategyId, setStrategyId] = useState<string>("");
  const handleStrategySelect = useCallback((strat: string) => {
    setStrategyId(strat);
    setStratAnchorEl(null);
  }, []);

  const selectJoinedStrats = useMemo(
    makeLiveJoinedStrategiesForcategorySelector,
    []
  );
  const joinedStrats = useTypedSelector((state) =>
    selectJoinedStrats(state, category)
  );
  const selectNetWorthHistory = useMemo(makeNetworthHistorySelector, []);
  const netWorthHistory = useTypedSelector((state) =>
    selectNetWorthHistory(state, strategyId)
  );

  useEffect(() => {
    setStrategyId(joinedStrats[0]?._id ?? "");
  }, [joinedStrats]);

  return (
    <AnimatedPaper className={classes.graphPaper}>
      <Box color="#fff" display="flex" alignItems="center" my={2}>
        <Typography>{t('My Leagues')}</Typography>
        <Box flexGrow={1} />
        <div className={classes.dropdownControl} onClick={handleCategoryOpen}>
          <Typography variant="body2">{t(category)}</Typography>
          <ArrowDropDown />
        </div>
        <Menu
          id="category-menu"
          anchorEl={categoryAnchorEl}
          keepMounted
          open={Boolean(categoryAnchorEl)}
          onClose={handleCategoryClose}
        >
          {categroies.map((cat) => (
            <MenuItem
              key={cat}
              disabled={cat === category}
              selected={cat === category}
              onClick={(e) => handleCategorySelect(cat)}
            >
              {t(cat)}
            </MenuItem>
          ))}
        </Menu>
        <div className={classes.dropdownControl} onClick={handleStratOpen}>
          <Typography variant="body2">
            {t('strategy')}{" "}
            {joinedStrats.find((x) => x._id === strategyId)?.strategies}
          </Typography>
          <ArrowDropDown />
        </div>
        <Menu
          id="category-menu"
          anchorEl={stratAnchorEl}
          keepMounted
          open={Boolean(stratAnchorEl)}
          onClose={handleStratClose}
        >
          {joinedStrats.map((cat) => (
            <MenuItem
              key={cat._id}
              disabled={cat._id === strategyId}
              selected={cat._id === strategyId}
              onClick={(e) => handleStrategySelect(cat._id)}
            >
              {t('strategy')} {cat.strategies}
            </MenuItem>
          ))}
        </Menu>
      </Box>
      {/* Graph */}
      {!!netWorthHistory?.length && (
        <Graph
          netWorthHistory={netWorthHistory}
          strategy={joinedStrats.find((x) => x._id === strategyId)}
        />
      )}
      <Box color="#FC6E4F" display="flex" justifyContent="space-between">
        <Typography variant="body2">0 hr</Typography>
        <Typography variant="body2">48 hr</Typography>
      </Box>
    </AnimatedPaper>
  );
};

export default React.memo(LiveLeagueGraph);

const Graph: React.FC<{
  netWorthHistory: NetWorthHistoryItem[];
  strategy?: league;
}> = (props) => {
  const { netWorthHistory, strategy } = props;

  const data = useMemo(() => {
    if (!(strategy && netWorthHistory.length)) return null;
    const fillLeft: NetWorthHistoryItem[] = [];
    const startDate = new Date(strategy.startDate);
    for (
      var d = startDate;
      isBefore(d, new Date(netWorthHistory[0].time));
      d = addHours(d, 1)
    ) {
      // console.log(d);
      fillLeft.push({
        time: format(d, "hh:mm"),
        netWorth: 0,
        _id: d.toISOString(),
      });
    }
    const actualData = netWorthHistory.map((x) => ({
      ...x,
      time: format(new Date(x.time), "hh:mm"),
    }));
    return [...fillLeft, ...actualData];
  }, [netWorthHistory, strategy]);

  if (!data) return null;
  return (
    <ResponsiveContainer width="100%" aspect={16 / 9}>
      <BarChart data={data} margin={{ top: 5, right: 5, left: 0, bottom: 5 }}>
        <XAxis dataKey="time" stroke="white" />
        <Tooltip
          cursor={{ fill: "#3BE4CE80" }}
          wrapperStyle={{ color: "#3BE4CE" }}
        />
        <ReferenceLine y={0} stroke="#ffffff80" />
        <Bar dataKey="netWorth" fill="#3BE4CE" />
      </BarChart>
    </ResponsiveContainer>
  );
};
