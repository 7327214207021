import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  makeStyles,
  Theme,
  createStyles,
  Box,
  Typography,
} from "@material-ui/core";

import { useTypedSelector } from "../../_redux/rootReducer";
import { makeLeagueInfoSelector } from "../../_redux/league/selectors";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    lableText: {
      fontWeight: "bold",
    },
  })
);

function HeaderRight(props: { strategyId: string }) {
  const classes = useStyles();
  const { strategyId } = props;
  const { t } = useTranslation("league");

  const selectLeagueInfo = useMemo(makeLeagueInfoSelector, []);
  const leagueInfo = useTypedSelector((state) =>
    selectLeagueInfo(state, strategyId)
  );

  if (!leagueInfo) return null;
  return (
    <Box display="flex" justifyContent={{ xs: "flex-start", sm: "flex-end" }}>
      {/* labels */}
      <Box mr={2}>
        <Typography className={classes.lableText}>
          {t('Total Participants')}
        </Typography>
        <Typography className={classes.lableText}>{t('Total Currencies')}</Typography>
        <Typography className={classes.lableText}>
          {t('Total Transactions')}
        </Typography>
      </Box>
      {/* values */}
      <Box>
        <Typography color="secondary" className={classes.lableText}>
          {leagueInfo.participants}
        </Typography>
        <Typography color="secondary" className={classes.lableText}>
          {leagueInfo.currencies}
        </Typography>
        <Typography color="secondary" className={classes.lableText}>
          {leagueInfo.transaction?.length ?? 0}
        </Typography>
      </Box>
    </Box>
  );
}

export default React.memo(HeaderRight);
