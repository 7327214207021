import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  createStyles,
  makeStyles,
  Theme,
  Box,
  Typography,
  MenuItem,
  Menu,
  useMediaQuery,
} from "@material-ui/core";
import { ExpandMoreRounded } from "@material-ui/icons";

import { makeLiveJoinedStrategiesForcategorySelector } from "../../../_redux/league/selectors";
import { useTypedSelector } from "../../../_redux/rootReducer";

import { ChartLine } from "../../../_assets/Icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: "bold",
    },
    categoryContainer: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
  })
);

type LeagueCategory = "Bronze" | "Silver" | "Gold";
interface LiveLeagueHeaderProps {
  category: LeagueCategory;
  handleCategoryChange: (
    event: React.ChangeEvent<{}>,
    newValue: LeagueCategory
  ) => void;
}

const categroies: LeagueCategory[] = ["Bronze", "Silver", "Gold"];

const LiveLeagueHeader: React.FC<LiveLeagueHeaderProps> = (props) => {
  const { category, handleCategoryChange } = props;
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const { t } = useTranslation("dashboard");

  const selectJoinedStrats = useMemo(
    makeLiveJoinedStrategiesForcategorySelector,
    []
  );
  const joinedStrats = useTypedSelector((state) =>
    selectJoinedStrats(state, category)
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = useCallback(() => setAnchorEl(null), []);

  const handleClick = useCallback(
    (event, cat: LeagueCategory) => {
      handleCategoryChange(event, cat);
      setAnchorEl(null);
    },
    [handleCategoryChange]
  );

  return (
    <Box display="flex" flexWrap="wrap">
      <Box display="flex" alignItems="center">
        <ChartLine />
        <Box ml={2}>
          <Typography variant="body2" color="textSecondary">
            {t("myLiveLeagues")}
          </Typography>
          <Typography
            className={classes.title}
            variant={isMobile ? "h5" : "h4"}
          >
            {t("playing")}{" "}
            <Typography variant="body2" color="secondary" component="span">
              {t("countStrategy", { count: joinedStrats.length })}
            </Typography>
          </Typography>
        </Box>
      </Box>
      <Box flexGrow={1} />
      <Box>
        <Typography color="textSecondary" align={isMobile ? "left" : "right"}>
          {t('translation:category')}
        </Typography>
        <div className={classes.categoryContainer} onClick={handleOpen}>
          <Typography
            variant={isMobile ? "h5" : "h4"}
            className={classes.title}
          >
            {t(`translation:${category}`)}
          </Typography>
          <ExpandMoreRounded fontSize="small" color="secondary" />
        </div>
        <Menu
          id="category-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {categroies.map((cat) => (
            <MenuItem
              key={cat}
              disabled={cat === category}
              selected={cat === category}
              onClick={(e) => handleClick(e, cat)}
            >
              {t(`translation:${cat}`)}
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </Box>
  );
};

export default React.memo(LiveLeagueHeader);
