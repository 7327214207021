import React, { useEffect } from "react";
import { animated, useSpring } from "react-spring";
import { useTranslation } from "react-i18next";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Box, Grid, Typography } from "@material-ui/core";

import { useTypedSelector } from "../../../_redux/rootReducer";
import {
  selectJoinedStrategies,
  selectAllJoinedUpcomingStrategies,
} from "../../../_redux/league/selectors";

import { AnimatedPaper } from "../../../Components";

const ATypography = animated(Typography);
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    summaryCard: {
      borderRadius: 10,
      padding: theme.spacing(1),
    },
    summaryItem: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      color: "#FFFFFF",
    },
    summaryTitle: {
      fontWeight: "bold",
      fontSize: 36,
      letterSpacing: -0.5,
    },
    summaryText: {
      fontWeight: 500,
    },
  })
);

const transNum = (num: number): number => ~~num;
const transMoney = (num: number): string => `$${(num / 1000).toFixed()}K`;

function Summary() {
  const classes = useStyles();
  const { t } = useTranslation("dashboard");

  const joinedStrategies = useTypedSelector(selectJoinedStrategies);
  const upcomingJoinedStrats = useTypedSelector(
    selectAllJoinedUpcomingStrategies
  );

  const [animatedProps, set] = useSpring(() => ({
    ticketsPurchased: 0,
    worthTrade: 0,
    upcomingLeague: 0,
  }));

  useEffect(() => {
    const worthTrade = joinedStrategies.reduce(
      (acc, crr) => acc + Number(crr.leagueAmount) ?? 0,
      0
    );
    set({ ticketsPurchased: joinedStrategies.length, worthTrade: worthTrade });
  }, [joinedStrategies, set]);
  useEffect(() => {
    set({ upcomingLeague: upcomingJoinedStrats.length });
  }, [upcomingJoinedStrats.length, set]);

  return (
    <AnimatedPaper className={classes.summaryCard}>
      <Grid container spacing={0}>
        {/* Tickets Purchased */}
        <Grid item xs={6} lg={4} className={classes.summaryItem}>
          <ATypography color="secondary" className={classes.summaryTitle}>
            {animatedProps.ticketsPurchased.interpolate(transNum)}
          </ATypography>
          <Typography className={classes.summaryText} align="center">
            {t("ticketsPurchased")}
          </Typography>
        </Grid>
        {/* Worth Trade */}
        <Box
          clone
          borderLeft="1px solid white"
          borderRight={{ lg: "1px solid white" }}
        >
          <Grid item xs={6} lg={4} className={classes.summaryItem}>
            <ATypography color="secondary" className={classes.summaryTitle}>
              {animatedProps.worthTrade.interpolate(transMoney)}
            </ATypography>
            <Typography className={classes.summaryText}>
              {t("worthTrade")}
            </Typography>
          </Grid>
        </Box>
        {/* Upcoming League */}
        <Box clone borderTop={{ xs: "1px solid white", lg: "unset" }}>
          <Grid item xs={12} lg={4} className={classes.summaryItem}>
            <ATypography color="secondary" className={classes.summaryTitle}>
              {animatedProps.upcomingLeague.interpolate(transNum)}
            </ATypography>
            <Typography className={classes.summaryText}>
              {t('upcomingLeague')}
            </Typography>
          </Grid>
        </Box>
      </Grid>
    </AnimatedPaper>
  );
}

export default React.memo(Summary);
