const domain =
  process.env.NODE_ENV === "development" ? "localhost" : "playctl.net";

  export const setAuthTokenCookie = (token: string) => {
  document.cookie = `token=${token};path=/;domain=${domain};`;
};

export const removeAuthTokenCookie = () => {
  document.cookie = `token=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=${domain};`;
};
