import { compareAsc } from "date-fns";
import * as types from "./types";
import * as authTypes from "../auth/types";

interface LeagueState {
  leagues: {
    leagueLookup: types.league[];
    leaguesSeason: types.leaguesSeason;
  }[];
  joinedLeagues: types.league[];
  leagueInfos: { [id: string]: types.LeagueInfo };
  networthHistory: { [id: string]: types.NetWorthHistoryItem[] };
  currentSeason: null | types.CurrentSeason;
  currencyHistory: { [symbol: string]: types.CurrencyHistoryItem[] };
}

const initialState: LeagueState = {
  leagues: [],
  joinedLeagues: [],
  leagueInfos: {},
  networthHistory: {},
  currentSeason: null,
  currencyHistory: {},
};

export default function (
  state: LeagueState = initialState,
  action: types.LeagueActionTypes | authTypes.AuthActionTypes
): LeagueState {
  switch (action.type) {
    case types.RECEIVE_LEAGUE_LIST:
      return {
        ...state,
        leagues: action.data,
        joinedLeagues: action.userLeagueDetail
          .reduce((acc: types.league[], crr) => {
            return [...acc, ...crr.leagueId];
          }, [])
          .sort((a, b) =>
            compareAsc(new Date(a.startDate), new Date(b.startDate))
          ),
      };
    case types.RECEIVE_LEAGUE_INFO:
      return {
        ...state,
        leagueInfos: {
          ...state.leagueInfos,
          [action.data.id]: {
            ...state.leagueInfos[action.data.id],
            ...action.data.info,
            _id: action.data.id,
          },
        },
      };
    case types.RECEIVE_CURRENCY_LIST:
      return {
        ...state,
        leagueInfos: {
          ...state.leagueInfos,
          [action.id]: {
            ...state.leagueInfos[action.id],
            currenciesData: action.currencies,
            netWorth: action.netWorth ?? 100,
            balance:
              action.balance ??
              Number(
                state.joinedLeagues.find((x) => x._id === action.id)
                  ?.leagueAmount ?? 0
              ),
            baseCurrency: action.baseCurrency ?? "USD",
            rank: action.rank ?? undefined,
          },
        },
      };
    case types.RECEIVE_TRANSACTIONS_LIST:
      return {
        ...state,
        leagueInfos: {
          ...state.leagueInfos,
          [action.id]: {
            ...state.leagueInfos[action.id],
            transactionsData: action.data,
          },
        },
      };
    case types.RECEIVE_LEAGUE_LEADERBOARD:
      return {
        ...state,
        leagueInfos: {
          ...state.leagueInfos,
          [action.id]: {
            ...state.leagueInfos[action.id],
            leaderboard: action.data.sort(
              (a, b) => a.currentRank - b.currentRank
            ),
          },
        },
      };
    case types.RECEIVE_NETWORTH_HISTORY:
      return {
        ...state,
        networthHistory: {
          ...state.networthHistory,
          [action.strategyId]: action.data,
        },
      };
    case types.RECEIVE_CURRENT_SEASON:
      return {
        ...state,
        currentSeason: action.data,
      };
    case types.RECEIVE_CURRENCY_HISTORY:
      return {
        ...state,
        currencyHistory: {
          ...state.currencyHistory,
          [action.symbol]: action.data,
        },
      };

    case authTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
}
