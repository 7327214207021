import callApi from "../../_utils/callApi";
import * as types from "./types";
import { getToken } from "../auth/selectors";

export const listLeagues = (season: string = ""): AppThunk => async (
  dispatch,
  getState
) => {
  const token = getToken(getState());
  try {
    const [jsonResponse, error] = await callApi({
      endpoint: "v1/league/getleague",
      method: "POST",
      token,
      body: { season },
    });
    if (error) return jsonResponse;
    const { data, userLeagueDetail } = jsonResponse.response;
    dispatch({
      type: types.RECEIVE_LEAGUE_LIST,
      data,
      userLeagueDetail,
    });
    return null;
  } catch (err) {
    return {
      msg: String(err),
    };
  }
};

export const buyLegaues = (values: {
  leagueId: string[];
  OrderUniqueID: string;
  paymentMode: "wallet";
}): AppThunk<Promise<[any, boolean]>> => async (dispatch, getState) => {
  const token = getToken(getState());
  const finalVals = {
    leagueId: values.leagueId,
    OrderUniqueID: values.OrderUniqueID,
    paymentMode: values.paymentMode,
  };
  try {
    const [jsonResponse, error] = await callApi({
      endpoint: "v1/user/buyingLegaues",
      method: "POST",
      token,
      body: finalVals,
    });
    if (error) return [jsonResponse, false];
    return [jsonResponse, true];
  } catch (err) {
    return [{ msg: String(err) }, false];
  }
};

export const fetchLeagueInfo = (
  id: string
): AppThunk<null | ObjectStringKey> => async (dispatch, getState) => {
  const token = getToken(getState());
  try {
    const [jsonResponse, error] = await callApi<{
      response: { data: types.LeagueInfo };
    }>({
      endpoint: `v1/league/info/${id}`,
      token,
    });
    if (error) return jsonResponse;
    const { data } = jsonResponse.response;
    dispatch({
      type: types.RECEIVE_LEAGUE_INFO,
      data: { id, info: data },
    });
    return null;
  } catch (err) {
    return { msg: String(err) };
  }
};

export const receiveCurrencyList = ({
  strategyId,
  currencies,
  netWorth,
  balance,
  baseCurrency,
  rank,
}: {
  strategyId: string;
  currencies: types.Currency[];
  netWorth?: number;
  balance?: number;
  baseCurrency?: string;
  rank?: number;
}): types.LeagueActionTypes => ({
  type: types.RECEIVE_CURRENCY_LIST,
  id: strategyId,
  currencies,
  netWorth,
  balance,
  baseCurrency,
  rank,
});

export const buyTrade = (values: {
  strategyId: string;
  currencyTrade: { currency: string; purchase: number };
}): AppThunk<Promise<[any, boolean]>> => async (dispatch, getState) => {
  const token = getToken(getState());
  try {
    const [jsonResponse, error] = await callApi({
      endpoint: "v1/trade/bT",
      method: "POST",
      token,
      body: values,
    });
    // console.log({ jsonResponse });
    if (error) return [jsonResponse, false];
    return [jsonResponse, true];
  } catch (err) {
    return [{ msg: String(err) }, false];
  }
};

export const sellTrade = (values: {
  strategyId: string;
  currencyTrade: { currency: string; sell: number };
}): AppThunk<Promise<[any, boolean]>> => async (dispatch, getState) => {
  const token = getToken(getState());
  try {
    const [jsonResponse, error] = await callApi({
      endpoint: "v1/trade/sT",
      method: "POST",
      token,
      body: values,
    });
    // console.log({ jsonResponse });
    if (error) return [jsonResponse, false];
    return [jsonResponse, true];
  } catch (err) {
    return [{ msg: String(err) }, false];
  }
};

export const convertBaseCurrency = (values: {
  strategyId: string;
  baseCurrency: string;
  convertCurrency: string;
}): AppThunk<Promise<[any, boolean]>> => async (dispatch, getState) => {
  const token = getToken(getState());
  try {
    const [jsonResponse, error] = await callApi({
      endpoint: "v1/trade/convertBaseCurreny",
      method: "POST",
      token,
      body: values,
    });
    // console.log({ jsonResponse });
    if (error) return [jsonResponse, false];
    return [jsonResponse, true];
  } catch (err) {
    return [{ msg: String(err) }, false];
  }
};

export const fetchLeagueTransactions = (
  strategyId: string
): AppThunk<null | ObjectStringKey> => async (dispatch, getState) => {
  const token = getToken(getState());
  try {
    const [jsonResponse, error] = await callApi<{
      response: { data: types.Transaction[] };
    }>({
      endpoint: "v1/trade/transaction",
      method: "POST",
      token,
      body: { strategyId },
    });
    if (error) return jsonResponse;
    const { data } = jsonResponse.response;
    // console.log(data);
    dispatch<types.LeagueActionTypes>({
      type: types.RECEIVE_TRANSACTIONS_LIST,
      id: strategyId,
      data,
    });
    return null;
  } catch (err) {
    return { msg: String(err) };
  }
};

export const fetchLeagueLeaderboard = (
  strategyId: string
): AppThunk<null | ObjectStringKey> => async (dispatch, getState) => {
  const token = getToken(getState());
  try {
    const [jsonResponse, error] = await callApi<{
      response: { data: types.LeaderBoardItem[] };
    }>({
      endpoint: "v1/league/getLeaderBoardLive",
      method: "POST",
      token,
      body: { strategyId },
    });
    if (error) return jsonResponse;
    const { data } = jsonResponse.response;
    // console.log(data);
    dispatch<types.LeagueActionTypes>({
      type: types.RECEIVE_LEAGUE_LEADERBOARD,
      id: strategyId,
      data,
    });
    return null;
  } catch (err) {
    return { msg: String(err) };
  }
};

export const fetchNetworthHistory = (
  strategyId: string
): AppThunk<null | ObjectStringKey> => async (dispatch, getState) => {
  const token = getToken(getState());
  try {
    const [jsonResponse, error] = await callApi<{
      response: { data: { netWorthRecord: types.NetWorthHistoryItem[] }[] };
    }>({
      endpoint: "v1/trade/nwRecord",
      method: "POST",
      token,
      body: { strategyId },
    });
    if (error) return jsonResponse;
    const { netWorthRecord } = jsonResponse.response.data?.[0];
    // console.log(data);
    dispatch<types.LeagueActionTypes>({
      type: types.RECEIVE_NETWORTH_HISTORY,
      strategyId,
      data: netWorthRecord,
    });
    return null;
  } catch (err) {
    return { msg: String(err) };
  }
};

export const fetchCurrrentSeason = (): AppThunk<null | ObjectStringKey> => async (
  dispatch,
  getState
) => {
  const token = getToken(getState());

  interface APIResponse {
    error: { data: types.CurrentSeason[] };
  }

  try {
    const [jsonResponse, error] = await callApi<APIResponse>({
      endpoint: "v1/league/season",
      token,
    });
    // console.log(error, jsonResponse);
    if (error) return jsonResponse;
    const { data } = jsonResponse.error;
    dispatch<types.LeagueActionTypes>({
      type: types.RECEIVE_CURRENT_SEASON,
      data: data[0],
    });
    return null;
  } catch (err) {
    return { msg: String(err) };
  }
};

export const fetchCurrencyHistory = (symbol: string): AppThunk => async (
  dispatch
) => {
  try {
    const res = await fetch(
      `https://min-api.cryptocompare.com/data/v2/histohour?fsym=${symbol}&tsym=USD&limit=48`
    );
    const resJson = await res.json();
    if (res.status >= 400 && res.status < 600) return "Network Error";
    dispatch<types.LeagueActionTypes>({
      type: types.RECEIVE_CURRENCY_HISTORY,
      symbol,
      data: resJson.Data.Data,
    });
  } catch (err) {
    console.log(err);
    return err;
  }
};
