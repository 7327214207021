import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { animated, useSpring } from "react-spring";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Label,
  Tooltip,
} from "recharts";

interface PurchasesGraphProps {
  data: { name: string; value: number; color: string }[];
}

function PurchasesGraph(props: PurchasesGraphProps) {
  const { data } = props;
  const { t } = useTranslation();

  const [animatedLabelProps, set] = useSpring(() => ({
    fill: "#fff",
    value: 0,
    from: { fill: "#130D4A" },
  }));

  useEffect(() => {
    const totalValue = data.reduce((acc, crr) => acc + crr.value, 0);
    set({ value: totalValue });
  }, [data, set]);

  const renderLabelContent = useCallback(
    (props: any) => {
      const {
        viewBox: { cx, cy },
      } = props;
      const positioningProps = {
        x: cx,
        y: cy,
        textAnchor: "middle",
        verticalanchor: "middle",
      };

      return (
        <g>
          <animated.text
            {...positioningProps}
            fill={animatedLabelProps.fill}
            fontSize={36}
            fontFamily="Montserrat"
          >
            {animatedLabelProps.value.interpolate((x) => `$${~~x}`)}
          </animated.text>
          {/* <animated.text
            {...positioningProps}
            y={cy + 44}
            fontSize={20}
            fill={animatedLabelProps.fill}
            fontFamily="Montserrat"
          >
            July
          </animated.text> */}
        </g>
      );
    },
    [animatedLabelProps.fill, animatedLabelProps.value]
  );

  return (
    <ResponsiveContainer width="100%" aspect={1}>
      <PieChart>
        <Pie
          data={data}
          innerRadius={100}
          outerRadius={110}
          dataKey="value"
          startAngle={90}
          endAngle={360 + 90}
        >
          <Label position="center" content={renderLabelContent} />
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={entry.color}
              stroke={entry.color}
            />
          ))}
        </Pie>
        <Tooltip
          formatter={(val, name) => [`$${val}`, t(name)]}
          itemStyle={{ fontFamily: "Montserrat", fontSize: "1rem" }}
        />
      </PieChart>
    </ResponsiveContainer>
  );
}

export default React.memo(PurchasesGraph);
