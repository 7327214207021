import { Reducer } from "redux";
import { produce } from "immer";
import jwtDecode from "jwt-decode";
import { compareDesc } from "date-fns";

import * as types from "./types";
import { loadState } from "../localStorage";
import { setAuthTokenCookie } from "../../_utils/cookie";

interface IDecodedToken {
  _id: string;
  email: string;
  role: "user" | "admin";
  iat: number;
}

const getLocalJwtToken = () => {
  const token = loadState("token");
  if (!token) return null;
  setAuthTokenCookie(token);
  const decoded = jwtDecode<IDecodedToken>(token);
  // console.log(decoded);
  // const expired = decoded.exp < Date.now() / 1000;
  // if (expired) return null;
  return { token, ...decoded };
};

interface AuthState {
  token: string | undefined;
  walletBTC: number | null;
  _id: string | null;
  BTCRate: number | null;
  orderId: string | null;
  name: string | null;
  email: string | null;
  refcode: string | null;
  profileImg: string | null;
  joiningDate: string | null;
  walletTransactions: types.IwalletTransaction[];
  referrals: {
    [season: string]: {
      level1: types.Ireferral[];
      level2: types.Ireferral[];
      level3: types.Ireferral[];
    };
  };
  refCodeRequired: boolean;
  baData: { [season: string]: types.BaData };
  seasonTemplate: { [season: string]: types.BaTemplateItem[] };
}

const initialState: AuthState = {
  token: undefined,
  _id: null,
  walletBTC: null,
  BTCRate: null,
  orderId: null,
  name: null,
  email: null,
  refcode: null,
  profileImg: null,
  joiningDate: null,
  walletTransactions: [],
  referrals: {},
  refCodeRequired: false,
  baData: {},
  seasonTemplate: {},
  ...getLocalJwtToken(),
};

const auth: Reducer<AuthState, types.AuthActionTypes> = (
  state = initialState,
  action
) =>
  produce(state, (draft) => {
    switch (action.type) {
      // SignIn
      case types.RECEIVE_AUTH_TOKEN:
        draft.token = action.token;
        break;
      case types.LOGOUT:
        return { ...initialState, token: undefined };
      case types.RECEIVE_WALLET_DATA:
        draft.walletBTC = action.data.UserWalletBTC;
        draft.BTCRate = action.data["1BTCRate"];
        draft.orderId = action.data.OrderId;
        break;
      case types.RECEIVE_PROFILE_DATA:
        draft.walletBTC = action.data.wallet;
        draft.name = action.data.name;
        draft.email = action.data.email;
        draft.refcode = action.data.refcode;
        draft.profileImg =
          action.data.profileImg !== "NA" ? action.data.profileImg : null;
        draft.joiningDate = action.data.joineDate;
        break;
      case types.RECEIVE_PROFILE_IMAGE:
        draft.profileImg = action.data.profileImg;
        break;
      case types.RECEIVE_WALLET_TRANSACTIONS:
        draft.walletTransactions = action.data.sort((a, b) =>
          compareDesc(new Date(a.createdAt), new Date(b.createdAt))
        );
        break;
      case types.RECEIVE_REFERRALS:
        draft.referrals[action.season] = action.data;
        break;
      case types.RECEIVE_REF_STATUS:
        draft.refCodeRequired = action.refCodeRequired;
        break;
      case types.RECEIVE_BA_DATA:
        draft.baData[action.season] = action.data;
        break;
      case types.RECEIVE_SEASON_TEMPLATE:
        draft.seasonTemplate[action.season] = action.data;
        break;
    }
  });

export default auth;
