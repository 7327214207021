import React, { useCallback, useMemo, useState } from "react";
import { formatRelative, Locale } from "date-fns";
import enUS from "date-fns/locale/en-US";
import ja from "date-fns/locale/ja";
import { useTranslation } from "react-i18next";

import { makeStyles, Box, Grid, Typography } from "@material-ui/core";

// Icons
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

import { useTypedSelector } from "../../../_redux/rootReducer";
import {
  selectAllLiveJoinedStrategies,
  selectNextJoinedLeagues,
  selectAllUpcomingStrategies,
  selectAllJoinedUpcomingStrategies,
  selectJoinedStrategies,
} from "../../../_redux/league/selectors";
import { selectUserName } from "../../../_redux/auth/selectors";

import { AnimatedPaper } from "../../../Components";
import PurchasesGraph from "./PurchasesGraph";
import BuyTicketDialog from "../BuyTicketDialog";

import AnimatedSvg4 from "../../../_assets/AnimatedSvgs/SVG4";
import Checkboard1 from "../../../_assets/Images/Checkboard1.svg";
import Checkboard2 from "../../../_assets/Images/Checkboard2.svg";
import Checkboard3 from "../../../_assets/Images/Checkboard3.svg";

import LeagueStatsItem from "./LeagueStatsItem";

const formatRelativeLocale = {
  lastWeek: "'Last' eeee",
  yesterday: "'Yesterday'",
  today: "'Today'",
  tomorrow: "'Tomorrow'",
  nextWeek: "'Next' eeee",
  other: "do MM yy",
};
const useStyles = makeStyles((theme) => ({
  gridOne: {
    display: "flex",
    flexDirection: "column",
    color: "#FFFFFF",
    order: 1,
  },
  welcomeText: {
    color: "#FC6E4F",
    fontWeight: "bold",
  },
  leagueStatsContainer: {
    display: "flex",
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  puchaseCard: {
    // height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  puchaseCardTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(2),
    borderBottom: "#3BE4CE 1px solid",
  },
  puchaseCardTitle: {
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 0.5,
    color: "#3BE4CE",
  },
  puchaseCardBody: {
    padding: theme.spacing(4),
    // height: "100%",
    display: "flex",
    // alignItems: "center",
    justifyContent: "center",
  },
  puchaseCardFooter: {
    display: "flex",
    justifyContent: "space-between",
  },
  puchaseCardFooterText: {
    color: "#FC6E4F",
    padding: theme.spacing(0.5, 2),
  },
  link: { cursor: "pointer" },
}));

export default function GridOne() {
  const classes = useStyles();
  const { t, i18n } = useTranslation("dashboard");

  const joinedLiveLeagues = useTypedSelector(selectAllLiveJoinedStrategies);
  const [nextDate, nextLeagues] = useTypedSelector(selectNextJoinedLeagues);
  const upcomingLeagues = useTypedSelector(selectAllUpcomingStrategies);
  const upcomingJoinedLeagus = useTypedSelector(
    selectAllJoinedUpcomingStrategies
  );
  const joinedLeagues = useTypedSelector(selectJoinedStrategies);
  const userName = useTypedSelector(selectUserName);

  const [buyTicketOpen, setBuyTicketOpen] = useState(false);
  const handleOpen = useCallback(() => setBuyTicketOpen(true), []);
  const handleClose = useCallback(() => setBuyTicketOpen(false), []);

  const nextDateDisplay = useMemo(() => {
    const lang = i18n.language;
    let locale: Locale = {
      ...enUS,
      formatRelative: (token: keyof typeof formatRelativeLocale) =>
        formatRelativeLocale[token],
    };
    if (lang === "ja") locale = ja;
    if (!nextDate) return "Soon";
    return formatRelative(nextDate, new Date(), { locale });
  }, [i18n.language, nextDate]);

  const [bronzePurchases, silverPurchases, goldPurchases] = useMemo(() => {
    const bronzePurchases = joinedLeagues.filter(
      (x) => x.leagueCategory === "Bronze"
    );
    const silverPurchases = joinedLeagues.filter(
      (x) => x.leagueCategory === "Silver"
    );
    const goldPurchases = joinedLeagues.filter(
      (x) => x.leagueCategory === "Gold"
    );
    return [bronzePurchases, silverPurchases, goldPurchases];
  }, [joinedLeagues]);
  const hasPurchases = useMemo(
    () =>
      !(
        bronzePurchases.length === 0 &&
        silverPurchases.length === 0 &&
        goldPurchases.length === 0
      ),
    [bronzePurchases.length, goldPurchases.length, silverPurchases.length]
  );
  const graphData = useMemo(() => {
    return [
      {
        name: "translation:Bronze",
        color: "#4043FF",
        count: bronzePurchases.length,
        value: bronzePurchases.length * Number(bronzePurchases[0]?.price ?? 0),
      },
      {
        name: "translation:Silver",
        color: "#3BE4CE",
        count: silverPurchases.length,
        value: silverPurchases.length * Number(silverPurchases[0]?.price ?? 0),
      },
      {
        name: "translation:Gold",
        color: "#fc6e4f",
        count: goldPurchases.length,
        value: goldPurchases.length * Number(goldPurchases[0]?.price ?? 0),
      },
    ];
  }, [bronzePurchases, goldPurchases, silverPurchases]);

  return (
    <Grid item xs={12} sm={6} lg={3} className={classes.gridOne}>
      <Typography className={classes.welcomeText} variant="h5" gutterBottom>
        {t("welcomeUser", { userName })}!
      </Typography>
      <AnimatedPaper className={classes.leagueStatsContainer}>
        <LeagueStatsItem
          src={Checkboard1}
          label={t("startingDate", { date: nextDateDisplay })}
          value={nextLeagues.length}
        />
        <LeagueStatsItem
          src={Checkboard2}
          label={t("liveLeagues")}
          value={joinedLiveLeagues.length}
        />
        <LeagueStatsItem
          src={Checkboard3}
          label={t("upcomingLeagues")}
          value={upcomingLeagues.length - upcomingJoinedLeagus.length}
          onClick={handleOpen}
        />
      </AnimatedPaper>
      <BuyTicketDialog open={buyTicketOpen} handleClose={handleClose} />
      {/* Purchases Card */}
      <AnimatedPaper className={classes.puchaseCard}>
        <div className={classes.puchaseCardTitleContainer}>
          <Typography variant="h6" className={classes.puchaseCardTitle}>
            {t("yourPurchase")}
            <br />
            <Typography variant="body2" color="textSecondary">
              {t("translation:Current Season")}
            </Typography>
          </Typography>
          <MoreHorizIcon htmlColor="white" />
        </div>
        <div className={classes.puchaseCardBody}>
          {hasPurchases ? (
            <PurchasesGraph data={graphData} />
          ) : (
            <div>
              <AnimatedSvg4 />
              <Typography align="center" variant="body2">
                {t("numTickets", { num: 0 })}
              </Typography>
            </div>
          )}
        </div>
        {hasPurchases && (
          <div className={classes.puchaseCardFooter}>
            {graphData.map((cat) => (
              <Box
                key={cat.name}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Box height={14} width={14} bgcolor={cat.color} />
                <Typography className={classes.puchaseCardFooterText}>
                  {cat.count} {t(cat.name)}
                </Typography>
              </Box>
            ))}
          </div>
        )}
      </AnimatedPaper>
    </Grid>
  );
}
