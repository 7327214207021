import React, { useCallback, useEffect, useMemo, useState } from "react";
import { format, isFuture, isPast, subDays, Locale } from "date-fns";
import { enUS, ja } from "date-fns/locale";
import { useTranslation } from "react-i18next";

import {
  createStyles,
  makeStyles,
  Theme,
  Typography,
  Box,
  MenuItem,
  Menu,
} from "@material-ui/core";
import { KeyboardArrowDown, Today } from "@material-ui/icons";

import { useTypedSelector } from "../../_redux/rootReducer";
import { makeLeaguesForCategorySelector } from "../../_redux/league/selectors";

const locales: { [key: string]: Locale } = { enUS, ja };

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerAction: { cursor: "pointer" },
    fontBold: {
      fontWeight: "bold",
    },
  })
);

interface DateSelectProps {
  endDate: Date | null;
  setEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
}

const DateSelect: React.FC<DateSelectProps> = (props) => {
  const { endDate, setEndDate } = props;
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const selectLeagues = useMemo(makeLeaguesForCategorySelector, []);
  const leagues = useTypedSelector((state) => selectLeagues(state, "Bronze"));
  const dates = useMemo(() => {
    const allDates = leagues?.leagueLookup.map((x) => x.endDate);
    const uniqueDates = [...new Set(allDates)].map((x) => new Date(x));
    return uniqueDates;
  }, [leagues]);

  useEffect(() => {
    // console.log({ endDate });
    if (endDate === null) {
      setEndDate(dates.filter((x) => isPast(x)).slice(-1)[0] ?? null);
    }
  }, [dates, endDate, setEndDate]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = useCallback(() => setAnchorEl(null), []);

  const handleClick = useCallback(
    (event, date: Date) => {
      setEndDate(date);
      setAnchorEl(null);
    },
    [setEndDate]
  );

  return (
    <>
      <div className={classes.headerAction} onClick={handleOpen}>
        <Typography variant="body2" color="textSecondary" align="right">
          {t("Date")}
        </Typography>
        <Box display="flex" alignItems="center">
          <Today fontSize="small" />
          <Typography className={classes.fontBold}>
            {endDate
              ? format(endDate, "d MMM yyyy", {
                  locale: locales[i18n.language] ?? enUS,
                })
              : t("leaderboard:Select Date")}
          </Typography>
          <KeyboardArrowDown fontSize="small" color="secondary" />
        </Box>
      </div>
      <Menu
        id="category-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {dates.map((date) => (
          <MenuItem
            key={date.getTime()}
            selected={date === endDate}
            disabled={isFuture(date)}
            onClick={(e) => handleClick(e, date)}
          >
            {format(subDays(date, 2), "d MMM", {
              locale: locales[i18n.language] ?? enUS,
            })}{" "}
            -{" "}
            {format(date, "d MMM yyyy", {
              locale: locales[i18n.language] ?? enUS,
            })}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default React.memo(DateSelect);
