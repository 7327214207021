import React from "react";

import { Grid } from "@material-ui/core";


import GridOne from "./GridOne";
import GridTwo from "./GridTwo";
import GridThree from "./GridThree";

export default function Dashboard() {
  return (
    <Grid container spacing={5}>
      <GridOne />
      <GridTwo />
      <GridThree />
    </Grid>
  );
}
