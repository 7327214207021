import React from "react";
import { useTranslation } from "react-i18next";

import {
  createStyles,
  makeStyles,
  Theme,
  Box,
  Typography,
} from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons";

import MaskImg from "./assets/MaskGroup.svg";

import Button from "./components/Button";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      background: `url("${MaskImg}") top`,
      margin: theme.spacing(-3),
      padding: theme.spacing(3),
    },
    succesIconContainer: {
      color: "#3BE4CE",
      fontSize: 124,
      display: "flex",
      justifyContent: "center",
      marginTop: 165,
      marginBottom: 24,
      position: "relative",
    },
    succesIconBg: {
      position: "absolute",
      backgroundColor: "#fff",
      height: 90,
      width: 90,
      borderRadius: "50%",
      top: 12.5,
    },
    succesIcon: {
      zIndex: 1,
    },
  })
);

interface SuccessProps {
  ticketIds: string[];
  handleReset: (e: React.MouseEvent) => void;
}

const Success: React.FC<SuccessProps> = (props) => {
  const { handleReset, ticketIds } = props;
  const classes = useStyles();
  const { t } = useTranslation("league");

  return (
    <div className={classes.root}>
      <div className={classes.succesIconContainer}>
        <div className={classes.succesIconBg} />
        <CheckCircle
          color="inherit"
          fontSize="inherit"
          className={classes.succesIcon}
        />
      </div>
      <Typography variant="h6" align="center">
        {t('TICKET BOOKED SUCCESSFULLY')}
      </Typography>
      <Box color="#FC6E4F" mt={2}>
        <Typography align="center" variant="body2">
          {t('TicketNum')} {ticketIds.join(", ")}
        </Typography>
      </Box>
      <Box px={4} my={8}>
        <Typography variant="body2" align="center">
          {t('ticketBookSuccess')}
        </Typography>
      </Box>
      <Box my={2} width="100%">
        <Button
          color="secondary"
          variant="contained"
          size="large"
          onClick={handleReset}
        >
          {t('Back to leagues')}
        </Button>
      </Box>
    </div>
  );
};

export default React.memo(Success);
