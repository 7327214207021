import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

// MUI
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
// Icons
import { CheckCircle, LockOutlined, VpnKeyOutlined } from "@material-ui/icons";

import { confirmPasswordReset, requestPasswordReset } from "../../_redux/auth";

import { TextField as CustomTextField } from "../../Components";

import useStylesBase from "./styles";

export default function ConfirmPasswordReset() {
  const classesBase = useStylesBase();
  const location = useLocation<{ email?: string }>();
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation("auth");

  const [success, setSuccess] = useState(false);
  const [resendTime, setResendTime] = useState(30);
  const interval = useRef<number>();
  const [errors, setErrors] = useState<ObjectStringKey>({});

  useEffect(() => {
    interval.current = window.setInterval(
      () => setResendTime((x) => x - 1),
      1000
    );
    return () => clearInterval(interval.current);
  }, []);

  const resend = useCallback(() => {
    if (resendTime > 0) return;
    const email = location.state?.email;
    if (!email) return;
    dispatch(requestPasswordReset({ email }));
    setResendTime(30);
  }, [dispatch, location.state, resendTime]);

  const form = useFormik({
    initialValues: {
      code: "",
      password: "",
      passwordConfirm: "",
    },
    validationSchema: Yup.object().shape({
      code: Yup.string().required(
        t("translation:requiredMessage", { field: t("verificationCode") })
      ),
      password: Yup.string().required(
        t("translation:requiredMessage", { field: t("translation:password") })
      ),
      passwordConfirm: Yup.string()
        .oneOf([Yup.ref("password")], t("passwordsMismatch"))
        .required(
          t("translation:requiredMessage", { field: t("confirmPassword") })
        ),
    }),
    onSubmit: async (values) => {
      // console.log({ values });
      const error = await dispatch(confirmPasswordReset(values));
      console.log(error);
      if (error) return setErrors(error);
      setSuccess(true);
    },
  });

  const {
    errors: formErrors,
    touched,
    getFieldProps,
    handleSubmit,
    isSubmitting,
  } = form;
  const getError = (field: keyof typeof form.initialValues) =>
    !!(formErrors[field] && touched[field]);
  const getHelperText = (field: keyof typeof form.initialValues) =>
    touched[field] && formErrors[field];

  if (success) {
    return (
      <div>
        <Typography className={classesBase.title} component="h1" align="center">
          {t("resetPassword")}
        </Typography>
        <Box
          fontSize={40}
          display="flex"
          justifyContent="center"
          color="#FC6E4F"
        >
          <CheckCircle fontSize="inherit" color="inherit" />
        </Box>
        <Box color="#FC6E4F">
          <Typography align="center" color="inherit">
            {t("passwordResetSuccess")}!
          </Typography>
        </Box>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          className={classesBase.button}
          component={Link}
          to="/auth/signin"
        >
          {t("loginNow")}
        </Button>
      </div>
    );
  }
  return (
    <form className={classesBase.root} onSubmit={handleSubmit} noValidate>
      <Typography className={classesBase.title} component="h1" align="center">
        {t("resetPassword")}
      </Typography>
      {/* Code */}
      <CustomTextField
        fullWidth
        variant="outlined"
        margin="dense"
        placeholder={t("verificationCode")}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <VpnKeyOutlined />
            </InputAdornment>
          ),
        }}
        error={getError("code")}
        helperText={getHelperText("code")}
        {...getFieldProps("code")}
      />
      {/* Password */}
      <CustomTextField
        fullWidth
        type="password"
        variant="outlined"
        margin="dense"
        placeholder={t("newPassword")}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockOutlined />
            </InputAdornment>
          ),
        }}
        error={getError("password")}
        helperText={getHelperText("password")}
        {...getFieldProps("password")}
      />
      {/* Confirm Password */}
      <CustomTextField
        fullWidth
        type="password"
        variant="outlined"
        margin="dense"
        placeholder={t("confirmPassword")}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockOutlined />
            </InputAdornment>
          ),
        }}
        error={getError("passwordConfirm")}
        helperText={getHelperText("passwordConfirm")}
        {...getFieldProps("passwordConfirm")}
      />
      {/* Resend */}
      {location.state?.email && (
        <>
          <Typography align="center">
            {t("codeSent")} {location.state.email}
          </Typography>
          <Typography align="center">
            {t("notReceiveCode")}?
            <Typography
              component="span"
              className={classesBase.link}
              style={{ cursor: resendTime > 0 ? "not-allowed" : undefined }}
              onClick={resend}
            >
              {t("resend")}
            </Typography>
            {resendTime > 0 && ` ${t("resendInSec", { resendTime })}`}
          </Typography>
        </>
      )}
      {/* Non Field Error */}
      <Box my={4} width="100%">
        <Typography align="center" color="error" variant="body2">
          {errors.msg}
        </Typography>
        {/* Submit */}
        <Button
          variant="contained"
          color="primary"
          disableElevation
          className={classesBase.button}
          type="submit"
          disabled={isSubmitting}
        >
          {t("setPassword")}
        </Button>
      </Box>
    </form>
  );
}
