export const RECEIVE_AUTH_TOKEN = "RECEIVE_AUTH_TOKEN";
export const LOGOUT = "LOGOUT";
export const RECEIVE_WALLET_DATA = "RECEIVE_WALLET_DATA";
export const RECEIVE_PROFILE_DATA = "RECEIVE_PROFILE_DATA";
export const RECEIVE_PROFILE_IMAGE = "RECEIVE_PROFILE_IMAGE";
export const RECEIVE_WALLET_TRANSACTIONS = "RECEIVE_WALLET_TRANSACTIONS";
export const RECEIVE_REFERRALS = "RECEIVE_REFERRALS";
export const RECEIVE_REF_STATUS = "RECEIVE_REF_STATUS";
export const RECEIVE_BA_DATA = "RECEIVE_BA_DATA";
export const RECEIVE_SEASON_TEMPLATE = "RECEIVE_SEASON_TEMPLATE";

export interface IwalletTransaction {
  _id: string;
  legaueUserId: string;
  userId: string;
  OrderUniqueID: string;
  ticketId: {
    strategies: "1" | "2" | "3";
    ticket: string;
  }[];
  leagueCategory: "Bronze" | "Silver" | "Gold";
  boughtAmount: number;
  deductAmount: number;
  updatedAt: string;
  createdAt: string;
  __v: number;
}

export interface Ireferral {
  rferralId: string;
  referralType: "Level1" | "Level2" | "Level3";
  amount: 25;
  userDetail: {
    name: string;
    profileImage: string;
  }[];
}

export interface BaData {
  _id: string;
  userId: string;
  HLevel: IHLevel[];
}

export interface IHLevel {
  _id: string;
  level: "H5" | "H10" | "H20" | "H50" | "H100";
  saleAmount: number;
  Season: string;
  Percentage: number;
  EarnedAmount: number;
}

export interface BaTemplateItem {
  _id: string;
  Hlevel: string;
  percentage: number;
  season: string;
  __v: number;
}

// Actions
interface ReceiveAuthTokenAction {
  type: typeof RECEIVE_AUTH_TOKEN;
  token: string;
}

interface LogoutAction {
  type: typeof LOGOUT;
}

interface ReceiveWalletDataAction {
  type: typeof RECEIVE_WALLET_DATA;
  data: {
    UserWalletBTC: number;
    "1BTCRate": number;
    OrderId: string;
  };
}

interface ReceiveProfileDataAction {
  type: typeof RECEIVE_PROFILE_DATA;
  data: {
    name: string;
    email: string;
    refcode: string;
    wallet: number;
    profileImg: string;
    joineDate: string;
  };
}

interface ReceiveProfileImageAction {
  type: typeof RECEIVE_PROFILE_IMAGE;
  data: {
    profileImg: string;
  };
}

interface ReceiveWalletTransactionsAction {
  type: typeof RECEIVE_WALLET_TRANSACTIONS;
  data: IwalletTransaction[];
}

interface ReceiveReferralsAction {
  type: typeof RECEIVE_REFERRALS;
  data: {
    level1: Ireferral[];
    level2: Ireferral[];
    level3: Ireferral[];
  };
  season: string;
}

interface ReceiveRefStatusAction {
  type: typeof RECEIVE_REF_STATUS;
  refCodeRequired: boolean;
}

interface ReceiveBaDataAction {
  type: typeof RECEIVE_BA_DATA;
  data: BaData;
  season: string;
}

interface ReceiveSeasonTemplateAction {
  type: typeof RECEIVE_SEASON_TEMPLATE;
  season: string;
  data: BaTemplateItem[];
}

export type AuthActionTypes =
  | ReceiveAuthTokenAction
  | LogoutAction
  | ReceiveWalletDataAction
  | ReceiveProfileDataAction
  | ReceiveProfileImageAction
  | ReceiveWalletTransactionsAction
  | ReceiveReferralsAction
  | ReceiveRefStatusAction
  | ReceiveBaDataAction
  | ReceiveSeasonTemplateAction;
