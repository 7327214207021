import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  makeStyles,
  createStyles,
  Theme,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Box,
  Typography,
} from "@material-ui/core";

import { Ireferral } from "../../../_redux/auth/types";

import { AnimatedPaper } from "../../../Components";
import TableHead from "./Head";
import Row from "./Row";

import Mascot4 from "../../../_assets/Images/Mascot4.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    noTransactionImgContainer: {
      display: "flex",
      height: "100%",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    root: {
      padding: theme.spacing(5, 0),
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(5),
      },
    },
    tableContainer: {
      backgroundColor: "transparent",
    },
    paginationSelectIcon: {
      color: theme.palette.text.primary,
    },
  })
);

interface ReferralTableProps {
  rate: number;
  referrals: Ireferral[];
}

const ReferralTable: React.FC<ReferralTableProps> = (props) => {
  const classes = useStyles();
  const { rate, referrals } = props;
  const { t } = useTranslation("referrals");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);
  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    },
    []
  );

  const rows = useMemo(
    () => referrals.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page, rowsPerPage, referrals]
  );

  if (referrals.length === 0) {
    return (
      <>
        <Box my={{ xs: 2, sm: 3, md: 4 }}>
          <Typography variant="h6" align="center">
            {t('Keep track of all of your referral income here')}
          </Typography>
        </Box>
        <div className={classes.noTransactionImgContainer}>
          <img src={Mascot4} alt="mascot" />
        </div>
      </>
    );
  }
  return (
    <div className={classes.root}>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead rate={rate} />
          <Box height={20} />
          <TableBody component={AnimatedPaper} scale={1.02}>
            {rows.map((row, i) => (
              <Row key={row.rferralId} row={row} index={i} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 20, 50]}
        component="div"
        count={referrals.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        SelectProps={{
          classes: { icon: classes.paginationSelectIcon },
        }}
      />
    </div>
  );
};

export default React.memo(ReferralTable);
