import React from "react";

import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function LockIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16.004"
        height="19.996"
        viewBox="0 0 16.004 19.996"
      > */}
        <path
          fillRule="evenodd"
          d="M16.173,20H1.819a.809.809,0,0,1-.825-.793V8.224a.809.809,0,0,1,.825-.793H4.741V4.1A4.18,4.18,0,0,1,9,.007,4.179,4.179,0,0,1,13.25,4.1V7.431h2.923A.81.81,0,0,1,17,8.224V19.21a.81.81,0,0,1-.825.793ZM11.6,4.1A2.558,2.558,0,0,0,9,1.593,2.558,2.558,0,0,0,6.391,4.1V7.431H11.6V4.1Zm3.748,4.921H2.644v9.4h12.7v-9.4Zm-6.471,2.43a.809.809,0,0,1,.825.793v3.032a.826.826,0,0,1-1.65,0V12.24a.809.809,0,0,1,.825-.793Z"
          transform="translate(-0.994 -0.007)"
        />
      {/* </svg> */}
    </SvgIcon>
  );
}
