import React from "react";

import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function ThumbsDown(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 21 21">
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="21"
        viewBox="0 0 21 21"
      > */}
      <g transform="translate(0.173)">
        <circle
          cx="10.5"
          cy="10.5"
          r="10.5"
          transform="translate(-0.173)"
          fill="#fc6e4f"
        />
        <path
          fill="snow"
          d="M10.642,4.5H4.765a1.3,1.3,0,0,0-1.2.8L1.591,9.9a1.29,1.29,0,0,0-.091.477v1.247l.007.007-.007.052a1.31,1.31,0,0,0,1.306,1.306H6.927l-.62,2.984-.02.209a.983.983,0,0,0,.287.692l.692.686,4.3-4.3a1.3,1.3,0,0,0,.379-.921V5.806A1.31,1.31,0,0,0,10.642,4.5Zm2.612,0v7.836h2.612V4.5Z"
          transform="translate(1.518 -0.727)"
        />
      </g>
      {/* </svg> */}
    </SvgIcon>
  );
}
