import React from "react";
import { useTranslation } from "react-i18next";

import {
  makeStyles,
  createStyles,
  Theme,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  IconButton,
  Box,
} from "@material-ui/core";
import { HighlightOff } from "@material-ui/icons";

import { AnimatedPaper } from "../../../Components";

import RefShareImg from "./RefShare.svg";
import TwitterSvg from "./Icon-twitter.svg";
import FacebookSvg from "./Icon-facebook.svg";
import InstagramSvg from "./Icon-instagram.svg";
import TelegramSvg from "./Icon-telegram-plane.svg";
import LinkSvg from "./Icon-link.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    shareItem: {
      textDecoration: "none",
      "&:hover": { textDecoration: "underline" },
    },
    fontBold: {
      fontWeight: theme.typography.fontWeightBold,
    },
    socialIconContainer: {
      backgroundColor: "#060220",
      height: 74,
      width: 74,
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: theme.spacing(1),
    },
  })
);

interface RefShareDialogProps {
  open: boolean;
  onClose: () => void;
}

const RefShareDialog: React.FC<RefShareDialogProps> = (props) => {
  const { open, onClose } = props;
  const classes = useStyles();
  const { t } = useTranslation("referrals");

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Box display="flex" justifyContent="flex-end">
        <IconButton onClick={onClose} color="secondary">
          <HighlightOff fontSize="large" />
        </IconButton>
      </Box>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <img src={RefShareImg} alt="" width="100%" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              variant="h4"
              align="center"
              className={classes.fontBold}
            >
              {t('Refer & Earn')}
            </Typography>
            <Typography color="textSecondary">
              Aenean non accumsan ante. Duis et risus accumsan sem tempus porta
              nec sit amet est. Sed ut euismod quam. Suspendisse potenti.
              Aliquam fringilla orci
            </Typography>
          </Grid>
        </Grid>
        <Box my={3} display="flex" flexWrap="wrap" justifyContent="center">
          {/* Twitter */}
          <div>
            <AnimatedPaper className={classes.socialIconContainer}>
              <img src={TwitterSvg} alt="" />
            </AnimatedPaper>
            <Typography
              color="secondary"
              className={classes.fontBold}
              variant="body2"
              align="center"
            >
              {t('Twitter')}
            </Typography>
          </div>
          {/* FB */}
          <a
            href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fapp.playctl.net%2F&amp;src=sdkpreparse"
            target="_blank"
            rel="noreferrer noopener"
            className={classes.shareItem}
          >
            <AnimatedPaper className={classes.socialIconContainer}>
              <img src={FacebookSvg} alt="" />
            </AnimatedPaper>
            <Typography
              color="secondary"
              className={classes.fontBold}
              variant="body2"
              align="center"
            >
              {t('Facebook')}
            </Typography>
          </a>
          {/* Insta */}
          <div>
            <AnimatedPaper className={classes.socialIconContainer}>
              <img src={InstagramSvg} alt="" />
            </AnimatedPaper>
            <Typography
              color="secondary"
              className={classes.fontBold}
              variant="body2"
              align="center"
            >
              {t('Instagram')}
            </Typography>
          </div>
          {/* Telegram */}
          <div>
            <AnimatedPaper className={classes.socialIconContainer}>
              <img src={TelegramSvg} alt="" />
            </AnimatedPaper>
            <Typography
              color="secondary"
              className={classes.fontBold}
              variant="body2"
              align="center"
            >
              {t('Telegram')}
            </Typography>
          </div>
          {/* Link */}
          <div>
            <AnimatedPaper className={classes.socialIconContainer}>
              <img src={LinkSvg} alt="" />
            </AnimatedPaper>
            <Typography
              color="secondary"
              className={classes.fontBold}
              variant="body2"
              align="center"
            >
              {t('Via a Link')}
            </Typography>
          </div>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(RefShareDialog);
