import React, { useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

// MUI
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";

import { signIn, socialSignIn } from "../../_redux/auth";

// Icons
import { UserIcon, LockIcon } from "../../_assets/Icons";

import {
  Button as CustomButton,
  TextField as CustomTextField,
  SocialButton,
} from "../../Components";

import useStylesBase from "./styles";

const Login: React.FC = () => {
  const classesBase = useStylesBase();
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation("auth");

  const [errors, setErrors] = useState<{ [x: string]: string }>({});

  const form = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email(
          t("translation:invalidMessage", { field: t("translation:email") })
        )
        .required(
          t("translation:requiredMessage", { field: t("translation:email") })
        ),
      password: Yup.string().required(
        t("translation:requiredMessage", { field: t("translation:password") })
      ),
    }),
    onSubmit: async (values) => {
      const error = await dispatch(signIn(values));
      if (error) setErrors(error);
    },
  });

  const handleSocialLogin = async (user: {
    provider: "facebook";
    profile: {
      id: string;
      name: string;
      firstName: string;
      lastName: string;
      email: string;
      profilePicURL: string;
    };
    token: {
      accessToken: string;
      expiresAt: number;
    };
  }) => {
    const socialData = {
      name: user.profile.name,
      email: user.profile.email,
      picture: user.profile.profilePicURL,
      id: user.profile.id,
      accessToken: user.token.accessToken,
      userID: user.profile.id,
      graphDomain: "facebook" as "facebook",
      mode: "facebook" as "facebook",
    };
    console.log({ user, socialData });
    const error = await dispatch(socialSignIn(socialData));
    console.log(error);
    history.push({
      pathname: "/auth/social",
      state: { socialData },
    });
  };

  const handleSocialLoginFailure = (err: any) => {
    console.log(err);
    setErrors({ msg: t("fbError") });
  };

  const {
    errors: formErrors,
    touched,
    getFieldProps,
    handleSubmit,
    isSubmitting,
  } = form;
  const getError = (field: keyof typeof form.initialValues) =>
    !!(formErrors[field] && touched[field]);
  const getHelperText = (field: keyof typeof form.initialValues) =>
    touched[field] && formErrors[field];

  return (
    <form className={classesBase.root} onSubmit={handleSubmit} noValidate>
      <Typography className={classesBase.title} component="h1" align="center">
        {t("signIn")}
      </Typography>
      {/* Username */}
      <CustomTextField
        fullWidth
        variant="outlined"
        margin="dense"
        placeholder={t("translation:username")}
        autoComplete="email"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <UserIcon />
            </InputAdornment>
          ),
        }}
        error={getError("email")}
        helperText={getHelperText("email")}
        {...getFieldProps("email")}
      />
      {/* Password */}
      <CustomTextField
        fullWidth
        type="password"
        variant="outlined"
        margin="dense"
        placeholder={t("translation:password")}
        autoComplete="current-password"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockIcon />
            </InputAdornment>
          ),
        }}
        error={getError("password")}
        helperText={getHelperText("password")}
        {...getFieldProps("password")}
      />
      {/* Submit */}
      <Box my={4} width="100%">
        {/* Non Field Error */}
        <Typography align="center" color="error" variant="body2">
          {errors.msg}
        </Typography>
        <CustomButton
          color="secondary"
          style={{ width: "100%" }}
          type="submit"
          disabled={isSubmitting}
        >
          {t("signIn")}
        </CustomButton>
      </Box>
      <Typography align="center">
        <Link
          align="center"
          component={RouterLink}
          to="/auth/reset-password"
          className={classesBase.link}
        >
          {t("forgotPassword")}?
        </Link>
      </Typography>
      <Box marginBottom={2} clone>
        <Typography align="center">
          {t("noAccount")}?
          <Link
            component={RouterLink}
            to="/auth/signup"
            className={classesBase.link}
          >
            {t("signUp")}
          </Link>
        </Typography>
      </Box>
      <Box mt={2}>
        <SocialButton
          provider="facebook"
          appId="2914195962021741"
          onLoginSuccess={handleSocialLogin}
          onLoginFailure={handleSocialLoginFailure}
        >
          {t("continueWithFacebook")}
        </SocialButton>
      </Box>
    </form>
  );
};

export default React.memo(Login);
