import React, { useCallback, useMemo } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import {
  Theme,
  createStyles,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";

import {
  makeStrategiesForDateAndCategory,
  makeJoinedStrategiesForDateAndCategory,
} from "../../../../_redux/league/selectors";
import { useTypedSelector } from "../../../../_redux/rootReducer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltip: {
      backgroundColor: "#E4E7EA",
      color: "#3A3361",
    },
    tooltipArrow: {
      "&:before": { backgroundColor: "#E4E7EA" },
    },
    root: {
      width: `${100 / 7}%`,
      paddingTop: `${100 / 7}%`,
      position: "relative",
      borderRadius: 6,
      color: "#ffffff40",
    },
    stratAvailable: (props: DateCellProps) => ({
      cursor: "pointer",
      color: props.selected ? "#fff" : "#FC6E4F",
      backgroundColor: props.selected ? "#FC6E4F" : "unset",
      "&:hover": {
        backgroundColor: props.selected ? "#FC6E4F" : "#FC6E4F80",
      },
    }),
    purhaseUnavailable: { color: "#FC6E4F80 !important" },
    dateTextContainer: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    dateText: { fontWeight: theme.typography.fontWeightBold },
  })
);

interface DateCellProps {
  date: Date;
  onClick: (date: Date) => void;
  selected: boolean;
  category: "Bronze" | "Silver" | "Gold";
}

function DateCell(props: DateCellProps) {
  const { date, onClick, category } = props;
  const dateDisplay = date.getDate();
  const classes = useStyles(props);
  const { t } = useTranslation("league");

  const selectStarategies = useMemo(makeStrategiesForDateAndCategory, []);
  const selectJoinedStrategies = useMemo(
    makeJoinedStrategiesForDateAndCategory,
    []
  );
  const strats = useTypedSelector((state) =>
    selectStarategies(state, category || "Bronze", date)
  );
  const joinedStrats = useTypedSelector((state) =>
    selectJoinedStrategies(state, category || "Bronze", date)
  );
  const allStratsPurchased = useMemo(
    () => strats.every((x) => joinedStrats.some((y) => y._id === x._id)),
    [joinedStrats, strats]
  );

  const canPurchase = useMemo(
    () =>
      strats.length > 0 && !allStratsPurchased && date.getTime() > Date.now(),
    [allStratsPurchased, date, strats.length]
  );

  const rootClass = clsx(classes.root, {
    [classes.stratAvailable]: canPurchase,
    [classes.purhaseUnavailable]: strats.length > 0 && !canPurchase,
  });

  const handleClick = useCallback(() => {
    if (!canPurchase) return;
    onClick(date);
  }, [canPurchase, onClick, date]);

  const tooltipTitle = useMemo(() => {
    if (strats.length === 0) return t("No leagues available");
    if (allStratsPurchased) return t("You have already purchased all tickets");
    if (Date.now() > date.getTime())
      return t(
        "Tickets can no longer be purchased becuase league has already started"
      );
    return t("tickets available for purchase", {
      count: strats.length - joinedStrats.length,
    });
  }, [allStratsPurchased, date, joinedStrats.length, strats.length, t]);

  return (
    <Tooltip
      title={tooltipTitle}
      arrow
      classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
    >
      <div className={rootClass} onClick={handleClick}>
        {dateDisplay > 0 && (
          <div className={classes.dateTextContainer}>
            <Typography align="center" className={classes.dateText}>
              {dateDisplay}
            </Typography>
          </div>
        )}
      </div>
    </Tooltip>
  );
}

export default React.memo(DateCell);
