import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";

import { getRefStatus } from "../../_redux/auth";

import BgImage from "../../_assets/Images/Bg-image.jpg";
import BgImage2x from "../../_assets/Images/Bg-image@2x.jpg";
import BgOverlay from "../../_assets/Images/Bg-ovelay.svg";
import Polygons from "../../_assets/Images/Polygons.svg";

import Header from "./Header";
import Footer from "../Footer";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
    padding: theme.spacing(2, 0),
  },
  paperRounded: {
    borderRadius: theme.spacing(1),
  },
  paperRoot: {
    padding: theme.spacing(1, 4),
    boxShadow: "0px 60px 120px #090E1833",
    background: "#090E18bb",
    // opacity: 0.7,
    color: "#fff",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(5, 20),
    },
  },
  background: {
    backgroundImage: `image-set( url("${BgImage}") 1x, url("${BgImage2x}") 2x )`,
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    zIndex: -2,
    backgroundPosition: "top",
    "&:after": {
      content: '""',
      backgroundImage: `url("${BgOverlay}")`,
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    },
  },
  ploygons: {
    backgroundImage: `url("${Polygons}")`,
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    zIndex: -2,
    backgroundPosition: "bottom",
  },
}));

interface AuthLayoutProps {
  children: React.ReactNode;
}

const AuthLayout: React.FC<AuthLayoutProps> = (props) => {
  const classes = useStyles();
  const dispatch: AppDispatch = useDispatch();
  const { children } = props;

  useEffect(() => {
    dispatch(getRefStatus());
  }, [dispatch]);

  return (
    <div className={classes.root}>
      <Header />
      <Container className={classes.container}>
        <Paper
          elevation={0}
          classes={{ rounded: classes.paperRounded }}
          className={classes.paperRoot}
        >
          {children}
        </Paper>
      </Container>
      <div className={classes.background} />
      <div className={classes.ploygons} />
      <Footer />
    </div>
  );
};

export default React.memo(AuthLayout);
