import React from "react";

import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function ThumbsUp(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 21 21">
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="21"
        viewBox="0 0 21 21"
      > */}
      <g transform="translate(0.001)">
        <g transform="translate(0 0)">
          <circle
            fill="#3be4ce"
            cx="10.5"
            cy="10.5"
            r="10.5"
            transform="translate(-0.001)"
          />
          <path
            fill="snow"
            d="M1.5,14.56H4.112V6.724H1.5ZM15.866,7.377A1.31,1.31,0,0,0,14.56,6.071H10.44l.62-2.984.02-.209a.983.983,0,0,0-.287-.692L10.1,1.5,5.8,5.8a1.277,1.277,0,0,0-.385.921v6.53A1.31,1.31,0,0,0,6.724,14.56H12.6a1.3,1.3,0,0,0,1.2-.8l1.972-4.6a1.29,1.29,0,0,0,.091-.477V7.436l-.007-.007Z"
            transform="translate(1.518 2.273)"
          />
        </g>
      </g>
      {/* </svg> */}
    </SvgIcon>
  );
}
