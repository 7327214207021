import translation from './translation.json';
import header from './header.json';
import auth from './auth.json';
import dashboard from './dashboard.json';
import league from './league.json';
import leaderboard from './leaderboard.json';
import referrals from './referrals.json';

export default {
  translation,
  header,
  auth,
  dashboard,
  league,
  leaderboard,
  referrals,
};
