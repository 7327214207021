import React from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  Box,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

import Mascot4 from "../../../_assets/Images/Mascot4.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    noTransactionImgContainer: {
      display: "flex",
      height: "100%",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  })
);

const NoDataDisplay: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation("referrals");

  return (
    <>
      <Box my={{ xs: 2, sm: 3, md: 4 }}>
        <Typography variant="h6" align="center">
          {t('Keep track of all of your Business Associate bonus here')}
        </Typography>
      </Box>
      <div className={classes.noTransactionImgContainer}>
        <img src={Mascot4} alt="mascot" />
      </div>
    </>
  );
};

export default React.memo(NoDataDisplay);
