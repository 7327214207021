import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import {
  makeStyles,
  Theme,
  createStyles,
  Grid,
  Typography,
  Box,
} from "@material-ui/core";
import { ArrowUpwardRounded, ArrowDownwardRounded } from "@material-ui/icons";

import { useTypedSelector } from "../../../_redux/rootReducer";
import {
  receiveCurrencyList,
  fetchNetworthHistory,
} from "../../../_redux/league";
import { Currency, NetWorthHistoryItem } from "../../../_redux/league/types";
import { selectUserId } from "../../../_redux/auth/selectors";
import {
  makeStrategyByIdSelector,
  makeLeagueInfoSelector,
  makeNetworthHistorySelector,
} from "../../../_redux/league/selectors";

import { SocketContext } from "../../../SocketProvider";
import { format } from "date-fns";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fontBold: {
      fontWeight: theme.typography.fontWeightBold,
    },
  })
);

interface LeagueInfoProps {
  strategyId: string;
}

const LeagueInfo: React.FC<LeagueInfoProps> = (props) => {
  const { strategyId } = props;
  const classes = useStyles();
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation("league");
  const { leagueDetailSocket } = useContext(SocketContext);

  const selectStrategy = useMemo(makeStrategyByIdSelector, []);
  const selectStrategyInfo = useMemo(makeLeagueInfoSelector, []);
  const selectNetWorthHistory = useMemo(makeNetworthHistorySelector, []);
  const userId = useTypedSelector(selectUserId);
  const strategy = useTypedSelector((state) =>
    selectStrategy(state, strategyId)
  );
  const stratInfo = useTypedSelector((state) =>
    selectStrategyInfo(state, strategyId)
  );
  const netWorthHistory = useTypedSelector((state) =>
    selectNetWorthHistory(state, strategyId)
  );
  const { balance = 0, netWorth = 100, baseCurrency = "USD", rank = 0 } =
    stratInfo || {};

  const emitGetLeaguesDetail = useCallback(() => {
    // console.log('emit');
    if (!strategy) return;
    const data = {
      userId,
      strategyId,
      leagueAmount: strategy.leagueAmount,
    };
    leagueDetailSocket.emit(
      "getLeaguesDetail",
      data,
      (res: {
        cD: Currency[];
        netWorth?: number;
        balance?: number;
        baseCurrency?: string;
        rank?: number;
      }) => {
        const { cD: currencies, netWorth, balance, baseCurrency, rank } = res;
        dispatch(
          receiveCurrencyList({
            strategyId,
            currencies,
            netWorth,
            balance,
            baseCurrency,
            rank,
          })
        );
      }
    );
  }, [dispatch, leagueDetailSocket, strategy, strategyId, userId]);

  useEffect(() => {
    emitGetLeaguesDetail();
    const interval = setInterval(emitGetLeaguesDetail, 30 * 1000);
    return () => clearInterval(interval);
  }, [emitGetLeaguesDetail]);
  useEffect(() => {
    dispatch(fetchNetworthHistory(strategyId));
  }, [dispatch, strategyId]);

  return (
    <>
      <Grid container spacing={5}>
        {/* Base Currency */}
        <Grid item xs={12} sm={6}>
          <Typography color="textSecondary" variant="body2">
            {t('Base Currency')}
          </Typography>
          <Typography variant="h4" className={classes.fontBold}>
            {baseCurrency}
          </Typography>
        </Grid>
        {/* Wallet Balance */}
        <Grid item xs={12} sm={6}>
          <Typography color="textSecondary" variant="body2">
            {t('Wallet Balance')} ({baseCurrency})
          </Typography>
          <Typography variant="h4" className={classes.fontBold}>
            {baseCurrency === "USD" ? "$" : ""}
            {balance.toFixed(2)}
          </Typography>
        </Grid>
        {/* Strategy Rank */}
        <Grid item xs={12} sm={6}>
          <Typography color="textSecondary" variant="body2">
            {t('Strategy Rank')}
          </Typography>
          <Typography variant="h4" className={classes.fontBold}>
            {rank} <ArrowUpwardRounded fontSize="small" color="secondary" />
          </Typography>
        </Grid>
        {/* Networth Status */}
        <Grid item xs={12} sm={6}>
          <Typography color="textSecondary" variant="body2">
            {t('Networth Status')}
          </Typography>
          <Typography variant="h4" className={classes.fontBold}>
            {netWorth.toFixed(4)}
            {netWorth > 0 && (
              <ArrowUpwardRounded fontSize="small" color="secondary" />
            )}
            {netWorth < 0 && (
              <ArrowDownwardRounded fontSize="small" color="error" />
            )}
          </Typography>
        </Grid>
      </Grid>
      <Box my={4}>
        {!!netWorthHistory?.length && <Graph data={netWorthHistory} />}
      </Box>
    </>
  );
};

export default React.memo(LeagueInfo);

interface TooltipContentProps {
  active: boolean;
  payload: [{ name: string; value: string }];
  label: string;
}

const TooltipContent: React.FC<TooltipContentProps> = ({
  active,
  payload,
  label,
}) => {
  if (!active) return null;
  return (
    <div style={{ backgroundColor: "#3BE4CE", padding: 8 }}>
      <Typography
        variant="body2"
        style={{ color: "white", fontWeight: "bold" }}
      >
        {payload?.[0]?.value}
      </Typography>
    </div>
  );
};

const Graph: React.FC<{ data: NetWorthHistoryItem[] }> = React.memo(
  ({ data }) => {
    const transformedData = useMemo(
      () =>
        data.map((x) => ({ ...x, time: format(new Date(x.time), "hh:mm") })),
      [data]
    );
    return (
      <ResponsiveContainer width="100%" minHeight={200}>
        <LineChart
          data={transformedData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <XAxis dataKey="time" />
          <YAxis />
          <Tooltip content={TooltipContent} />
          <Line dataKey="netWorth" stroke="#FC6E4F" />
        </LineChart>
      </ResponsiveContainer>
    );
  }
);
