import React, { useEffect, useMemo, useState } from "react";
import { format, subDays, Locale } from "date-fns";
import { enUS, ja } from "date-fns/locale";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import AvatarGroup from "@material-ui/lab/AvatarGroup";
import {
  makeStyles,
  createStyles,
  Theme,
  Avatar,
  Box,
  Typography,
  Paper,
} from "@material-ui/core";

import { useTypedSelector } from "../../_redux/rootReducer";
import { fetchLeagueLeaderboard } from "../../_redux/leaderboard";
import { leagueCategory } from "../../_redux/leaderboard/types";
import { makeLeaderboardForDateAndCategorySelector } from "../../_redux/leaderboard/selectors";

import UserItem from "./UserItem";
import CategorySelect from "./CategorySelect";
import DateSelect from "./DateSelect";

import AnimatedSvg3 from "../../_assets/AnimatedSvgs/SVG3";
// import Mascot3 from "../../_assets/Images/Mascot3.svg";

const locales: { [key: string]: Locale } = { enUS, ja };

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      background:
        "transparent linear-gradient(180deg, #060220 0%, #4043FF 100%) 0% 0% no-repeat padding-box",
      boxShadow: "0px 3px 6px #00000099",
      padding: theme.spacing(2),
      color: "#fff",
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    title: {
      fontWeight: "bold",
      fontSize: 24,
      marginBottom: theme.spacing(2),
      textTransform: "uppercase",
    },
    header: {
      borderBottom: "#3BE4CE 1px solid",
    },
    headerActions: {
      display: "flex",
      justifyContent: "space-between",
    },
    headerTitleContainer: {
      display: "flex",
      justifyContent: "space-evenly",
      margin: theme.spacing(2, 0),
    },
    fontBold: {
      fontWeight: "bold",
    },
  })
);

export default function LeaderBoard() {
  const classes = useStyles();
  const dispatch: AppDispatch = useDispatch();
  const { t, i18n } = useTranslation("leaderboard");

  const [category, setCategory] = useState<leagueCategory>("Bronze");
  const [endDate, setEndDate] = useState<Date | null>(null);

  const selectLeaderboard = useMemo(
    makeLeaderboardForDateAndCategorySelector,
    []
  );
  const leaderboard = useTypedSelector((state) =>
    selectLeaderboard(state, category, endDate)
  );

  useEffect(() => {
    if (endDate) {
      dispatch(fetchLeagueLeaderboard(format(endDate, "yyyy-MM-dd"), category));
    }
  }, [category, dispatch, endDate]);

  const renderWinner = useMemo(() => {
    if (leaderboard.length === 0)
      return (
        <Box
          my={{ xs: 2, sm: 4 }}
          display="flex"
          flexDirection="column"
          height="100%"
        >
          <Typography align="center" className={classes.fontBold}>
            {t("leaderboardNoDataMsg")}
          </Typography>
          <Box
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {/* <img src={Mascot3} alt="" /> */}
            <AnimatedSvg3 />
          </Box>
        </Box>
      );
    return (
      <>
        <Box marginY={1}>
          <Typography align="center" className={classes.fontBold}>
            {t('Winner')}
          </Typography>
        </Box>
        <UserItem user={leaderboard[0]} />
      </>
    );
  }, [classes.fontBold, leaderboard, t]);

  const renderRunnerups = useMemo(() => {
    if (leaderboard.length < 2) return null;
    return (
      <>
        <Box marginY={2}>
          <Typography align="center" className={classes.fontBold}>
            {t('Runner ups')}
          </Typography>
        </Box>
        {leaderboard.slice(1, 5).map((item, index) => (
          <Box marginY={2} key={item._id}>
            <UserItem user={item} />
          </Box>
        ))}
      </>
    );
  }, [classes.fontBold, leaderboard, t]);

  const renderAvatars = useMemo(() => {
    if (leaderboard.length < 6) return;
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <AvatarGroup max={4} spacing="small">
          {leaderboard.slice(5, 9).map((user) => (
            <Avatar
              key={user._id}
              alt={user.userId.name}
              src={user.userId.profileImg}
            >
              {user.userId.name}
            </Avatar>
          ))}
        </AvatarGroup>
        {leaderboard.length > 10 && (
          <Box marginLeft={1}>
            <Typography>{t('countMore', {count: leaderboard.length - 9})}</Typography>
          </Box>
        )}
      </Box>
    );
  }, [leaderboard, t]);

  return (
    <Paper className={classes.card}>
      <Typography className={classes.title} align="center">
        {t("Leaderboard")}
      </Typography>
      <div className={classes.header}>
        <div className={classes.headerActions}>
          {/* Category */}
          <CategorySelect category={category} setCategory={setCategory} />
          {/* Date */}
          <DateSelect endDate={endDate} setEndDate={setEndDate} />
        </div>
        <div className={classes.headerTitleContainer}>
          {endDate && (
            <Typography className={classes.fontBold}>
              {t(`translation:${category}`)}{" "}
              {format(subDays(endDate, 2), "do MMM", {
                locale: locales[i18n.language] ?? enUS,
              })}{" "}
              -{" "}
              {format(endDate, "do MMM yyyy", {
                locale: locales[i18n.language] ?? enUS,
              })}
            </Typography>
          )}
        </div>
      </div>
      {renderWinner}
      {renderRunnerups}
      {renderAvatars}
    </Paper>
  );
}
