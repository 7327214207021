import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { animated, useSpring } from "react-spring";

import {
  withStyles,
  makeStyles,
  createStyles,
  Theme,
  Box,
  Collapse,
  TableCell as MUITableCell,
  TableRow,
  Typography,
} from "@material-ui/core";

import { Currency } from "../../../../_redux/league/types";
import { Button } from "../../../../Components";
import RowExpanded from "./RowExpanded";

const AnimatedTypography = animated(Typography);

const TableCell = withStyles(
  createStyles({
    root: {
      whiteSpace: "nowrap",
    },
  })
)(MUITableCell);

interface RowProps {
  row: Currency;
  index: number;
  onBuy: (arg0: string) => void;
  onSell: (arg0: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: (props: RowProps) => ({
      backgroundColor: props.index % 2 === 0 ? "#06022066" : "#130D4A66",
      "& > *": {
        borderBottom: "unset",
      },
      // "&:hover": {
      //   transform: "translateY(-5%)",
      // },
    }),
    titleText: {
      color: "#fff",
      fontWeight: theme.typography.fontWeightBold,
    },
    subtitleText: {
      color: "#ffffff80",
    },
    // expandedTitleText: {},
  })
);

const Row: React.FC<RowProps> = (props) => {
  const { row, onBuy, onSell } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useStyles(props);
  const { t } = useTranslation("league");

  const handleBuyPress = useCallback(
    () => onBuy(row.alias),
    [onBuy, row.alias]
  );
  const handleSellPress = useCallback(
    () => onSell(row.alias),
    [onSell, row.alias]
  );

  const animatedProps = useSpring<{ price: number; volume24HOURTO: number }>({
    price: row.price ?? 0,
    volume24HOURTO: row.volume24HOURTO ?? 0,
  });

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        {/* Coin */}
        <TableCell component="th" scope="row">
          <Typography className={classes.titleText}>
            {row.alias}{" "}
            <Typography
              variant="body2"
              component="span"
              className={classes.subtitleText}
            >
              ({row.symbol})
            </Typography>
          </Typography>
          <Typography variant="body2" className={classes.subtitleText}>
            $112.1 Billions
          </Typography>
        </TableCell>
        {/* Rate */}
        <TableCell align="right">
          <AnimatedTypography className={classes.titleText}>
            {animatedProps.price.interpolate((x) => `$${x.toFixed(2)}`)}
          </AnimatedTypography>
          <Typography
            variant="body2"
            color={
              (row.quotes?.USD.percent_change_24h ?? 0) < 0
                ? "error"
                : "secondary"
            }
          >
            {row.quotes?.USD.percent_change_24h.toFixed(2)}%
          </Typography>
        </TableCell>
        {/* Trade Vol */}
        <TableCell align="right">
          <AnimatedTypography className={classes.titleText}>
            {animatedProps.volume24HOURTO.interpolate((x) => `$${x.toFixed()}`)}
          </AnimatedTypography>
        </TableCell>
        {/* Holding */}
        <TableCell align="right">
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <Typography className={classes.titleText}>
              {+(row.holding ?? 0).toFixed(8)}
            </Typography>
            <Box ml={0.5}>
              <Typography variant="body2" className={classes.subtitleText}>
                ({row.symbol})
              </Typography>
            </Box>
          </Box>
          <Typography className={classes.subtitleText} variant="body2">
            {t("usdApprox", {
              count: +((row.holding ?? 0) * (row.price ?? 0)).toFixed(2),
            })}
          </Typography>
        </TableCell>
        {/* Actions */}
        <TableCell align="center">
          <Button
            color="secondary"
            variant="outlined"
            shape="pill"
            onClick={handleBuyPress}
          >
            {t("Buy")}
          </Button>
          <Box mx={1} display="inline-flex" color="#FC6E4F">
            <Button
              color="inherit"
              variant="outlined"
              shape="pill"
              onClick={handleSellPress}
            >
              {t("Sell")}
            </Button>
          </Box>
          <Button
            variant="outlined"
            shape="pill"
            onClick={() => setOpen(!open)}
          >
            {t("More")}
          </Button>
        </TableCell>
      </TableRow>
      {/* Expension */}
      <TableRow className={classes.root}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <RowExpanded row={row} />
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default React.memo(Row);
