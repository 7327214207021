import React from "react";
import { useTranslation } from "react-i18next";

// MUI
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Box, Container, Link, Toolbar } from "@material-ui/core";

import Logo from "../../_assets/Logo.svg";

const loginButtonColor = "#3BE4CE";
const earlyBirdButtonColor = "#4043FF";
const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
  },
  appBar: {
    backgroundColor: "#090E18",
  },
  toolbar: {
    height: 83,
  },
  content: {
    flexGrow: 1,
    justifyContent: "center",
  },
  link: {
    margin: theme.spacing(0, 2.5),
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: -0.54,
    color: "#576078",
    textDecoration: "none",
    fontFamily: "Montserrat",
    "&:hover": {
      color: "#4043ff",
      transition: "color 300ms linear",
      textDecoration: "underline",
    },
  },
  buttonRoot: {
    padding: theme.spacing(1, 4),
    textTransform: "none",
  },
  loginButton: {
    backgroundColor: loginButtonColor,
    borderColor: loginButtonColor,
    "&:hover": {
      backgroundColor: loginButtonColor,
      borderColor: loginButtonColor,
    },
    "&:active": {
      backgroundColor: loginButtonColor,
      borderColor: loginButtonColor,
    },
  },
  earlyBirdButton: {
    marginLeft: theme.spacing(2),
    backgroundColor: earlyBirdButtonColor,
    borderColor: earlyBirdButtonColor,
    "&:hover": {
      backgroundColor: earlyBirdButtonColor,
      borderColor: earlyBirdButtonColor,
    },
    "&:active": {
      backgroundColor: earlyBirdButtonColor,
      borderColor: earlyBirdButtonColor,
    },
  },
}));

const links = [
  { key: "home", to: "https://playctl.net/" },
  { key: "howToPlay", to: "https://playctl.net/#" },
  { key: "upcomingLeagues", to: "https://playctl.net/all-upcoming-leagues/" },
  { key: "ourTeam", to: "https://playctl.net/" },
  { key: "blogs", to: "https://playctl.net/blogs" },
];

export default function ButtonAppBar() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Container>
          <Toolbar className={classes.toolbar}>
            <a href="https://playctl.net">
              <img src={Logo} alt="Play Ctl Logo" />
            </a>
            {/* hide on screens smaller than md */}
            <Box clone display={{ xs: "none", md: "flex" }}>
              <div className={classes.content}>
                {links.map((link) => (
                  <Link key={link.key} href={link.to} className={classes.link}>
                    {t(link.key)}
                  </Link>
                ))}
              </div>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}
