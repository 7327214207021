import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  makeStyles,
  createStyles,
  Theme,
  Typography,
  Box,
} from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import { StarBorder as StarBorderIcon } from "@material-ui/icons";

import { Button } from "../../../Components";
import InfoDialog from "./InfoDialog";
import { BaData } from "../../../_redux/auth/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      marginBottom: theme.spacing(2),
    },
    rating: {
      color: theme.palette.secondary.main,
    },
    fontBold: {
      fontWeight: theme.typography.fontWeightBold,
    },
  })
);

interface HeaderProps {
  baData: BaData | null;
  selectedSeason: string;
}

const Header: React.FC<HeaderProps> = (props) => {
  const classes = useStyles();
  const { baData, selectedSeason } = props;
  const { t } = useTranslation("referrals");

  const [infoOpen, setInfoOpen] = useState(false);
  const openInfo = useCallback(() => setInfoOpen(true), []);
  const closeInfo = useCallback(() => setInfoOpen(false), []);

  return (
    <div className={classes.root}>
      <Box flex="1 1 0"></Box>
      <Box
        flex="1 1 0"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Typography
          variant="h5"
          color="textPrimary"
          align="center"
          className={classes.fontBold}
        >
          {t("translation:Business Associate")}
        </Typography>
        <Rating
          value={baData?.HLevel.length ?? 0}
          readOnly
          emptyIcon={<StarBorderIcon fontSize="inherit" />}
          classes={{ root: classes.rating, iconEmpty: classes.rating }}
        />
      </Box>
      <Box
        flex="1 1 0"
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
      >
        <Button color="secondary" shape="pill" onClick={openInfo}>
          {t('What is business Associate?')}
        </Button>
      </Box>
      <InfoDialog
        open={infoOpen}
        onClose={closeInfo}
        selectedSeason={selectedSeason}
      />
    </div>
  );
};

export default React.memo(Header);
