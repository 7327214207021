import React from "react";

import {
  makeStyles,
  createStyles,
  Theme,
  Paper,
  Table,
  TableBody,
  TableContainer,
  Box,
} from "@material-ui/core";

import { BaData } from "../../../../_redux/auth/types";

import { AnimatedPaper } from "../../../../Components";
import TableHead from "./Head";
import Row from "./Row";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    noTransactionImgContainer: {
      display: "flex",
      height: "100%",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    root: {
      padding: theme.spacing(5, 0),
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(5),
      },
    },
    tableContainer: {
      backgroundColor: "transparent",
    },
    paginationSelectIcon: {
      color: theme.palette.text.primary,
    },
  })
);

interface BusinessAssociateTableProps {
  data: BaData;
}

const BusinessAssociateTable: React.FC<BusinessAssociateTableProps> = (
  props
) => {
  const classes = useStyles();
  const { data } = props;

  return (
    <div className={classes.root}>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead />
          <Box height={20} />
          <TableBody component={AnimatedPaper} scale={1.02}>
            {data.HLevel.map((row, i) => (
              <Row key={row._id} row={row} index={i} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default React.memo(BusinessAssociateTable);
