import * as types from "./types";
import * as authTypes from "../auth/types";

interface LeaderboardState {
  leagueLeaderboard: {
    Bronze: { [date: string]: types.LeaderboardItem[] };
    Silver: { [date: string]: types.LeaderboardItem[] };
    Gold: { [date: string]: types.LeaderboardItem[] };
  };
  winners: {
    Bronze: types.LeagueWinnerItem[];
    Silver: types.LeagueWinnerItem[];
    Gold: types.LeagueWinnerItem[];
  };
}

const initialState: LeaderboardState = {
  leagueLeaderboard: { Bronze: {}, Silver: {}, Gold: {} },
  winners: { Bronze: [], Silver: [], Gold: [] },
};

export default function (
  state: LeaderboardState = initialState,
  action: types.LeaderboardActionTypes | authTypes.AuthActionTypes
): LeaderboardState {
  switch (action.type) {
    case types.RECEIVE_LEADERBOARD_DATA:
      return {
        ...state,
        leagueLeaderboard: {
          ...state.leagueLeaderboard,
          [action.leagueCategory]: { [action.date]: action.data },
        },
      };
    case types.RECEIVE_WINNERS_DATA:
      return {
        ...state,
        winners: {
          Bronze: action.data.bronze,
          Silver: action.data.silver,
          Gold: action.data.gold,
        },
      };
    case authTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
}
