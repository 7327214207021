import React from "react";
import { useSpring, animated } from "react-spring";

import { PaperProps } from "@material-ui/core";
import Paper from "./Paper";

const APaper = animated(Paper);
const trans = (s: number) => `scale(${s})`;

interface AnimatedPaperProps extends PaperProps {
  scale?: number;
}

export default function AnimatedPaper(props: AnimatedPaperProps) {
  const { scale = 1.04, children, style, ...otherProps } = props;
  const [animProps, set] = useSpring(() => ({
    s: 1,
    config: { mass: 1, tension: 350, friction: 10 },
  }));

  return (
    <APaper
      onMouseMove={() => set({ s: scale })}
      onMouseLeave={() => set({ s: 1 })}
      style={{ transform: animProps.s.interpolate(trans), ...style }}
      {...otherProps}
    >
      {children}
    </APaper>
  );
}
