import React, { useCallback, useMemo } from "react";
import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";

import { Box } from "@material-ui/core";

import LeagueCalendar from "./LeagueCalendar";
import Button from "./components/Button";

import { BuyTicketFormValues } from "./data";

function LeagueList(props: { form: FormikProps<BuyTicketFormValues> }) {
  const { form } = props;
  const { values, setFieldValue } = form;
  const { t } = useTranslation('league');

  const isValid = useMemo(() => values.category && values.date, [
    values.category,
    values.date,
  ]);

  const handleNext = useCallback(() => {
    if (!isValid) return;
    setFieldValue("activeStep", 1);
  }, [isValid, setFieldValue]);

  return (
    <>
      <Box my={2}>
        <LeagueCalendar title={"Bronze"} form={form} />
      </Box>
      <Box my={2}>
        <LeagueCalendar title="Silver" form={form} />
      </Box>
      <Box my={2}>
        <LeagueCalendar title="Gold" form={form} />
      </Box>
      <Box my={2}>
        <Button
          color="secondary"
          variant="contained"
          size="large"
          onClick={handleNext}
          disabled={!isValid}
        >
          {t('Buy Ticket')}
        </Button>
      </Box>
    </>
  );
}

export default React.memo(LeagueList);
