import { createSelector } from "reselect";
import { format, isSameDay } from "date-fns";
import * as types from "./types";

export const makeLeaderBoardsForCategorySelector = () =>
  createSelector(
    (state: RootState) => state.leaderboard.leagueLeaderboard,
    (_: RootState, category: types.leagueCategory) => category,
    (leagueLeaderboard, category) => leagueLeaderboard[category]
  );

export const makeLeaderboardForDateAndCategorySelector = () =>
  createSelector(
    (state: RootState, category: types.leagueCategory) =>
      makeLeaderBoardsForCategorySelector()(state, category),
    (_, __, date: Date | null) =>
      date ? format(date, "yyyy-MM-dd") : "not-found",
    (leaderboard, date) => leaderboard[date] ?? []
  );

export const selectAllWinners = (state: RootState) => state.leaderboard.winners;
export const makeWinnersForCategorySelector = () =>
  createSelector(
    selectAllWinners,
    (_: RootState, category: types.leagueCategory) => category,
    (winners, category) => winners[category]
  );
export const makeWinnerForCategoryAndDateSelector = () =>
  createSelector(
    (
      state: RootState,
      { category, date }: { category: types.leagueCategory; date: Date }
    ) => makeWinnersForCategorySelector()(state, category),
    (
      _: RootState,
      { category, date }: { category: types.leagueCategory; date: Date }
    ) => date,
    (categoryWinners, date) =>
      categoryWinners.find((x) => isSameDay(new Date(x.endDate), date))
  );
