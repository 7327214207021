import callApi from "../../_utils/callApi";
import * as types from "./types";
import { getToken } from "../auth/selectors";

export const fetchLeagueLeaderboard = (
  endDate: string,
  leagueCategory: types.leagueCategory
): AppThunk<null | ObjectStringKey> => async (dispatch, getState) => {
  const token = getToken(getState());
  try {
    const [jsonResponse, error] = await callApi<{
      response: { data: types.LeaderboardItem[] };
    }>({
      endpoint: "v1/league/getLeaderBoard",
      method: "POST",
      token,
      body: { endDate, leagueCategory },
    });
    if (error) return jsonResponse;
    const { data } = jsonResponse.response;
    // console.log(data);
    dispatch<types.LeaderboardActionTypes>({
      type: types.RECEIVE_LEADERBOARD_DATA,
      data,
      leagueCategory,
      date: endDate,
    });
    return null;
  } catch (err) {
    return { msg: String(err) };
  }
};

export const fetchWinners = (): AppThunk<null | ObjectStringKey> => async (
  dispatch,
  getState
) => {
  const token = getToken(getState());

  interface APIResponse {
    response: {
      data: {
        bronze: types.LeagueWinnerItem[];
        silver: types.LeagueWinnerItem[];
        gold: types.LeagueWinnerItem[];
      };
    };
  }

  try {
    const [jsonResponse, error] = await callApi<APIResponse>({
      endpoint: "v1/league/getLeagueWinner",
      token,
    });
    if (error) return jsonResponse;
    const { data } = jsonResponse.response;
    // console.log(data);
    dispatch<types.LeaderboardActionTypes>({
      type: types.RECEIVE_WINNERS_DATA,
      data,
    });
    return null;
  } catch (err) {
    return { msg: String(err) };
  }
};
