import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";

import { fetchCurrrentSeason, listLeagues } from "../../_redux/league";

import Polygons from "../../_assets/Images/Polygons.svg";

import Header from "./Header";
import Footer from "../Footer";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#060220",
    backgroundImage: `url("${Polygons}")`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom",
    backgroundAttachment: "fixed",
  },
  container: {
    backgroundColor: "#06022080",
    display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    flexGrow: 1,
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2.5, 5),
      paddingBottom: theme.spacing(8),
    },
  },
}));

const AppLayout: React.FC = (props) => {
  const { children } = props;
  const classes = useStyles();
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCurrrentSeason());
    dispatch(listLeagues());
  }, [dispatch]);

  return (
    <div className={classes.root}>
      <Header />
      <div className={classes.container}>{children}</div>
      <Footer />
    </div>
  );
};

export default AppLayout;
