export const RECEIVE_LEAGUE_LIST = "RECEIVE_LEAGUE_LIST";
export const RECEIVE_LEAGUE_INFO = "RECEIVE_LEAGUE_INFO";
export const RECEIVE_CURRENCY_LIST = "RECEIVE_CURRENCY_LIST";
export const RECEIVE_TRANSACTIONS_LIST = "RECEIVE_TRANSACTIONS_LIST";
export const RECEIVE_LEAGUE_LEADERBOARD = "RECEIVE_LEAGUE_LEADERBOARD";
export const RECEIVE_NETWORTH_HISTORY = "RECEIVE_NETWORTH_HISTORY";
export const RECEIVE_CURRENT_SEASON = "RECEIVE_CURRENT_SEASON";
export const RECEIVE_CURRENCY_HISTORY = "RECEIVE_CURRENCY_HISTORY";

export type LeagueCategory = "Bronze" | "Silver" | "Gold";

export interface league {
  _id: string;
  leagueCategory: LeagueCategory;
  strategies: number;
  minparticipants: number;
  leagueAmount: string;
  price: string;
  season: string;
  startDate: string;
  endDate: string;
  leagueId: string;
  updatedAt: string;
  createdAt: string;
  __v: number;
}

export interface leaguesSeason {
  _id: string;
  leagueCategory: LeagueCategory;
  strategiesAllowed: number;
  minparticipants: number;
  frequency: number;
  leagueAmount: string;
  price: string;
  season: string;
  endDate: string;
  termsandcondition: string;
  rules: string;
  description: string;
}

export interface leaguesDetail {
  _id: string;
  season: string;
  leagueCategory: LeagueCategory;
  status: string;
  startDate: string;
  endDate: string;
}

interface CurrencyQuote {
  last_updated: string;
  market_cap: number;
  percent_change_1h: number;
  percent_change_7d: number;
  percent_change_24h: number;
  price: number;
  volume_24h: number;
}

export interface Currency {
  alias: string;
  holding?: number;
  name: string;
  oneUSDRate?: number;
  price?: number;
  quotes?: { USD: CurrencyQuote };
  status: "Enable" | "Disable";
  symbol: string;
  symbolId: string;
  lastVOLUME?: number;
  lastVOLUMETO?: number;
  topTIERVOLUME24HOUR?: number;
  volume24HOUR?: number;
  volume24HOURTO?: number;
  volumeDAY?: null | string;
  volumeDAYTO?: number;
  volumeHOURTO?: number;
  __v?: number;
  _id?: string;
}

export interface Transaction {
  _id: string;
  strategyId: string;
  userId: string;
  strategyWalletRecord: string;
  invest?: string;
  withdraw?: string;
  currency: string;
  type: "buy" | "sell" | "CBC";
  transactionId: number;
  baseCurrency: string;
  updatedAt: string;
  createdAt: string;
  __v: number;
}

export interface _LeagueUser {
  _id: string;
  leagueStatus: "Live";
  leagueCategory: LeagueCategory;
  startDate: string;
  endDate: string;
  userId: string;
  strategyId: string;
  strategyWalletRecordID: string;
  netWorth: number;
  currentRank: number;
  name: string;
  profileImage: string;
  __v: number;
}

export interface LeaderBoardItem {
  _id: string;
  leagueStatus: "Live";
  leagueCategory: LeagueCategory;
  startDate: string;
  endDate: string;
  userId: {
    _id: string;
    name: string;
    profileImg: string;
  };
  strategyId: string;
  strategyWalletRecordID: string;
  netWorth: number;
  currentRank: number;
  __v: number;
}

export interface LeagueInfo {
  _id: string;
  participants: number;
  currencies: number;
  transaction: string[];
  currenciesData?: Currency[];
  transactionsData?: Transaction[];
  netWorth: number;
  balance: number;
  baseCurrency: string;
  rank?: number | "NA";
  leaderboard?: LeaderBoardItem[];
}

export interface NetWorthHistoryItem {
  _id: string;
  netWorth: number;
  time: string;
}

export interface CurrentSeason {
  season: string;
  counter: number;
}

export interface CurrencyHistoryItem {
  time: number;
  high: number;
  low: number;
  open: number;
  volumefrom: number;
  volumeto: number;
  close: number;
  conversionType: string;
  conversionSymbol: string;
}

// Actions
interface ReceiveLeagueListAction {
  type: typeof RECEIVE_LEAGUE_LIST;
  data: { leagueLookup: league[]; leaguesSeason: leaguesSeason }[];
  userLeagueDetail: { leagueId: league[]; leaguesDetail: leaguesDetail }[];
}

interface ReceiveLeagueInfoAction {
  type: typeof RECEIVE_LEAGUE_INFO;
  data: { id: string; info: LeagueInfo };
}

interface ReceiveCurrencyListAction {
  type: typeof RECEIVE_CURRENCY_LIST;
  id: string;
  currencies: Currency[];
  netWorth?: number;
  balance?: number;
  baseCurrency?: string;
  rank?: number;
}

interface ReceiveTransactionsListActions {
  type: typeof RECEIVE_TRANSACTIONS_LIST;
  id: string;
  data: Transaction[];
}

interface ReceiveLeagueLeaderBoardAction {
  type: typeof RECEIVE_LEAGUE_LEADERBOARD;
  id: string;
  data: LeaderBoardItem[];
}

interface ReceiveNetworthHistoryAction {
  type: typeof RECEIVE_NETWORTH_HISTORY;
  strategyId: string;
  data: NetWorthHistoryItem[];
}

interface ReceiveCurrentSeasonAction {
  type: typeof RECEIVE_CURRENT_SEASON;
  data: CurrentSeason;
}

interface ReceiveCurrencyHistoryAction {
  type: typeof RECEIVE_CURRENCY_HISTORY;
  symbol: string;
  data: CurrencyHistoryItem[];
}

export type LeagueActionTypes =
  | ReceiveLeagueListAction
  | ReceiveLeagueInfoAction
  | ReceiveCurrencyListAction
  | ReceiveTransactionsListActions
  | ReceiveLeagueLeaderBoardAction
  | ReceiveNetworthHistoryAction
  | ReceiveCurrentSeasonAction
  | ReceiveCurrencyHistoryAction;
