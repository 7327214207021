import React from "react";

import { Grid } from "@material-ui/core";

import GridOne from "./GridOne";
import GridTwo from "./GridTwo";

const Account: React.FC = () => {
  return (
    <Grid container>
      <Grid item xs={12} md={4}>
        <GridOne />
      </Grid>
      <Grid item xs={12} md={8}>
        <GridTwo />
      </Grid>
    </Grid>
  );
};

export default React.memo(Account);
