import React from "react";
import { useTranslation } from "react-i18next";

import { makeStyles, createStyles, Theme, TableCell } from "@material-ui/core";
import { TableHead } from "../../../../Components/Table";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableHeadRoot: {
      backgroundColor: "#3BE4CE",
      "& > tr > *": {
        borderBottom: "unset",
      },
    },
    cell: {
      fontWeight: 600,
    },
  })
);

function Head() {
  const classes = useStyles();
  const { t } = useTranslation("league");

  return (
    <TableHead>
      <TableCell size="small" className={classes.cell}>
        {t('Coin')}
      </TableCell>
      <TableCell size="small" className={classes.cell} align="right">
        {t('Rate (USD)')}
      </TableCell>
      <TableCell size="small" className={classes.cell} align="right">
        {t('24 hrs Trade Vol')}
      </TableCell>
      <TableCell size="small" className={classes.cell} align="right">
        {t('You Holding')}
      </TableCell>
      <TableCell size="small" className={classes.cell} align="center">
        {t('Action')}
      </TableCell>
    </TableHead>
  );
}

export default React.memo(Head);
