import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

// MUI
import { Box, Typography, InputAdornment, Button } from "@material-ui/core";
import { PersonOutline } from "@material-ui/icons";

import { useTypedSelector } from "../../_redux/rootReducer";
import { socialSignUp } from "../../_redux/auth";
import { selectRefCodeRquired } from "../../_redux/auth/selectors";

import { TextField as CustomTextField } from "../../Components";

import useStylesBase from "./styles";

interface IsocialData {
  name: string;
  email: string;
  picture: string;
  id: string;
  accessToken: string;
  userID: string;
  graphDomain: "facebook";
  mode: "facebook" | "google";
}

const SocialSignup: React.FC = () => {
  const classesBase = useStylesBase();
  const location = useLocation<{ socialData?: IsocialData }>();
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation("auth");

  const [errors, setErrors] = useState<{ [x: string]: string }>({});

  const refCodeRequired = useTypedSelector(selectRefCodeRquired);

  const form = useFormik({
    initialValues: {
      refcode: "",
    },
    validationSchema: Yup.object().shape({
      refcode: refCodeRequired
        ? Yup.string().required(
            t("translation:requiredMessage", { field: t("referralCode") })
          )
        : Yup.string().notRequired(),
    }),
    onSubmit: async (values) => {
      // console.log(values);
      const socialData = location.state.socialData;
      if (!socialData) return;
      const error = await dispatch(socialSignUp({ ...socialData, ...values }));
      // console.log(error);
      if (error) return setErrors(error);
    },
  });

  const {
    errors: formErrors,
    touched,
    getFieldProps,
    handleSubmit,
    isSubmitting,
  } = form;
  const getError = (field: keyof typeof form.initialValues) =>
    !!(formErrors[field] && touched[field]);
  const getHelperText = (field: keyof typeof form.initialValues) =>
    touched[field] && formErrors[field];

  if (!location?.state?.socialData) return null;
  return (
    <form className={classesBase.root} noValidate onSubmit={handleSubmit}>
      <Typography className={classesBase.title} component="h1" align="center">
        {t("completeSignUp")}
      </Typography>
      {/* Name */}
      <CustomTextField
        fullWidth
        variant="outlined"
        margin="dense"
        placeholder={t("yourName")}
        autoComplete="name"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PersonOutline />
            </InputAdornment>
          ),
        }}
        disabled
        value={location.state.socialData.name}
      />
      {/* Email */}
      <CustomTextField
        fullWidth
        variant="outlined"
        margin="dense"
        placeholder={t("translation:email")}
        autoComplete="email"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PersonOutline />
            </InputAdornment>
          ),
        }}
        disabled
        value={location.state.socialData.email}
      />
      {/* Referral Code (Optional) */}
      <CustomTextField
        fullWidth
        variant="outlined"
        margin="dense"
        placeholder={t("referralCode")}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PersonOutline />
            </InputAdornment>
          ),
        }}
        error={getError("refcode")}
        helperText={getHelperText("refcode")}
        {...getFieldProps("refcode")}
      />
      {/* Submit */}
      <Box
        my={4}
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        {/* Non Field Error */}
        <Box mt={2}>
          <Typography align="center" color="error" variant="body2">
            {errors.msg}
          </Typography>
          {/* Submit */}
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disableElevation
            disabled={isSubmitting}
            className={classesBase.button}
          >
            {t("completeSignUp")}
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default React.memo(SocialSignup);
