import React, { useCallback, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  makeStyles,
  createStyles,
  Theme,
  Box,
  Typography,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { ExpandMoreRounded } from "@material-ui/icons";

import { useTypedSelector as useSelector } from "../../../_redux/rootReducer";
import { BaData } from "../../../_redux/auth/types";
import { selectCurrentSeason } from "../../../_redux/league/selectors";

import BaIcon from "../../../_assets/Images/BaIcon.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fontBold: {
      fontWeight: theme.typography.fontWeightBold,
    },
    menuButton: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
  })
);

interface PaperTopInfoProps {
  baData: BaData | null;
  selectedSeason: string;
  setSelectedSeason: React.Dispatch<React.SetStateAction<string>>;
}

const PaperTopInfo: React.FC<PaperTopInfoProps> = (props) => {
  const { baData, selectedSeason, setSelectedSeason } = props;
  const classes = useStyles();
  const { t } = useTranslation("referrals");

  const currentSeason = useSelector(selectCurrentSeason);
  const seasons = useMemo(() => {
    const res = ["All"];
    if (currentSeason) {
      for (var i = 1; i <= currentSeason.counter; i++) {
        res.push(`Season${i}`);
      }
    }
    return res;
  }, [currentSeason]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = useCallback(() => setAnchorEl(null), []);

  const handleClick = useCallback((season: string) => {
    setSelectedSeason(season);
    setAnchorEl(null);
  }, [setSelectedSeason]);

  const totalBonus = useMemo(
    () => baData?.HLevel.reduce((acc, crr) => acc + crr.EarnedAmount, 0) ?? 0,
    [baData]
  );

  return (
    <Box display="flex" justifyContent="space-between">
      <Box display="flex" alignItems="center">
        <img src={BaIcon} alt="BA" height="64" />
        <Box ml={2}>
          <Typography variant="body2" color="textSecondary">
            {t('Total Associate Bonus')}
          </Typography>
          <Typography
            variant="h5"
            color="textPrimary"
            className={classes.fontBold}
          >
            ${totalBonus}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Typography variant="body2" color="textSecondary">
          {t('translation:Season')}
        </Typography>
        <div onClick={handleOpen} className={classes.menuButton}>
          <Typography
            variant="h5"
            color="textPrimary"
            className={classes.fontBold}
          >
            {selectedSeason}
          </Typography>
          <ExpandMoreRounded color="secondary" fontSize="small" />
        </div>
        <Menu
          id="category-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {seasons.map((season) => (
            <MenuItem
              key={season}
              disabled={season === selectedSeason}
              selected={season === selectedSeason}
              onClick={(e) => handleClick(season)}
            >
              {season}
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </Box>
  );
};

export default React.memo(PaperTopInfo);
