import React, { useMemo } from "react";
import { format } from "../../../_utils/date";
import { useTranslation } from "react-i18next";

import {
  createStyles,
  makeStyles,
  Theme,
  Box,
  Typography,
} from "@material-ui/core";
import { EmojiEventsRounded } from "@material-ui/icons";
import { AnimatedPaper } from "../../../Components";

import { useTypedSelector } from "../../../_redux/rootReducer";
import { league } from "../../../_redux/league/types";
import { makeWinnerForCategoryAndDateSelector } from "../../../_redux/leaderboard/selectors";
import { selectUserId } from "../../../_redux/auth/selectors";

interface LeagueCardProps {
  strategy: league;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2, 3),
      backgroundColor: "#060220",
      color: "white",
    },
    subtitle: {
      fontWeight: 600,
    },
    info: {
      fontWeight: theme.typography.fontWeightBold,
      color: "#3BE4CE",
    },
  })
);

export default function LeagueCard(props: LeagueCardProps) {
  const { strategy } = props;
  const classes = useStyles();
  const { t } = useTranslation("league");

  const selectWinner = useMemo(makeWinnerForCategoryAndDateSelector, []);
  const winner = useTypedSelector((state) =>
    selectWinner(state, {
      category: strategy.leagueCategory,
      date: new Date(strategy.endDate),
    })
  );
  const userId = useTypedSelector(selectUserId);
  const winnerText = useMemo(() => {
    if (!winner) return "";
    if (winner.userId._id === userId)
      return (
        <>
          {t("Congratulations")}!{" "}
          <Box component="span" color="#FC6E4F">
            {t("You")}
          </Box>{" "}
          {t("are the winner in this league")}
        </>
      );
    return (
      <>
        {t("Opps")}!{" "}
        <Box component="span" color="#FC6E4F">
          {winner?.userId.name}
        </Box>{" "}
        {t("is winner in this league")}
      </>
    );
  }, [t, userId, winner]);

  const subtitle = useMemo(() => {
    return t("CompletedOnDate", {
      date: format(new Date(strategy.endDate), "do MMM yy"),
    });
  }, [strategy.endDate, t]);

  return (
    <AnimatedPaper className={classes.root}>
      <Box display="flex">
        <Box flexGrow={1}>
          <Typography>{strategy.leagueCategory}</Typography>
        </Box>
        <Box color="#FC6E4F" display="flex" fontSize={20}>
          <EmojiEventsRounded fontSize="inherit" />
          <Typography>{t('Leaderboard')}</Typography>
        </Box>
      </Box>
      <Typography className={classes.subtitle} variant="body2">
        {subtitle}
      </Typography>
      <Box mt={2}>
        <Typography align="center" className={classes.info}>
          {winnerText}
        </Typography>
      </Box>
    </AnimatedPaper>
  );
}
