import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { animated, useSpring } from "react-spring";
// import { format } from "../../_utils/date";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  createStyles,
  makeStyles,
  Theme,
  Avatar,
  Drawer,
  Box,
  IconButton,
  Typography,
  Menu,
  MenuItem,
} from "@material-ui/core";
import {
  Close as CloseIcon,
  PowerSettingsNew,
  Settings,
  AccountBalanceWallet,
  // MoreHoriz,
  PeopleRounded,
  Translate as TranslateIcon,
} from "@material-ui/icons";

import { useTypedSelector } from "../../_redux/rootReducer";
import { logout, getProfile } from "../../_redux/auth";
import {
  selectWalletBalance,
  selectUserName,
  selectUserImg,
  // selectWalletTransactions,
} from "../../_redux/auth/selectors";
// import { IwalletTransaction } from "../../_redux/auth/types";

import LiveLeagueGraph from "./LiveLeagueGraph";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
    userAvatar: {
      height: 52,
      width: 52,
      margin: theme.spacing(0, 2),
    },
    userBalanceText: {
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: 14,
      letterSpacing: -0.25,
      color: "#3BE4CE",
    },
    userNameText: {
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: 14,
      color: "#FFFFFF",
    },
    drawerPaper: {
      backgroundImage: "linear-gradient(180deg, #060220 0%, #4043FF 100%)",
      width: 400,
      maxWidth: "100vw",
      padding: theme.spacing(4, 3),
    },
    linkItem: {
      color: "#fff",
      display: "flex",
      alignItems: "center",
      margin: theme.spacing(2, 0),
      cursor: "pointer",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "none",
        color: "#FC6E4F",
      },
    },
    graphPaper: {
      padding: theme.spacing(2),
      backgroundColor: "#4043FF",
    },
  })
);

const languages = [
  { label: "English", key: "en" },
  { label: "日本人", key: "ja" },
];

const ProfileSidebar: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const [open, setOpen] = useState(false);
  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);
  const [lngSelectOpen, setLngSelectOpen] = useState<null | HTMLElement>(null);
  const openLngSelect = useCallback(
    (event: React.MouseEvent<HTMLElement>) =>
      setLngSelectOpen(event.currentTarget),
    []
  );
  const closeLngSelect = useCallback(() => setLngSelectOpen(null), []);

  useEffect(() => {
    dispatch(getProfile());
    // dispatch(getWalletTransactions());
  }, [dispatch]);

  const userName = useTypedSelector(selectUserName);
  const userImg = useTypedSelector(selectUserImg);
  const walletBalance = useTypedSelector(selectWalletBalance);
  const animatedWalletBalance = useSpring<{ bal: number }>({
    bal: walletBalance,
    config: { duration: 800 },
  });
  // const walletTransactions = useTypedSelector(selectWalletTransactions);

  const handleLogout = useCallback(() => dispatch(logout()), [dispatch]);
  const handleNavigate = useCallback(
    (pathname: string) => {
      history.push({ pathname });
      handleClose();
    },
    [handleClose, history]
  );

  const links = [
    {
      icon: <AccountBalanceWallet />,
      label: "Accounts",
      onClick: () => handleNavigate("/account"),
    },
    {
      icon: <PeopleRounded />,
      label: "Business Associate",
      onClick: () => handleNavigate("/business-associate"),
    },
    {
      icon: <TranslateIcon />,
      label: "Change Language",
      onClick: openLngSelect,
    },
    { icon: <Settings />, label: "Settings" },
    { icon: <PowerSettingsNew />, label: "Logout", onClick: handleLogout },
  ];

  return (
    <>
      <div className={classes.container} onClick={handleOpen}>
        <Avatar src={userImg} alt="user avatar" className={classes.userAvatar}>
          {userName[0]}
        </Avatar>
        <div>
          <Typography className={classes.userBalanceText}>
            <animated.span>
              {animatedWalletBalance.bal.interpolate((x) => x.toFixed(8))}
            </animated.span>
            BTC
          </Typography>
          <Typography className={classes.userNameText}>{userName}</Typography>
        </div>
      </div>
      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        classes={{ paper: classes.drawerPaper }}
        keepMounted
      >
        <div>
          {/* User */}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box color="#FFFFFF">
              <Typography variant="h4" color="inherit">
                {userName}
              </Typography>
              <Box color="#748AA1">
                <Typography color="inherit">
                  {walletBalance?.toFixed(8)} BTC
                </Typography>
              </Box>
            </Box>
            <IconButton color="secondary" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          {/* Links */}
          <Box mt={4}>
            {links.map((link) => (
              <div
                role="button"
                key={link.label}
                onClick={link.onClick}
                className={classes.linkItem}
              >
                <Box mx={2}>{link.icon}</Box>
                <Typography>{t(link.label)}</Typography>
              </div>
            ))}
          </Box>
          {/* Graph */}
          <Box my={4}>
            <LiveLeagueGraph />
          </Box>
          {/* Transactions */}
          {/* <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            color="#fff"
          >
            <Typography>{t("Transactions")}</Typography>
            <Box color="#748AA1">
              <Typography variant="body2">{t("View all")}</Typography>
            </Box>
          </Box>
          {walletTransactions.map((transaction) => (
            <TransactionItem key={transaction._id} transaction={transaction} />
          ))} */}
        </div>
      </Drawer>
      <Menu
        open={!!lngSelectOpen}
        anchorEl={lngSelectOpen}
        onClose={closeLngSelect}
      >
        {languages.map((lng) => (
          <MenuItem
            key={lng.key}
            onClick={() => {
              i18n.changeLanguage(lng.key);
              closeLngSelect();
            }}
          >
            {lng.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default React.memo(ProfileSidebar);

// const TransactionItem: React.FC<{ transaction: IwalletTransaction }> = (
//   props
// ) => {
//   const { transaction } = props;
//   const { t } = useTranslation();

//   const [titleColor, avatarBg] = useMemo(() => {
//     // if (type === "credit")
//     //   return ["#3BE4CE", "linear-gradient(180deg, #3BE4CE 0%, #4043FF 100%)"];
//     return ["#FC6E4F", "linear-gradient(180deg, #FC6E4F 0%, #4043FF 100%)"];
//   }, []);

//   return (
//     <Box display="flex" alignItems="center" color="#748AA1" my={1}>
//       <Box
//         height={40}
//         width={40}
//         borderRadius={5}
//         style={{ backgroundImage: avatarBg }}
//       />
//       <Box flexGrow={1} mx={2}>
//         <Box color={titleColor}>
//           <Typography variant="body2">
//             {transaction.leagueCategory} {t("Ticket Purchased")}
//           </Typography>
//         </Box>
//         <Typography variant="caption">
//           {format(new Date(transaction.createdAt), "do MMM yyyy")}
//         </Typography>
//       </Box>
//       <MoreHoriz />
//     </Box>
//   );
// };
