import React, { useMemo } from "react";
// import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

import { LeagueInfo } from "../../../../_redux/league/types";

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     root: {
//       backgroundColor: theme.palette.background.paper,
//     },
//   })
// );

interface BaseCurrencyChangeProps {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  handleClick: (
    event: React.MouseEvent<HTMLElement>,
    index: string
  ) => Promise<void>;
  stratInfo: LeagueInfo;
}

const BaseCurrencyChange: React.FC<BaseCurrencyChangeProps> = (props) => {
  const { anchorEl, handleClose, handleClick, stratInfo } = props;

  const options = useMemo(
    () => [{ symbol: "USD" }, ...(stratInfo.currenciesData ?? [])],
    [stratInfo.currenciesData]
  );

  return (
    <Menu
      id="lock-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      {options.map((crr, i) => (
        <MenuItem
          key={i}
          disabled={crr.symbol === stratInfo.baseCurrency}
          selected={crr.symbol === stratInfo.baseCurrency}
          onClick={(event) => handleClick(event, crr.symbol)}
        >
          {crr.symbol}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default React.memo(BaseCurrencyChange);
