import React, { ReactNode } from "react";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import MuiDialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

const titleStyles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      color: theme.palette.secondary.main,
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof titleStyles> {
  children: React.ReactNode;
  onClose: () => void;
}

const Dialog = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > .MuiBackdrop-root": { backgroundColor: "#130d4acc" },
    },
    paper: {
      color: "#fff",
      backgroundColor: "#060220",
    },
  })
)(MuiDialog);

const DialogTitle = withStyles(titleStyles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {typeof children === "string" ? (
        <Typography align="center" variant="h6" style={{ marginRight: 48 }}>
          {children}
        </Typography>
      ) : (
        children
      )}
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
});

interface CustomizedDialogProps {
  open: boolean;
  title: ReactNode;
  children: ReactNode;
  handleClose: () => void;
}

function CustomizedDialog(props: CustomizedDialogProps) {
  const { open, title, children, handleClose } = props;

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle onClose={handleClose}>{title}</DialogTitle>
      {children}
    </Dialog>
  );
}

export default React.memo(CustomizedDialog);
