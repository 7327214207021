import React, { useCallback, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  makeStyles,
  createStyles,
  Box,
  IconButton,
  Theme,
  Typography,
} from "@material-ui/core";
import { ViewListRounded, ViewModuleRounded } from "@material-ui/icons";

import { useTypedSelector } from "../../_redux/rootReducer";
import { fetchWinners } from "../../_redux/leaderboard";
import { selectAllWinners } from "../../_redux/leaderboard/selectors";

import { AnimatedPaper } from "../../Components";
import AnimatedSvg2 from "../../_assets/AnimatedSvgs/SVG2";

import FinalistsListTable from "./FinalistsListTable";
import FinalistsListBig from "./FinalistsListBig";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
      color: "white",
      [theme.breakpoints.up("lg")]: {
        padding: theme.spacing(3, 10),
      },
    },
    title: {
      fontWeight: theme.typography.fontWeightBold,
    },
    selectedViewIcon: {
      backgroundColor: "#4043FF",
      "&:hover": {
        backgroundColor: "#4043FF80",
      },
    },
  })
);

export default function Leaderboard() {
  const classes = useStyles();
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation("leaderboard");

  const [winnerListView, setWinnerListView] = useState(0);
  const allWinners = useTypedSelector(selectAllWinners);
  const winners = useMemo(
    () => [...allWinners.Bronze, ...allWinners.Silver, ...allWinners.Gold],
    [allWinners.Bronze, allWinners.Gold, allWinners.Silver]
  );

  useEffect(() => {
    dispatch(fetchWinners());
  }, [dispatch]);

  const listSwitchIconClass = useCallback(
    (i: number) =>
      clsx({
        [classes.selectedViewIcon]: i === winnerListView,
      }),
    [classes.selectedViewIcon, winnerListView]
  );

  const renderFinalistsList = useMemo(() => {
    if (winners.length === 0) return <AnimatedSvg2 />;
    if (winnerListView === 0) return <FinalistsListBig winners={winners} />;
    if (winnerListView === 1) return <FinalistsListTable winners={winners} />;
    return null;
  }, [winnerListView, winners]);

  return (
    <AnimatedPaper className={classes.root}>
      <Box>
        <Box position="relative">
          <Typography variant="h4" align="center" className={classes.title}>
            {t('Our Finalists this season')}
          </Typography>
          <Box
            position={{ lg: "absolute" }}
            top={{ lg: 0 }}
            right={{ lg: -60 }}
            display="flex"
            justifyContent="center"
          >
            <IconButton
              color="inherit"
              className={listSwitchIconClass(0)}
              onClick={() => setWinnerListView(0)}
            >
              <ViewListRounded />
            </IconButton>
            <IconButton
              color="inherit"
              className={listSwitchIconClass(1)}
              onClick={() => setWinnerListView(1)}
            >
              <ViewModuleRounded />
            </IconButton>
          </Box>
        </Box>
        <Box color="#576078" mt={2} px={{ lg: 10 }}>
          <Typography align="center" color="inherit">
            {t('leagueWinnersMsg')}
          </Typography>
        </Box>
      </Box>
      <Box pt={4}>{renderFinalistsList}</Box>
    </AnimatedPaper>
  );
}
