import React from "react";
import { animated, useSpring } from "react-spring";

import {
  makeStyles,
  createStyles,
  Theme,
  Typography,
  Box,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: "1 1 0",
      padding: theme.spacing(1),
      cursor: "pointer",
    },
    imgContainer: {
      padding: theme.spacing(1),
    },
    fontSemiBold: {
      fontWeight: 600,
    },
  })
);

interface LeagueStatsItemProps extends React.HTMLProps<HTMLDivElement> {
  src: string;
  label: string;
  value: number;
}

const AnimBox = animated(Box);

const LeagueStatsItem: React.FC<LeagueStatsItemProps> = (props) => {
  const classes = useStyles();
  const { src, label, value, ...otherProps } = props;

  const [animatedProps, set] = useSpring(() => ({ transform: "scaleX(0)" }));

  return (
    <div
      className={classes.root}
      onMouseMove={() => set({ transform: "scaleX(1)" })}
      onMouseLeave={() => set({ transform: "scaleX(0)" })}
      {...otherProps}
    >
      <div className={classes.imgContainer}>
        <img src={src} alt="" width="100%" />
      </div>
      <Typography
        variant="body2"
        align="center"
        className={classes.fontSemiBold}
      >
        {label.split(/\s+/).map((x, i) => (
          <React.Fragment key={i}>
            {x}
            {i === 0 ? <br /> : " "}
          </React.Fragment>
        ))}
      </Typography>
      <Typography variant="h5" align="center" className={classes.fontSemiBold}>
        {value}
      </Typography>
      <AnimBox height={4} bgcolor="#3BE4CE" mx={1} style={animatedProps} />
    </div>
  );
};

export default React.memo(LeagueStatsItem);
