import React from "react";

import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function Twitter(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 21.003 20.998">
      {/* <svg xmlns="http://www.w3.org/2000/svg" width="21.003" height="20.998"> */}
      <g transform="translate(0.5 0.5)">
        <path
          stroke="rgba(0,0,0,0)"
          strokeMiterlimit="10"
          d="M1581,17552a10,10,0,1,1,10-10A10.013,10.013,0,0,1,1581,17552Zm-5.479-6.408h0a6.508,6.508,0,0,0,3.519,1.029,6.261,6.261,0,0,0,4.85-2.127,6.745,6.745,0,0,0,1.682-4.4c0-.1,0-.189-.008-.285v-.006a4.789,4.789,0,0,0,1.145-1.193,4.64,4.64,0,0,1-1.317.361,2.324,2.324,0,0,0,1.009-1.273,4.6,4.6,0,0,1-1.458.561,2.3,2.3,0,0,0-3.912,2.092,6.558,6.558,0,0,1-4.731-2.4,2.255,2.255,0,0,0-.308,1.154,2.278,2.278,0,0,0,1.018,1.906,2.217,2.217,0,0,1-1.04-.285v.031a2.3,2.3,0,0,0,1.841,2.25,2.432,2.432,0,0,1-.6.08,1.854,1.854,0,0,1-.432-.047,2.291,2.291,0,0,0,2.145,1.6,4.588,4.588,0,0,1-2.85.986A4.331,4.331,0,0,1,1575.523,17545.592Z"
          transform="translate(-1570.998 -17532.002)"
        />
      </g>
      {/* </svg> */}
    </SvgIcon>
  );
}
