import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  makeStyles,
  createStyles,
  Theme,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  IconButton,
  Box,
} from "@material-ui/core";
import { HighlightOff, StarBorder as StarBorderIcon } from "@material-ui/icons";
import { Rating } from "@material-ui/lab";

import { useTypedSelector as useSelector } from "../../../../_redux/rootReducer";
import { getBaSeasonTemplate } from "../../../../_redux/auth";
import { makeTemplateForSeasonSelector } from "../../../../_redux/auth/selectors";

import RefShareImg from "./RefShare.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    shareItem: {
      textDecoration: "none",
      "&:hover": { textDecoration: "underline" },
    },
    fontBold: {
      fontWeight: theme.typography.fontWeightBold,
    },
    socialIconContainer: {
      backgroundColor: "#060220",
      height: 74,
      width: 74,
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: theme.spacing(1),
    },
    rating: { color: theme.palette.secondary.main },
  })
);

interface InfoDialogProps {
  open: boolean;
  onClose: () => void;
  selectedSeason: string;
}

const InfoDialog: React.FC<InfoDialogProps> = (props) => {
  const { open, onClose, selectedSeason } = props;
  const classes = useStyles();
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation("referrals");

  const selectTemplateForSeason = useMemo(makeTemplateForSeasonSelector, []);

  const baTemplate = useSelector((state) =>
    selectTemplateForSeason(state, selectedSeason)
  );

  useEffect(() => {
    if (selectedSeason) dispatch(getBaSeasonTemplate(selectedSeason));
  }, [selectedSeason, dispatch]);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const selectIndex = useCallback((i: number) => setSelectedIndex(i), []);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <Box display="flex" justifyContent="flex-end">
        <IconButton onClick={onClose} color="secondary">
          <HighlightOff fontSize="large" />
        </IconButton>
      </Box>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <img src={RefShareImg} alt="" width="100%" />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography
              variant="h4"
              align="center"
              className={classes.fontBold}
            >
              {t("translation:Business Associate")}
            </Typography>
            <Typography color="textSecondary">{t("baInfoOne")}</Typography>
            <Typography color="textSecondary">{t("baInfoTwo")}</Typography>
          </Grid>
        </Grid>
        <Box my={3} display="flex" flexWrap="wrap" justifyContent="center">
          {baTemplate.map((x, i) => (
            <Box
              padding={2}
              key={x._id}
              borderBottom={selectedIndex === i ? "1px solid #3BE4CE" : "unset"}
              onClick={() => selectIndex(i)}
              style={{ cursor: "pointer" }}
            >
              <Box
                height={52}
                width={52}
                borderRadius={26}
                bgcolor="#3BE4CE"
                display="flex"
                alignItems="center"
                justifyContent="center"
                color="#130D4A"
              >
                <Typography
                  variant="body2"
                  color="inherit"
                  className={classes.fontBold}
                >
                  {x.Hlevel}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
        {/* Rating */}
        <Box my={2} display="flex" flexDirection="column" alignItems="center">
          <Rating
            size="small"
            value={selectedIndex + 1}
            readOnly
            emptyIcon={<StarBorderIcon fontSize="inherit" />}
            classes={{ root: classes.rating, iconEmpty: classes.rating }}
          />
          <Typography variant="body2">
            {t("countStar", { count: selectedIndex + 1 })}
          </Typography>
        </Box>
        {/* Required Referrals */}
        <Box display="flex" alignItems="center" justifyContent="center">
          <Box bgcolor="#3BE4CE" borderRadius={6} px={2} py={1} color="#130D4A">
            <Typography variant="body2" color="inherit">
              {t("directReferrals", {
                referrals: baTemplate[selectedIndex]?.Hlevel.replace(/\D/, ""),
              })}
            </Typography>
          </Box>
        </Box>
        {/* Bonus Percentage */}
        <Box display="flex" alignItems="center" justifyContent="center" my={2}>
          <Box maxWidth={320} display="flex">
            <Box flex="1 1 0">
              <Typography variant="body2" align="center">
                {t("Additional")}
              </Typography>
              <Typography
                variant="h4"
                align="center"
                className={classes.fontBold}
              >
                {baTemplate[selectedIndex]?.percentage * 100}%
              </Typography>
              <Typography variant="body2" align="center">
                {t("Bonus")}
              </Typography>
            </Box>
            <Box flex="1 1 0">
              <Typography variant="body2" align="center">
                {t("baInfoThree", {
                  referrals: baTemplate[selectedIndex]?.Hlevel.replace(
                    /\D/,
                    ""
                  ),
                  percentage: baTemplate[selectedIndex]?.percentage * 100,
                  user1:
                    Number(
                      baTemplate[selectedIndex - 1]?.Hlevel.replace(/\D/, "") ??
                        0
                    ) + 1,
                  user2: baTemplate[selectedIndex]?.Hlevel.replace(/\D/, ""),
                })}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box my={2}>
          <Typography
            className={classes.fontBold}
            color="secondary"
            align="center"
            variant="h6"
          >
            {t("translation:Note")}:
          </Typography>
          <Typography align="center" variant="body2">
            {t("baInfoNote")}
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(InfoDialog);
