import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

// MUI
import {
  Box,
  Button,
  InputAdornment,
  Link,
  Typography,
} from "@material-ui/core";
// Icons
import PersonOutline from "@material-ui/icons/PersonOutline";

import { requestPasswordReset } from "../../_redux/auth";

import { TextField as CustomTextField } from "../../Components";

import useStylesBase from "./styles";

export default function RequestPasswordReset() {
  const classesBase = useStylesBase();
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation("auth");

  const [errors, setErrors] = useState<ObjectStringKey>({});

  const form = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email(
          t("translation:invalidMessage", { field: t("translation:email") })
        )
        .required(
          t("translation:requiredMessage", { field: t("translation:email") })
        ),
    }),
    onSubmit: async (values) => {
      // console.log({ values });
      const error = await dispatch(requestPasswordReset(values));
      console.log(error);
      if (error) return setErrors(error);
      history.push({
        pathname: "/auth/reset-password-confirm",
        state: { email: values.email },
      });
    },
  });

  const {
    errors: formErrors,
    touched,
    getFieldProps,
    handleSubmit,
    isSubmitting,
  } = form;
  const getError = (field: keyof typeof form.initialValues) =>
    !!(formErrors[field] && touched[field]);
  const getHelperText = (field: keyof typeof form.initialValues) =>
    touched[field] && formErrors[field];

  return (
    <form className={classesBase.root} onSubmit={handleSubmit} noValidate>
      <Typography className={classesBase.title} component="h1" align="center">
        {t("resetPassword")}
      </Typography>
      {/* Code */}
      <CustomTextField
        fullWidth
        variant="outlined"
        margin="dense"
        placeholder={t("translation:email")}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PersonOutline />
            </InputAdornment>
          ),
        }}
        error={getError("email")}
        helperText={getHelperText("email")}
        {...getFieldProps("email")}
      />
      <Box my={4} width="100%">
        {/* Non Field Error */}
        <Typography align="center" color="error" variant="body2">
          {errors.msg}
        </Typography>
        {/* Submit */}
        <Button
          variant="contained"
          color="primary"
          disableElevation
          className={classesBase.button}
          type="submit"
          disabled={isSubmitting}
        >
          {t("sendEmail")}
        </Button>
      </Box>
      <Typography align="center">
        <Link
          align="center"
          component={RouterLink}
          to="/auth/signin"
          className={classesBase.link}
        >
          {t("backToLogin")}
        </Link>
      </Typography>
    </form>
  );
}
