import React from "react";
import { useTranslation } from "react-i18next";

import {
  createStyles,
  makeStyles,
  Theme,
  Typography,
  Box,
  Avatar,
} from "@material-ui/core";
import {
  PersonRounded,
  StarBorder as StarBorderIcon,
} from "@material-ui/icons";
import { Rating } from "@material-ui/lab";

import { useTypedSelector as useSelector } from "../../_redux/rootReducer";
import {
  selectCurrentSeasonReferrals,
  selectUserImg,
  selectUserName,
  selectCurrentSeasonBaData,
} from "../../_redux/auth/selectors";

import BaIcon from "../../_assets/Images/BaIcon.svg";
import TierAvatars from "./TierAvatars";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatarContainer: {
      margin: theme.spacing(2, 0),
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      height: 240,
      width: 240,
      backgroundColor: "#fff",
    },
    fontBold: {
      fontWeight: theme.typography.fontWeightBold,
    },
    rating: {
      color: theme.palette.secondary.main,
    },
  })
);

const GridOne: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation("referrals");

  const referrals = useSelector(selectCurrentSeasonReferrals);
  const userImg = useSelector(selectUserImg);
  const userName = useSelector(selectUserName);
  const baData = useSelector(selectCurrentSeasonBaData);

  return (
    <div>
      {/* user Avatar */}
      <div className={classes.avatarContainer}>
        <Avatar className={classes.avatar} src={userImg} alt="User profile pic">
          <Box color="#FC6E4F">
            <PersonRounded fontSize="large" />
          </Box>
        </Avatar>
      </div>
      {/* User details */}
      <div>
        <Typography color="textSecondary">{t('translation:Welcome')}</Typography>
        <Box display="flex" alignItems="center">
          <Typography
            color="textPrimary"
            className={classes.fontBold}
            variant="h4"
          >
            {userName}
          </Typography>
          <Box ml={1}>
            <img src={BaIcon} alt="" />
          </Box>
        </Box>
        <Rating
          value={baData?.HLevel.length ?? 0}
          readOnly
          emptyIcon={<StarBorderIcon fontSize="inherit" />}
          classes={{ root: classes.rating, iconEmpty: classes.rating }}
        />
        <Typography color="secondary" className={classes.fontBold} variant="h6">
          {t('translation:Business Associate')}
        </Typography>
      </div>
      {/* Tier avatars */}
      <Box my={2}>
        <TierAvatars
          title={t('TierCount', {count: 1})}
          rate={referrals?.level1.length ?? 0}
          referrals={referrals?.level1 ?? []}
        />
      </Box>
    </div>
  );
};

export default React.memo(GridOne);
