import React, { useState } from "react";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Box, Grid, Paper } from "@material-ui/core";

import LeagueTabPanel from "./components/LeagueTabPanel";
import Summary from "./components/Summary";
import LiveLeagueHeader from "./components/LiveLeagueHeader";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableCard: {
      backgroundColor: "#4043FF66",
      borderRadius: 5,
      height: "100%",
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(2, 3),
      [theme.breakpoints.up("lg")]: {
        marginTop: theme.spacing(2),
      },
    },
  })
);

type LeagueCategory = "Bronze" | "Silver" | "Gold";

export default function GridTwo() {
  const classes = useStyles();
  const [category, setCategory] = useState<LeagueCategory>("Bronze");

  const handleCategoryChange = (
    event: React.ChangeEvent<{}>,
    newValue: LeagueCategory
  ) => {
    setCategory(newValue);
  };

  return (
    <Box clone order={{ xs: 3, lg: 2 }} display="flex" flexDirection="column">
      <Grid item xs={12} lg={6}>
        {/* hide on screens smaller than lg */}
        <Box display={{ xs: "none", lg: "block" }}>
          <Summary />
        </Box>
        <Paper className={classes.tableCard}>
          <LiveLeagueHeader
            category={category}
            handleCategoryChange={handleCategoryChange}
          />
          <LeagueTabPanel category={category} />
        </Paper>
      </Grid>
    </Box>
  );
}
