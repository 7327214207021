import React from "react";
import { useTranslation } from "react-i18next";

import { makeStyles, createStyles, Theme, TableCell } from "@material-ui/core";
import { TableHead } from "../../../../Components/Table";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cell: {
      fontWeight: 600,
    },
  })
);

function Head() {
  const classes = useStyles();
  const { t } = useTranslation("referrals");

  return (
    <TableHead>
      <TableCell size="small" className={classes.cell}>
        {t('translation:Details')}
      </TableCell>
      <TableCell size="small" className={classes.cell} align="right">
        {t('Sale')}
      </TableCell>
      <TableCell size="small" className={classes.cell} align="center">
      {t('Additional')} %
      </TableCell>
      <TableCell size="small" className={classes.cell} align="right">
        {t('Earned')}
      </TableCell>
      <TableCell size="small" className={classes.cell}>
        {t('Status')}
      </TableCell>
    </TableHead>
  );
}

export default React.memo(Head);
