import React, { Suspense, useEffect } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import ReactGA from "react-ga";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { enUS, jaJP } from "@material-ui/core/locale";

import i18n from "./i18n";
import AuthRoutes from "./AuthRoutes";
import AppRoutes from "./AppRoutes";

const locales = { enUS, ja: jaJP };

const theme = createMuiTheme(
  {
    typography: {
      fontFamily: "'Montserrat', sans-serif",
    },
    palette: {
      primary: {
        main: "#4043FF",
        light: "#8570ff",
        dark: "#0018ca",
        contrastText: "#fff",
      },
      secondary: {
        main: "#3be4ce",
        light: "#7cffff",
        dark: "#00b19d",
        contrastText: "#fff",
      },
      error: {
        main: "#fc6e4f",
        light: "#ffa07c",
        dark: "#c33d25",
        contrastText: "#fff",
      },
      background: {
        paper: "#130D4A",
      },
      text: {
        primary: "#ffffff",
        secondary: "#ffffffb3",
      },
    },
  },
  locales[i18n.language] ?? enUS
);

function App() {
  const history = useHistory();

  useEffect(() => {
    history.listen((location, action) => {
      // location is an object like window.location
      // console.log(action, location.pathname, location.state);
      ReactGA.set({ page: location.pathname }); // Update the user's current page
      ReactGA.pageview(location.pathname);
    });
  }, [history]);

  return (
    <ThemeProvider theme={theme}>
      <Switch>
        <Route path="/auth">
          <AuthRoutes />
        </Route>
        <Route path="/">
          <AppRoutes />
        </Route>
      </Switch>
    </ThemeProvider>
  );
}

export default function () {
  return (
    <Suspense fallback="loading">
      <App />
    </Suspense>
  );
}
