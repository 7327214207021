import React, { useCallback, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

// MUI
import {
  makeStyles,
  createStyles,
  Theme,
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  Toolbar,
  useMediaQuery,
} from "@material-ui/core";
import { Menu as MenuIcon, Close as CloseIcon } from "@material-ui/icons";

import Logo from "../../_assets/Logo.svg";
import LogoSmall from "../../_assets/LogoSmall.svg";

import ProfileSidebar from "./ProfileSidebar";

const HEADER_HEIGHT = 83;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: HEADER_HEIGHT,
    },
    appBar: {
      backgroundColor: "#130D4A",
      zIndex: theme.zIndex.drawer + 1,
    },
    toolbar: {
      height: HEADER_HEIGHT,
    },
    grow: {
      flexGrow: 1,
    },
    navLink: {
      margin: theme.spacing(2),
      paddingBottom: theme.spacing(1),
      fontSize: "0.875rem",
      fontFamily: "Montserrat",
      letterSpacing: -0.54,
      color: "#fff",
      opacity: 0.3,
      textDecoration: "none",
      display: "inline-flex",
      alignItems: "center",
      [theme.breakpoints.up("md")]: {
        paddingBottom: theme.spacing(2),
        margin: theme.spacing(0, 2),
      },
    },
    navLinkActive: {
      opacity: 1,
      borderBottom: "2px solid #4043FF",
    },
    navLinkLive: {
      backgroundColor: theme.palette.secondary.main,
      height: 6,
      width: 6,
      borderRadius: 3,
      marginLeft: theme.spacing(0.5),
    },
    rightContainer: {
      display: "flex",
      alignItems: "center",
    },
    drawerPaper: {
      width: 400,
      maxWidth: "100vw",
      backgroundColor: "#130D4A",
    },
    divider: {
      backgroundColor: theme.palette.primary.main,
    },
  })
);

const links = [
  { key: "dashboard", to: "/dashboard", exact: true, live: false },
  { key: "leagues", to: "/leagues", exact: false, live: true },
  { key: "leaderboard", to: "/leaderboard", exact: true, live: false },
  { key: "referrals", to: "/referrals", exact: true, live: false },
  { key: "accounts", to: "/account", exact: true, live: false },
];

const Header: React.FC = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const { t } = useTranslation("header");

  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleDrawerOpen = useCallback(() => setDrawerOpen(true), []);
  const handleDrawerClose = useCallback(() => setDrawerOpen(false), []);

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <div>
          <Toolbar className={classes.toolbar}>
            <a href="https://playctl.net">
              <img src={isMobile ? LogoSmall : Logo} alt="Play Ctl Logo" />
            </a>
            <div className={classes.grow} />
            {/* hide on screens smaller than md */}
            <Box
              display={{ xs: "none", md: "flex" }}
              marginX={2}
              alignItems="baseline"
            >
              {links.map((link) => (
                <NavLink
                  key={link.key}
                  to={link.to}
                  className={classes.navLink}
                  activeClassName={classes.navLinkActive}
                  exact={link.exact}
                >
                  {t(`nav.${link.key}`)}
                  {link.live && <div className={classes.navLinkLive} />}
                </NavLink>
              ))}
            </Box>
            {/* Right */}
            <div className={classes.rightContainer}>
              <ProfileSidebar />
              <Box ml={2} display={{ md: "none" }}>
                <IconButton color="secondary" onClick={handleDrawerOpen}>
                  <MenuIcon />
                </IconButton>
              </Box>
            </div>
          </Toolbar>
        </div>
      </AppBar>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerClose}
        classes={{ paper: classes.drawerPaper }}
        keepMounted
      >
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <IconButton color="secondary" onClick={handleDrawerClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider className={classes.divider} />
        {links.map((link) => (
          <NavLink
            key={link.key}
            to={link.to}
            onClick={handleDrawerClose}
            className={classes.navLink}
            activeClassName={classes.navLinkActive}
            exact
          >
            {t(`nav.${link.key}`)}
          </NavLink>
        ))}
      </Drawer>
    </div>
  );
};

export default React.memo(Header);
