import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { createStyles, withStyles, Theme, Tabs, Tab } from "@material-ui/core";

import { fetchWinners } from "../../../_redux/leaderboard";

import CustomPaper from "../CustomPaper";
import CategoryTabPanel from "./CategoryTabPanel";
import ListLeagues from "./ListLeagues";

function a11yProps(index: number) {
  return {
    id: `completed-leagues-tab-${index}`,
    "aria-controls": `completed-leagues-tabpanel-${index}`,
  };
}

export default function Completed() {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation("league");

  useEffect(() => {
    dispatch(fetchWinners());
  }, [dispatch]);

  const [value, setValue] = useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <CustomPaper title={t("Completed Leagues")} indicatorColor="#E4E7EA">
      <StyledTabs value={value} onChange={handleChange}>
        <StyledTab label={t("translation:Bronze")} {...a11yProps(0)} />
        <StyledTab label={t("translation:Silver")} {...a11yProps(1)} />
        <StyledTab label={t("translation:Gold")} {...a11yProps(2)} />
      </StyledTabs>
      <CategoryTabPanel value={value} index={0}>
        <ListLeagues category="Bronze" />
      </CategoryTabPanel>
      <CategoryTabPanel value={value} index={1}>
        <ListLeagues category="Silver" />
      </CategoryTabPanel>
      <CategoryTabPanel value={value} index={2}>
        <ListLeagues category="Gold" />
      </CategoryTabPanel>
    </CustomPaper>
  );
}

export const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: "none",
      color: "#3BE4CE",
      fontWeight: theme.typography.fontWeightLight,
      fontSize: "1rem",
      minWidth: "unset",
      padding: theme.spacing(0, 2),
      "&:focus": {
        opacity: 1,
      },
    },
    selected: {
      fontWeight: theme.typography.fontWeightBold,
      color: "#4043FF",
    },
  })
)(Tab);

export const StyledTabs = withStyles({
  indicator: {
    backgroundColor: "#3BE4CE",
  },
})(Tabs);
