import React, { useCallback, useMemo } from "react";
import { format, isFuture, Locale } from "date-fns";
import { enUS, ja } from "date-fns/locale";
import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";

import {
  Theme,
  createStyles,
  makeStyles,
  withStyles,
  Box,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  Divider,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMoreRounded";

import { BuyTicketFormValues } from "./data";

import { makeLeaguesForCategorySelector } from "../../../_redux/league/selectors";
import { useTypedSelector } from "../../../_redux/rootReducer";

import DateCell from "./components/DateCell";

const locales: { [key: string]: Locale } = { enUS, ja };

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    subtitle: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 600,
    },
    detailTitle: {
      color: "#FC6E4F",
    },
  })
);
interface ILeagueCalendarProps {
  title: "Bronze" | "Silver" | "Gold";
  form: FormikProps<BuyTicketFormValues>;
}

function LeagueCalendar(props: ILeagueCalendarProps) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { title, form } = props;
  const { category, date: formDate } = form.values;

  const onCellClick = useCallback(
    (date: Date) => {
      form.setFieldValue("category", title);
      form.setFieldValue("date", date);
    },
    [form, title]
  );

  const selectLeaguesForCategory = useMemo(makeLeaguesForCategorySelector, []);

  const leagues = useTypedSelector((state) =>
    selectLeaguesForCategory(state, title)
  );

  const remainingLeagues = useMemo(
    () =>
      leagues?.leagueLookup.filter((x) => isFuture(new Date(x.startDate)))
        .length,
    [leagues]
  );

  const dates = useMemo(() => {
    if (!leagues) return [];
    const startDate = new Date(leagues.leagueLookup[0].startDate);
    const endDate = new Date(leagues.leagueLookup.slice(-1)[0].endDate);
    const dates = [];
    for (
      var d = new Date(startDate);
      d <= new Date(endDate);
      d.setDate(d.getDate() + 1)
    ) {
      dates.push(new Date(d));
    }
    return dates;
  }, [leagues]);

  const detailTitle = useMemo(() => {
    if (!leagues) return "";
    const startDate = new Date(leagues.leagueLookup[0].startDate);
    const endDate = new Date(leagues.leagueLookup.slice(-1)[0].endDate);
    const formatString = "do MMM";
    const locale = locales[i18n.language] ?? enUS;
    return `${format(startDate, formatString, { locale })} - ${format(
      endDate,
      formatString,
      { locale }
    )}`;
  }, [i18n.language, leagues]);

  return (
    <div className={classes.root}>
      <CustomExpansionPanel>
        <CustomExpansionPanelSummary
          expandIcon={<ExpandMoreIcon fontSize="large" />}
        >
          <Typography>{t(title)}</Typography>
          <Typography className={classes.subtitle}>
            {t("league:remainingLeagues", { count: remainingLeagues ?? 0 })}
          </Typography>
        </CustomExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Box width="100%">
            <Typography className={classes.detailTitle}>
              {detailTitle}
            </Typography>
            <Box my={2} bgcolor="#FC6E4F">
              <Divider />
            </Box>
            <Box display="flex" flexWrap="wrap">
              {dates.map((date) => (
                <DateCell
                  key={date.getTime()}
                  date={date}
                  category={title}
                  onClick={onCellClick}
                  selected={
                    category === title && date.getTime() === formDate.getTime()
                  }
                />
              ))}
            </Box>
          </Box>
        </ExpansionPanelDetails>
      </CustomExpansionPanel>
    </div>
  );
}

const CustomExpansionPanel = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#060220",
      color: "white",
    },
  })
)(ExpansionPanel);

const CustomExpansionPanelSummary = withStyles((theme: Theme) =>
  createStyles({
    content: {
      flexDirection: "column",
    },
    expandIcon: {
      color: "#FC6E4F",
    },
  })
)(ExpansionPanelSummary);

export default React.memo(LeagueCalendar);
