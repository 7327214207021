import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { Grid } from "@material-ui/core";

import { getReferrals } from "../../_redux/auth";

import GridOne from "./GridOne";
import GridTwo from "../Referrals/BusinessAssociate";

const BusinessAssociate: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  useEffect(() => {
    dispatch(getReferrals());
  }, [dispatch]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        <GridOne />
      </Grid>
      <Grid item xs={12} md={8}>
        <GridTwo />
      </Grid>
    </Grid>
  );
};

export default React.memo(BusinessAssociate);
