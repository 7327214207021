import React, { useMemo } from "react";
import { animated, useSpring } from "react-spring";
import { useTranslation } from "react-i18next";

import {
  makeStyles,
  Theme,
  createStyles,
  Box,
  Typography,
  TypographyProps,
} from "@material-ui/core";
import { differenceInSeconds } from "date-fns";
import { format } from "../../_utils/date";
import { league } from "../../_redux/league/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    lableText: {
      fontWeight: "bold",
    },
  })
);

function HeaderLeft(props: { strategy: league }) {
  const classes = useStyles();
  const { strategy } = props;
  const { t } = useTranslation("league");

  const remainingSecs = useMemo(
    () => differenceInSeconds(new Date(strategy.endDate), new Date()),
    [strategy.endDate]
  );

  return (
    <Box display="flex">
      <Box mr={2}>
        <Typography className={classes.lableText}>
          {t(`translation:${strategy.leagueCategory}`)}
        </Typography>
        <Typography className={classes.lableText}>{t("Ending in")}:</Typography>
      </Box>
      <Box>
        <Typography color="secondary" className={classes.lableText}>
          {format(new Date(strategy.endDate), "d MMM yyyy")}
        </Typography>
        {/* Ending in */}
        <Box color="#FC6E4F">
          <AnimatedTimer sec={remainingSecs} className={classes.lableText} />
        </Box>
      </Box>
    </Box>
  );
}

export default React.memo(HeaderLeft);

const formatSecsToHumanRedable = (secs: number): string => {
  let finalString = "";
  let diffSec = ~~secs;
  const diffHours = ~~(diffSec / (60 * 60));
  diffSec = diffSec % (60 * 60);
  if (diffHours > 0 || !!finalString)
    finalString += `${diffHours} hr${diffHours > 1 ? "s" : ""} :  `;
  // minutes
  const diffMins = ~~(diffSec / 60);
  diffSec = diffSec % 60;
  if (diffMins > 0 || !!finalString)
    finalString += `${diffMins} min${diffMins > 1 ? "s" : ""} :  `;
  // secs
  finalString += `${diffSec} sec`;
  return finalString;
};

const AnimatedTypography = animated(Typography);

interface AnimatedTimerProps extends TypographyProps {
  sec: number;
}
const AnimatedTimer: React.FC<AnimatedTimerProps> = ({
  sec,
  ...otherProps
}) => {
  const animatedProps = useSpring({
    sec: 0,
    from: { sec },
    config: { duration: sec * 1000 },
  });

  return (
    <AnimatedTypography {...otherProps}>
      {animatedProps.sec.interpolate(formatSecsToHumanRedable)}
    </AnimatedTypography>
  );
};
