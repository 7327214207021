import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";

import data from "./data.json";

const AnimatedSvg: React.FC = () => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (ref.current) {
      const animation = lottie.loadAnimation({
        container: ref.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: data,
      });
      return () => {
        animation.destroy();
      };
    }
  });

  return <div ref={ref} />;
};

export default React.memo(AnimatedSvg);
