import React from "react";
import { useTranslation } from "react-i18next";

import {
  createStyles,
  Theme,
  makeStyles,
  Avatar,
  Box,
  Typography,
} from "@material-ui/core";
import { Star } from "@material-ui/icons";

import { LeagueWinnerItem } from "../../../_redux/leaderboard/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      height: 150,
      width: 150,
      margin: theme.spacing(1, 'auto'),
    },
    avatarDescription: {
      background:
        "transparent linear-gradient(180deg, #3BE4CE 0%, #4043FF 100%) 0% 0% no-repeat padding-box",
      borderRadius: 6,
      margin: theme.spacing(0, 2),
      transform: "translateY(-16px)",
    },
    tradesWonText: {
      fontWeight: theme.typography.fontWeightBold,
    },
  })
);

interface IUserItemProps {
  user: LeagueWinnerItem;
}

const UserItem = (props: IUserItemProps) => {
  const { user } = props;
  const classes = useStyles();
  const { t } = useTranslation("leaderboard");

  return (
    <Box flex='1 1 0' mx={1} maxWidth={170}>
      <Avatar src={user.userId.profileImg} className={classes.avatar} />
      <div className={classes.avatarDescription}>
        <Box fontSize={26} display="flex" justifyContent="center">
          <Star fontSize="inherit" />
        </Box>
        <Typography align="center" variant="body2">
          {t('Net Worth')}
        </Typography>
        <Typography
          align="center"
          variant="h6"
          className={classes.tradesWonText}
        >
          {user.netWorth}
        </Typography>
      </div>
      <Typography align="center" variant="h6">
        {user.userId.name}
      </Typography>
      <Box color="#FC6E4F" mt={1}>
        <Typography align="center" variant="body2" color="inherit">
          {t(`translation:${user.leagueCategory}`)}
        </Typography>
      </Box>
    </Box>
  );
};

export default React.memo(UserItem);
