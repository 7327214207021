import React from "react";
import { useTranslation } from "react-i18next";

import { Box } from "@material-ui/core";
import CustomPaper from "../CustomPaper";
import LeagueCard from "./LeagueCard";

export default function Live() {
  const { t } = useTranslation("league");
  return (
    <CustomPaper title={t("Live Leagues")} indicatorColor="#29CB97">
      <Box mb={4}>
        <LeagueCard category="Bronze" />
      </Box>
      <Box mb={4}>
        <LeagueCard category="Silver" />
      </Box>
      <Box>
        <LeagueCard category="Gold" />
      </Box>
    </CustomPaper>
  );
}
