import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Box, Typography } from "@material-ui/core";
import { Star } from "@material-ui/icons";

import { LeaderboardItem } from "../../_redux/leaderboard/types";

import { AnimatedPaper } from "../../Components";

const useStyles = makeStyles((theme) => ({
  root: (props: UserItemProps) => ({
    background: props.user.rank === 1 ? "#3BE4CE" : "#4043FF",
    borderRadius: 6,
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1, 0.5),
    color: "#fff",
  }),
  avatar: {
    height: 30,
    width: 30,
    marginRight: theme.spacing(1),
    border: "1px solid white",
  },
  content: (props: UserItemProps) => ({
    flexGrow: 1,
    color: props.user.rank === 1 ? "#090E18" : "inherit",
  }),
  fontBold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  fontLight: {
    fontWeight: theme.typography.fontWeightLight,
  },
}));

interface UserItemProps {
  user: LeaderboardItem;
}

export default function UserItem(props: UserItemProps) {
  const { user } = props;
  const classes = useStyles(props);
  const { t } = useTranslation("leaderboard");

  const isWinner = useMemo(() => user.rank === 1, [user.rank]);

  return (
    <AnimatedPaper className={classes.root}>
      <Box
        fontSize={26}
        display="flex"
        alignItems="center"
        justifyContent="center"
        minWidth={30}
      >
        {isWinner && <Star fontSize="inherit" />}
      </Box>
      <Avatar
        src={user.userId.profileImg}
        alt={user.userId.name}
        className={classes.avatar}
      >
        {user.userId.name?.[0] ?? ""}
      </Avatar>
      <div className={classes.content}>
        <Typography variant="body2" className={classes.fontBold}>
          {user.userId.name}
        </Typography>
        <Typography variant="caption" className={classes.fontLight}>
          {t('Net Worth')}: {user.netWorth}
        </Typography>
      </div>
      <Box margin={1}>
        <Typography variant="h6" className={classes.fontBold}>
          {user.rank}
        </Typography>
      </Box>
    </AnimatedPaper>
  );
}
