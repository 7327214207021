import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import Cropper from "react-easy-crop";
import getCroppedImg, { ICroppedAreaPixels } from "./cropImage";
import { useTranslation } from "react-i18next";

import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core";

import { updateProfile } from "../../_redux/auth";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cropperContainer: {
      marginTop: theme.spacing(2),
      height: 300,
      width: "100%",
      position: "relative",
    },
  })
);

interface ImageUploadProps {
  open: boolean;
  handleClose: () => void;
}

const ImageUpload: React.FC<ImageUploadProps> = (props) => {
  const { open, handleClose } = props;
  const classes = useStyles();
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();

  const [helperText, setHelperText] = useState("");
  const [imgUrl, setImgUrl] = useState<string | null>(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [
    croppedAreaPixels,
    setCroppedAreaPixels,
  ] = useState<ICroppedAreaPixels | null>(null);
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    // console.log(croppedArea, croppedAreaPixels);
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0])
      return setImgUrl(URL.createObjectURL(e.target.files[0]));
    setImgUrl(null);
  }, []);

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      // console.log({ imgUrl, croppedAreaPixels });
      if (!(imgUrl && croppedAreaPixels))
        return setHelperText("Please select an Image");
      const croppedImage = await getCroppedImg(imgUrl, croppedAreaPixels);
      // console.log("donee", { croppedImage });
      if (!croppedImage) return setHelperText("Error in cropping Image");
      const formData = new FormData();
      formData.append(
        "img",
        croppedImage,
        `profile-pic-${getRandomString()}.jpeg`
      );
      const error = await dispatch(updateProfile(formData));
      // console.log(error);
      if (!error) return handleClose();
      setHelperText(error.msg ?? "Unknown Server Error");
    },
    [croppedAreaPixels, dispatch, handleClose, imgUrl]
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle id="form-dialog-title">{t('Upload Picture')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('Select an Image')}</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Profile Picture"
            type="file"
            inputProps={{ accept: "image/*" }}
            fullWidth
            color="secondary"
            onChange={handleChange}
            error={!!helperText}
            helperText={helperText}
          />
          {imgUrl && (
            <div className={classes.cropperContainer}>
              <Cropper
                cropShape="round"
                image={imgUrl}
                crop={crop}
                zoom={zoom}
                aspect={1 / 1}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('Cancel')}
          </Button>
          <Button color="primary" type="submit">
            {t('Save')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
const getRandomString = () => Math.random().toString(36).substr(2, 5);

export default React.memo(ImageUpload);
