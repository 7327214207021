import React from "react";

import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function UserIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16.011"
        height="17.009"
        viewBox="0 0 16.011 17.009"
      > */}
      <path
        fillRule="evenodd"
        d="M17,17.147a.833.833,0,1,1-1.665,0A6.444,6.444,0,0,0,9,10.617a6.444,6.444,0,0,0-6.341,6.53A.846.846,0,0,1,1.825,18a.845.845,0,0,1-.832-.858A8.222,8.222,0,0,1,6.815,9.221,4.441,4.441,0,0,1,4.7,5.426,4.373,4.373,0,0,1,9,1a4.372,4.372,0,0,1,4.3,4.43,4.443,4.443,0,0,1-2.117,3.795A8.222,8.222,0,0,1,17,17.147ZM9,2.712A2.678,2.678,0,0,0,6.363,5.426a2.637,2.637,0,1,0,5.271,0A2.678,2.678,0,0,0,9,2.712Z"
        transform="translate(-0.993 -0.996)"
      />
    </SvgIcon>
  );
}
