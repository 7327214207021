import React, { useMemo } from "react";
import { compareDesc } from "date-fns";

import { Box } from "@material-ui/core";

import LeagueCard from "./LeagueCard";

import { useTypedSelector } from "../../../_redux/rootReducer";
import { makeCompletedStrategiesForCategorySelector } from "../../../_redux/league/selectors";
import { league, LeagueCategory } from "../../../_redux/league/types";

import Mascot4 from "../../../_assets/Images/Mascot4.svg";

function ListLeagues(props: { category: LeagueCategory }) {
  const { category } = props;

  const selectCompletedStrategies = useMemo(
    makeCompletedStrategiesForCategorySelector,
    []
  );
  const strats = useTypedSelector((state) =>
    selectCompletedStrategies(state, category)
  );
  const sortedStrats = useMemo(() => {
    return strats
      .sort((a, b) => compareDesc(new Date(a.startDate), new Date(b.startDate)))
      .reduce((acc: league[], crr) => {
        if (acc.findIndex((x) => x.startDate === crr.startDate) === -1)
          return [...acc, crr];
        return acc;
      }, []);
  }, [strats]);

  if (sortedStrats.length === 0)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        my={{ xs: 2, sm: 4 }}
      >
        <img src={Mascot4} alt="NO data display" />
      </Box>
    );
  return (
    <>
      {sortedStrats.map((strat) => (
        <Box my={2} key={strat._id}>
          <LeagueCard strategy={strat} />
        </Box>
      ))}
    </>
  );
}

export default React.memo(ListLeagues);
