import React from "react";

import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function ChartLine(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 36 27">
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="27"
        viewBox="0 0 36 27"
      > */}
        <path
          fill="snow"
          d="M34.875,27H4.5V5.625A1.125,1.125,0,0,0,3.375,4.5H1.125A1.125,1.125,0,0,0,0,5.625V29.25A2.25,2.25,0,0,0,2.25,31.5H34.875A1.125,1.125,0,0,0,36,30.375v-2.25A1.125,1.125,0,0,0,34.875,27ZM32.625,6.75h-8.3a1.687,1.687,0,0,0-1.193,2.881l2.278,2.278-5.159,5.16L15.091,11.91a2.249,2.249,0,0,0-3.182,0l-4.83,4.83a1.125,1.125,0,0,0,0,1.591l1.59,1.59a1.125,1.125,0,0,0,1.591,0L13.5,16.682l5.159,5.159a2.249,2.249,0,0,0,3.182,0l6.75-6.75,2.278,2.278a1.687,1.687,0,0,0,2.881-1.193v-8.3A1.124,1.124,0,0,0,32.625,6.75Z"
          transform="translate(0 -4.5)"
        />
      {/* </svg> */}
    </SvgIcon>
  );
}
