import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import clsx from "clsx";
import { FormikProps } from "formik";
import { format } from "../../../_utils/date";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  createStyles,
  makeStyles,
  Theme,
  Box,
  Divider,
  Paper,
  TextField,
  Typography,
  withStyles,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons";

import { BuyTicketFormValues } from "./data";

import { useTypedSelector } from "../../../_redux/rootReducer";
import { getWallet } from "../../../_redux/auth";
import { makeLeaguesForCategorySelector } from "../../../_redux/league/selectors";
import {
  selectWalletBalance,
  selectBtcRate,
  selectOrderId,
} from "../../../_redux/auth/selectors";

import { Dialog } from "../../../Components";
import Button from "./components/Button";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { width: "100%" },
    paperRoot: {
      color: "white",
      backgroundColor: "#060220",
      padding: theme.spacing(3),
    },
    semiBold: {
      fontWeight: 600,
    },
    section: {
      padding: theme.spacing(2),
      margin: theme.spacing(2, 0),
    },
    selectedSection: {
      border: "1px solid #4043FF",
      borderRadius: 4,
      backgroundColor: "#130D4A",
      position: "relative",
    },
    selectedSectionIcon: {
      position: "absolute",
      top: -12,
      right: -12,
    },
    selectedSectionIconBg: {
      position: "absolute",
      top: -10,
      right: -10,
      height: 20,
      width: 20,
      backgroundColor: "white",
      borderRadius: "50%",
    },
  })
);

function Checkout(props: { form: FormikProps<BuyTicketFormValues> }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation("league");

  const { form } = props;
  const { setFieldValue, handleSubmit, values, isSubmitting } = form;
  const { category, date, leagueId } = values;

  const [loading, setLoading] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const openConfirmation = useCallback(() => setConfirmationOpen(true), []);
  const closeConfirmation = useCallback(() => setConfirmationOpen(false), []);

  const fetchWalletData = useCallback(async () => {
    setLoading(true);
    await dispatch(getWallet());
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    fetchWalletData();
  }, [fetchWalletData]);

  const selectLeagues = useMemo(makeLeaguesForCategorySelector, []);
  const leagues = useTypedSelector((state) =>
    selectLeagues(state, category || "Bronze")
  );
  const walletBalance = useTypedSelector(selectWalletBalance);
  const orderId = useTypedSelector(selectOrderId);
  const btcRate = useTypedSelector(selectBtcRate);

  const startEndDateDidplay = useMemo(() => {
    if (!leagues) return "";
    const startDate = new Date(leagues.leagueLookup[0].startDate);
    const endDate = new Date(leagues.leagueLookup.slice(-1)[0].endDate);
    const formatString = "do MMM";
    return `${format(startDate, formatString)} - ${format(
      endDate,
      formatString
    )}`;
  }, [leagues]);

  const [usdPrice, btcPrice] = useMemo(() => {
    const price = Number(leagues?.leaguesSeason.price);
    const totalPrice = leagueId.length * price;
    return [totalPrice, btcRate ? usdToBtc(totalPrice, btcRate) : ""];
  }, [btcRate, leagueId.length, leagues]);

  const onSubmit = useCallback(() => {
    setFieldValue("OrderUniqueID", orderId, false);
    handleSubmit();
  }, [handleSubmit, orderId, setFieldValue]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paperRoot}>
        <Box display="flex" justifyContent="space-between">
          <Typography>{category}</Typography>
          <Typography>
            {t("Order ID")} {!loading && orderId}
          </Typography>
        </Box>
        <Typography variant="body2" className={classes.semiBold}>
          {t("League")} {startEndDateDidplay}
        </Typography>
        <Box width="100%" color="#FC6E4F" mt={2}>
          <Typography>{t("Payment")}</Typography>
          <Box my={2} bgcolor="#FC6E4F">
            <Divider />
          </Box>
        </Box>
        {/* Pay By Wallet */}
        <PaymentMethodContainer selected>
          <Box display="flex" justifyContent="space-between" my={1}>
            <Typography variant="body2" className={classes.semiBold}>
              {t("Pay from my wallet")}
            </Typography>
            <Typography
              color="secondary"
              variant="body2"
              className={classes.semiBold}
            >
              {t("Available BTC")}
              <br />
              {walletBalance?.toFixed(8)}
            </Typography>
          </Box>
          <Typography variant="body2">
            {usdPrice} USD = {btcPrice} BTC ({t("translation:Approx")})
          </Typography>
        </PaymentMethodContainer>
        {/* Pay by Card */}
        <PaymentMethodContainer>
          <CustomTextField
            label={t("Card number")}
            fullWidth
            autoComplete="cc-number"
          />
          <Box display="flex">
            <Box mr={0.5}>
              <CustomTextField
                label={t("Expired Date")}
                fullWidth
                autoComplete="cc-exp"
              />
            </Box>
            <Box ml={0.5}>
              <CustomTextField
                label={t("CVV")}
                fullWidth
                autoComplete="cc-csc"
              />
            </Box>
          </Box>
          <CustomTextField
            label={t("Card holder")}
            fullWidth
            autoComplete="cc-name"
          />
        </PaymentMethodContainer>
      </Paper>
      <Box my={2} width="100%">
        <Button
          color="secondary"
          variant="contained"
          size="large"
          type="submit"
          disabled={isSubmitting}
          onClick={openConfirmation}
        >
          {t("Pay")} (${usdPrice})
        </Button>
      </Box>
      <Dialog
        open={confirmationOpen}
        title={t("Confirm Ticket Purchase")}
        handleClose={closeConfirmation}
      >
        <DialogContent dividers>
          <Typography>
            {t('Buy')}{" "}
            <Typography component="span" color="secondary">
              {leagueId.length} <span>{t(`translation:${category}`)}</span>
            </Typography>{" "}
            {t('league ticket for')}{" "}
            <Typography component="span" color="secondary">
              {format(date, "do MMM")}
            </Typography>
          </Typography>
          <Box>
            <Typography variant="body2" color="error">
              {/* @ts-ignore */}
              {form.errors.msg}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={closeConfirmation}
            variant="contained"
            color="primary"
          >
            {t("translation:Cancel")}
          </Button>
          <Button
            autoFocus
            onClick={onSubmit}
            variant="contained"
            color="secondary"
          >
            {t("translation:Proceed")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default React.memo(Checkout);

const PaymentMethodContainer = (props: {
  children: ReactNode;
  selected?: boolean;
}) => {
  const { children, selected = false } = props;
  const classes = useStyles();
  const rootClass = useMemo(
    () => clsx(classes.section, { [classes.selectedSection]: selected }),
    [classes.section, classes.selectedSection, selected]
  );

  return (
    <div className={rootClass}>
      {selected && (
        <>
          <div className={classes.selectedSectionIconBg} />
          <CheckCircle
            color="secondary"
            className={classes.selectedSectionIcon}
          />
        </>
      )}
      {children}
    </div>
  );
};

const CustomTextField = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiInputBase-root": {
        color: "white",
        "&:before": {
          borderBottom: "1px solid rgba(255, 255, 255, 0.42)",
        },
        "&:hover:before": {
          borderBottom: "2px solid rgba(255, 255, 255, 0.87)",
        },
        "&:after": {
          borderBottom: "2px solid #fff",
        },
      },
      "& label, & label.Mui-focused": {
        color: "white",
      },
    },
  })
)(TextField);

const usdToBtc = (usd: number, rate: number) => (usd / rate).toFixed(8);
