import React, { useMemo } from "react";
import {
  Button,
  ButtonProps,
  createStyles,
  Theme,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import { animated, useSpring } from "react-spring";

const AButton = animated(Button);

interface CustomButtonProps extends ButtonProps {
  shape: "square" | "rounded" | "pill";
  color: "default" | "inherit" | "primary" | "secondary";
}

const getBorderRadiusForShape = (
  shape: "square" | "rounded" | "pill"
): number => {
  if (shape === "square") return 0;
  if (shape === "rounded") return 10;
  if (shape === "pill") return 9999;
  return 0;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: (props: CustomButtonProps) => ({
      display: "inline-flex",
      position: "relative",
      overflow: "hidden",
      borderRadius: getBorderRadiusForShape(props.shape),
    }),
    root: (props: CustomButtonProps) => ({
      fontWeight: "bold",
      zIndex: 1,
      borderRadius: getBorderRadiusForShape(props.shape),
    }),
    outlined: (props: CustomButtonProps) => ({
      border: `2px solid ${
        props.color === "inherit" ? "currentColor" : "white"
      }`,
      "&:hover": {
        border: `2px solid ${
          props.color === "inherit" ? "currentColor" : "white"
        }`,
        "& > .MuiButton-label": {
          color: props.color === "inherit" ? "#fff" : undefined,
        },
      },
    }),
    outlinedPrimary: {
      border: `2px solid ${theme.palette.primary.main} !important`,
      "&:hover": {
        border: `2px solid ${theme.palette.primary.main} !important`,
      },
    },
    outlinedSecondary: {
      border: `2px solid ${theme.palette.secondary.main} !important`,
      "&:hover": {
        border: `2px solid ${theme.palette.secondary.main} !important`,
      },
    },
    background: (props: CustomButtonProps) => ({
      content: '""',
      borderRadius: getBorderRadiusForShape(props.shape),
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor:
        props.color === "primary"
          ? theme.palette.primary.main
          : props.color === "secondary"
          ? theme.palette.secondary.main
          : props.color === "inherit"
          ? "currentColor"
          : "#fff",
    }),
  })
);
const CustomButton = (props: CustomButtonProps) => {
  const { children, shape, style, ...otherProps } = props;
  const classes = useStyles(props);
  const theme = useTheme();

  const [textColor, hoverColor] = useMemo((): [string, string] => {
    if (otherProps.color === "primary")
      return [theme.palette.primary.main, theme.palette.primary.contrastText];
    if (otherProps.color === "secondary")
      return [
        theme.palette.secondary.main,
        theme.palette.secondary.contrastText,
      ];
    if (otherProps.color === "default") return ["#fff", "#000"];
    if (otherProps.color === "inherit") return ["inherit", "inherit"];
    return ["#000", "#fff"];
  }, [
    otherProps.color,
    theme.palette.primary.contrastText,
    theme.palette.primary.main,
    theme.palette.secondary.contrastText,
    theme.palette.secondary.main,
  ]);

  const [{ x, color }, set] = useSpring(() => ({ x: -100, color: textColor }));

  return (
    <animated.div
      className={classes.wrapper}
      onMouseMove={() => set({ x: 0, color: hoverColor })}
      onMouseLeave={() => set({ x: -100, color: textColor })}
      style={style}
    >
      <animated.div
        className={classes.background}
        style={{ transform: x.interpolate((v) => `translateX(${v}%)`) }}
      />
      <AButton
        variant="outlined"
        classes={{
          root: classes.root,
          outlined: classes.outlined,
          outlinedPrimary: classes.outlinedPrimary,
          outlinedSecondary: classes.outlinedSecondary,
        }}
        disableElevation
        style={{ color, ...style }}
        {...otherProps}
      >
        {children}
      </AButton>
    </animated.div>
  );
};

CustomButton.defaultProps = {
  shape: "rounded",
  color: "default",
};

export default React.memo(CustomButton);
