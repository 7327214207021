import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import Color from "color";
import { useTranslation } from "react-i18next";

import {
  makeStyles,
  createStyles,
  Theme,
  Typography,
  Box,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { PeopleRounded, ExpandMore } from "@material-ui/icons";

import { useTypedSelector as useSelector } from "../../_redux/rootReducer";
import { makeReferralsForSeasonSelector } from "../../_redux/auth/selectors";
import { selectCurrentSeason } from "../../_redux/league/selectors";
import { getReferrals } from "../../_redux/auth";

import { Paper, Tabs, Tab } from "../../Components";

import ReferralsTable from "./ReferralsTable";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
      minHeight: "100%",
      display: "flex",
      flexDirection: "column",
      backgroundColor: Color(theme.palette.primary.main).alpha(0.4).string(),
    },
    sectionHeader: {
      color: "#fff",
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
      marginBottom: theme.spacing(4),
    },
    menuButton: {
      // display: "flex",
      // alignItems: "center",
      cursor: "pointer",
    },
    titleText: { fontWeight: theme.typography.fontWeightBold },
    tabRoot: {
      fontWeight: theme.typography.fontWeightMedium,
      color: "#576078",
    },
    tabSelected: {
      fontWeight: theme.typography.fontWeightBold,
      color: "white",
    },
    tabPanel: { height: "100%" },
  })
);

const GridTwo: React.FC = () => {
  const classes = useStyles();
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation("referrals");

  const selectReferrals = useMemo(makeReferralsForSeasonSelector, []);

  const currentSeason = useSelector(selectCurrentSeason);
  const [selectedSeason, setSelectedSeason] = useState(
    currentSeason?.season ?? ""
  );
  const referrals = useSelector((state) =>
    selectReferrals(state, selectedSeason)
  );

  useEffect(() => {
    if (currentSeason) setSelectedSeason(currentSeason.season);
  }, [currentSeason]);
  useEffect(() => {
    dispatch(getReferrals(selectedSeason));
  }, [dispatch, selectedSeason]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = useCallback(() => setAnchorEl(null), []);

  const handleClick = useCallback(
    (season: string) => {
      setSelectedSeason(season);
      setAnchorEl(null);
    },
    [setSelectedSeason]
  );

  const [value, setValue] = useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const seasons = useMemo(() => {
    const res = [];
    if (currentSeason) {
      for (var i = 1; i <= currentSeason.counter; i++) {
        res.push(`Season${i}`);
      }
    }
    return res;
  }, [currentSeason]);
  const totalIncome = useMemo(() => {
    if (!referrals) return 0;
    const keys = Object.keys(referrals) as "level1"[];
    return keys.reduce((acc: number, crr) => {
      acc += referrals[crr].map((x) => x.amount).reduce((a, b) => a + b, 0);
      return acc;
    }, 0);
  }, [referrals]);

  return (
    <Paper className={classes.root}>
      <div className={classes.sectionHeader}>
        {/* balance */}
        <Box display="flex" alignItems="center">
          <PeopleRounded fontSize="large" />
          <Box ml={1}>
            <Typography variant="body2" color="textSecondary">
              {t('Total Referral Income')}
            </Typography>
            <Typography variant="h5" className={classes.titleText}>
              ${+totalIncome.toFixed(2)}
            </Typography>
          </Box>
        </Box>
        {/* actions */}
        <div onClick={handleOpen} className={classes.menuButton}>
          <Typography variant="body2" color="textSecondary" align="right">
            {t('translation:Season')}
          </Typography>
          <Box display="flex" alignItems="center">
            <Typography variant="h5" className={classes.titleText}>
              {selectedSeason}
            </Typography>
            <ExpandMore color="secondary" fontSize="small" />
          </Box>
        </div>
        <Menu
          id="category-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {seasons.map((season) => (
            <MenuItem
              key={season}
              disabled={season === selectedSeason}
              selected={season === selectedSeason}
              onClick={(e) => handleClick(season)}
            >
              {season}
            </MenuItem>
          ))}
        </Menu>
      </div>
      <Tabs value={value} onChange={handleChange}>
        <Tab
          label={t("TierCount", {count: 1})}
          classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
        />
        <Tab
          label={t("TierCount", {count: 2})}
          classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
        />
        <Tab
          label={t("TierCount", {count: 3})}
          classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
        />
      </Tabs>
      <div role="tabpanel" hidden={value !== 0} className={classes.tabPanel}>
        <ReferralsTable rate={12.5} referrals={referrals?.level1 ?? []} />
      </div>
      <div role="tabpanel" hidden={value !== 1} className={classes.tabPanel}>
        <ReferralsTable rate={7.5} referrals={referrals?.level2 ?? []} />
      </div>
      <div role="tabpanel" hidden={value !== 2} className={classes.tabPanel}>
        <ReferralsTable rate={5} referrals={referrals?.level3 ?? []} />
      </div>
    </Paper>
  );
};

export default React.memo(GridTwo);
