import React from "react";
import { useTranslation } from "react-i18next";

import {
  makeStyles,
  createStyles,
  Theme,
  Typography,
  DialogContent,
} from "@material-ui/core";

import { Dialog } from "../../Components";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: theme.typography.fontWeightBold,
      color: "#fff",
    },
    content: {
      minWidth: 300,
    },
  })
);

interface WithdrawFundsDialogProps {
  open: boolean;
  handleClose: () => void;
}

const WithdrawFundsDialog: React.FC<WithdrawFundsDialogProps> = (props) => {
  const classes = useStyles();
  const { open, handleClose } = props;
  const { t } = useTranslation();

  return (
    <Dialog open={open} title={t("Withdraw Funds")} handleClose={handleClose}>
      <DialogContent className={classes.content}>
        <Typography align="center" className={classes.title}>
          Coming soon
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(WithdrawFundsDialog);
