import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
export default withStyles((theme) => ({
  root: {
    borderRadius: 5,
    "& .MuiInputBase-root": {
      color: "white",
      background: "#302C5D 0% 0% no-repeat padding-box",
    },
    "& label, & label.Mui-focused": {
      color: "white",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
    },
  },
}))(TextField);
