import React from "react";
import { useTranslation } from "react-i18next";

import { makeStyles, createStyles, Theme, TableCell } from "@material-ui/core";

import { TableHead } from "../../../Components/Table";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cell: {
      fontWeight: 600,
    },
  })
);

function Head() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableCell size="small" className={classes.cell}>
        {t('Details')}
      </TableCell>
      <TableCell size="small" className={classes.cell} align="right">
        {t('Amount / Status')}
      </TableCell>
      <TableCell size="small" className={classes.cell}>
        {t('Date')}
      </TableCell>
    </TableHead>
  );
}

export default React.memo(Head);
