import { createStyles, withStyles, Theme, Button } from "@material-ui/core";

export default withStyles((theme: Theme) =>
  createStyles({
    root: { width: "100%" },
    contained: {
      "&.Mui-disabled": {
        backgroundColor: "#060220",
        color: "#ffffff80",
      },
    },
  })
)(Button);
