import React from "react";

import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export default function Facebook(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 20 19.961">
      {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19.961"> */}
      <path d="M20,9.981a10,10,0,1,0-10,9.981l.176,0V12.189H8.027V9.69h2.148V7.851a3,3,0,0,1,3.211-3.293,17.72,17.72,0,0,1,1.926.1V6.885H14c-1.037,0-1.238.492-1.238,1.214V9.69h2.48l-.323,2.5H12.76v7.386A9.989,9.989,0,0,0,20,9.981Z" />
      {/* </svg> */}
    </SvgIcon>
  );
}
