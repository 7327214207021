import React from "react";

import { Grid } from "@material-ui/core";

import LeaderboardCard from "../../Common/LeaderBoard";
import Finalists from "./Finalists";

export default function Leaderboard() {
  return (
    <Grid container spacing={5}>
      <Grid item xs={12} sm={6} lg={9}>
        <Finalists />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <LeaderboardCard />
      </Grid>
    </Grid>
  );
}
