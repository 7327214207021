import React, { useCallback, useMemo } from "react";
import { FormikProps } from "formik";
import { format } from "../../../_utils/date";
import { useTranslation } from "react-i18next";

import {
  createStyles,
  makeStyles,
  withStyles,
  Theme,
  Avatar,
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  Paper,
  Typography,
  CheckboxProps,
} from "@material-ui/core";
import { CheckCircle, RadioButtonUnchecked } from "@material-ui/icons";

import {
  makeStrategiesForDateAndCategory,
  makeLeaguesForCategorySelector,
  makeJoinedStrategiesForDateAndCategory,
} from "../../../_redux/league/selectors";
import { useTypedSelector } from "../../../_redux/rootReducer";

import { BuyTicketFormValues } from "./data";

import GoldImg from "../../../_assets/Images/Gold.png";
import GoldImg2x from "../../../_assets/Images/Gold@2x.png";
import SilverImg from "../../../_assets/Images/Silver.png";
import SilverImg2x from "../../../_assets/Images/Silver@2x.png";
import BronzeImg from "../../../_assets/Images/Bronze.png";
import BronzeImg2x from "../../../_assets/Images/Bronze@2x.png";

import Button from "./components/Button";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { width: "100%" },
    paperRoot: {
      color: "white",
      backgroundColor: "#060220",
      padding: theme.spacing(3),
    },
    subtitle: {
      fontWeight: 600,
    },
    formControlRoot: {
      width: "100%",
    },
    formControlLabelRoot: { margin: theme.spacing(2, 0) },
    formControlLabelLabel: {
      width: "100%",
      "&.Mui-disabled": { color: "#ffffff80" },
    },
  })
);

function StrategySelect(props: { form: FormikProps<BuyTicketFormValues> }) {
  const { form } = props;
  const { category, date, leagueId } = form.values;
  const classes = useStyles();
  const { t } = useTranslation("league");

  const selectLeagues = useMemo(makeLeaguesForCategorySelector, []);
  const selectStarategies = useMemo(makeStrategiesForDateAndCategory, []);
  const selectJoinedStrategies = useMemo(
    makeJoinedStrategiesForDateAndCategory,
    []
  );
  const leagues = useTypedSelector((state) =>
    selectLeagues(state, category || "Bronze")
  );
  const strats = useTypedSelector((state) =>
    selectStarategies(state, category || "Bronze", date)
  );
  const joinedStrats = useTypedSelector((state) =>
    selectJoinedStrategies(state, category || "Bronze", date)
  );

  const handleStrategySelect = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
      if (e.target.checked) form.setFieldValue("leagueId", [...leagueId, id]);
      else
        form.setFieldValue(
          "leagueId",
          leagueId.filter((x) => x !== id)
        );
    },
    [form, leagueId]
  );

  const handleNext = useCallback(() => {
    if (leagueId.length === 0) return;
    form.setFieldValue("activeStep", 2);
  }, [form, leagueId.length]);

  const startEndDateDidplay = useMemo(() => {
    if (!leagues) return "";
    const startDate = new Date(leagues.leagueLookup[0].startDate);
    const endDate = new Date(leagues.leagueLookup.slice(-1)[0].endDate);
    const formatString = "do MMM";
    return `${format(startDate, formatString)} - ${format(
      endDate,
      formatString
    )}`;
  }, [leagues]);

  const selectedDateDisplay = useMemo(
    () => format(form.values.date, "do MMMM"),
    [form.values.date]
  );

  const checkoutBtnLabel = useMemo(() => {
    let label = t("Checkout");
    const price = Number(leagues?.leaguesSeason.price);
    const totalPrice = leagueId.length * price;
    if (totalPrice > 0) label += ` ($${totalPrice})`;
    return label;
  }, [leagueId.length, leagues, t]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paperRoot}>
        <Typography>{t(`translation:${category}`)}</Typography>
        <Typography variant="body2" className={classes.subtitle}>
          {t("League")} {startEndDateDidplay}
        </Typography>
        <Box width="100%" color="#FC6E4F" mt={2}>
          <Typography>{selectedDateDisplay}</Typography>
          <Box my={2} bgcolor="#FC6E4F">
            <Divider />
          </Box>
        </Box>
        <FormControl
          component="fieldset"
          className={classes.formControlRoot}
          name="leagueId"
        >
          <FormGroup>
            {strats?.map((strategy, i) => {
              const subtitle = joinedStrats.some((x) => x._id === strategy._id)
                ? t("Already playing Strategy", {
                    strategy: `${t("translation:strategy")} ${
                      strategy.strategies
                    }`,
                  })
                : t("Play in Strategy", {
                    strategy: `${t("translation:strategy")} ${
                      strategy.strategies
                    }`,
                  });

              return (
                <FormControlLabel
                  disabled={joinedStrats.some((x) => x._id === strategy._id)}
                  key={strategy._id}
                  labelPlacement="start"
                  classes={{
                    root: classes.formControlLabelRoot,
                    label: classes.formControlLabelLabel,
                  }}
                  label={
                    <CustomLabel
                      title={"" + strategy.strategies}
                      subtitle={subtitle}
                      league={category || "Bronze"}
                      price={Number(strategy.price)}
                    />
                  }
                  control={
                    <CustomCheckbox
                      checked={
                        leagueId.includes(strategy._id) ||
                        joinedStrats.some((x) => x._id === strategy._id)
                      }
                      onChange={(e) => handleStrategySelect(e, strategy._id)}
                      icon={<RadioButtonUnchecked />}
                      checkedIcon={<CheckCircle />}
                    />
                  }
                />
              );
            })}
          </FormGroup>
          <FormHelperText></FormHelperText>
        </FormControl>
      </Paper>
      <Box my={2} width="100%">
        <Button
          color="secondary"
          variant="contained"
          size="large"
          onClick={handleNext}
          disabled={leagueId.length === 0}
        >
          {checkoutBtnLabel}
        </Button>
      </Box>
    </div>
  );
}
export default React.memo(StrategySelect);

const CustomCheckbox = withStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.secondary.dark,
      "&.Mui-disabled": { color: `${theme.palette.secondary.dark}80` },
    },
    checked: {
      color: theme.palette.secondary.main,
    },
  })
)((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const CustomLabel = (props: {
  title: string;
  subtitle: string;
  price: number;
  league: "Gold" | "Silver" | "Bronze";
}) => {
  const { title, subtitle, price, league } = props;
  const { t } = useTranslation();

  const [src, srcSet] = useMemo(() => {
    if (league === "Gold") return [GoldImg, `${GoldImg}, ${GoldImg2x} 2x`];
    if (league === "Silver")
      return [SilverImg, `${SilverImg}, ${SilverImg2x} 2x`];
    if (league === "Bronze")
      return [BronzeImg, `${BronzeImg}, ${BronzeImg2x} 2x`];
    return [undefined, undefined];
  }, [league]);

  return (
    <Box display="flex" alignItems="center">
      <Avatar srcSet={srcSet} src={src} alt="Gold League Logo" />
      <Box ml={2} flexGrow={1}>
        <Typography variant="body2" style={{ fontWeight: 600 }}>
          {t("strategy")} {title}
        </Typography>
        <Typography variant="caption">{subtitle}</Typography>
      </Box>
      <Typography>${price.toFixed(2)}</Typography>
    </Box>
  );
};
