import React, { ReactNode } from "react";

import {
  makeStyles,
  createStyles,
  Theme,
  TableHead,
  TableRow,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableHeadRoot: {
      backgroundColor: theme.palette.secondary.main,
      "& > tr > *": {
        backgroundColor: theme.palette.secondary.main,
        color: "#000000de",
        borderBottom: "unset",
      },
    },
  })
);

const Head: React.FC<{ children: ReactNode }> = (props) => {
  const classes = useStyles();

  return (
    <TableHead classes={{ root: classes.tableHeadRoot }}>
      <TableRow>{props.children}</TableRow>
    </TableHead>
  );
};

export default React.memo(Head);
