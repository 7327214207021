import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: 240,
      [theme.breakpoints.up("sm")]: {
        width: 360,
      },
    },
    title: {
      fontWeight: "bold",
      fontSize: 36,
      letterSpacing: -0.5,
      marginBottom: theme.spacing(5),
    },
    button: {
      width: "100%",
      backgroundColor: "#4DA1FF",
      borderColor: "#4DA1FF",
      "&:hover": {
        backgroundColor: "#4DA1FF",
        borderColor: "#4DA1FF",
      },
      "&:active": {
        backgroundColor: "#4DA1FF",
        borderColor: "#4DA1FF",
      },
      "&:disabled": {
        backgroundColor: "#325b88",
        borderColor: "#325b88",
        color: "white",
      },
    },
    link: {
      marginLeft: theme.spacing(1),
      color: "#3BE4CE",
      cursor: "pointer",
    },
  })
);
