import React from "react";
import { format } from "../../../../_utils/date";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import {
  makeStyles,
  createStyles,
  Theme,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";

import { Transaction } from "../../../../_redux/league/types";

interface RowProps {
  row: Transaction;
  index: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: (props: RowProps) => ({
      backgroundColor: props.index % 2 === 0 ? "#06022066" : "#130D4A66",
      "& > *": {
        borderBottom: "unset",
      },
    }),
    titleText: {
      color: "#fff",
      fontWeight: theme.typography.fontWeightBold,
    },
    subtitleText: {
      color: "#ffffff80",
    },
    capitalize: { textTransform: "capitalize" },
  })
);

const Row: React.FC<RowProps> = (props) => {
  const { row } = props;
  const classes = useStyles(props);
  const { t } = useTranslation("league");

  return (
    <TableRow className={classes.root}>
      {/* Details */}
      <TableCell component="th" scope="row">
        <Typography className={clsx(classes.titleText, classes.capitalize)}>
          {row.type === "CBC" ? t("Change Base Currency") : row.type}
        </Typography>
        <Typography variant="body2" className={classes.subtitleText}>
          ID#{row.transactionId}
        </Typography>
      </TableCell>
      {/* Amount / Status */}
      <TableCell align="right">
        <Typography className={classes.titleText}>{row.currency}</Typography>
        <Typography
          variant="body2"
          color={row.type === "buy" ? "error" : "secondary"}
        >
          {row.type === "buy" ? "-" : "+"}
          {row.invest ?? row.withdraw} {row.baseCurrency}
        </Typography>
      </TableCell>
      {/* Date */}
      <TableCell>
        <Typography className={classes.titleText}>
          {format(new Date(row.createdAt), "dd MMM yyyy")}
        </Typography>
        <Typography variant="body2" className={classes.subtitleText}>
          {t('Self')}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default React.memo(Row);
