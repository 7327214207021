import React from "react";

import {
  makeStyles,
  createStyles,
  Theme,
  Dialog,
  IconButton,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";

import Upcoming from "../Leagues/Upcoming";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconButton: {
      position: "absolute",
      right: 0,
    },
  })
);

interface BuyTicketDialogProps {
  open: boolean;
  handleClose: () => void;
}

const BuyTicketDialog: React.FC<BuyTicketDialogProps> = (props) => {
  const classes = useStyles();
  const { open, handleClose } = props;

  return (
    <Dialog open={open} onClose={handleClose}>
      <div>
        <IconButton
          className={classes.iconButton}
          onClick={handleClose}
          color="secondary"
        >
          <Close />
        </IconButton>
        <Upcoming />
      </div>
    </Dialog>
  );
};

export default React.memo(BuyTicketDialog);
