import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

// MUI
import { Box, Typography, InputAdornment, Button } from "@material-ui/core";
// Icons
import VpnKeyOutlined from "@material-ui/icons/VpnKeyOutlined";

import { verify } from "../../_redux/auth";

import { TextField as CustomTextField } from "../../Components";

import useStylesBase from "./styles";

export default function Verify() {
  const classesBase = useStylesBase();
  const location = useLocation<{ email?: string }>();
  const dispatch: AppDispatch = useDispatch();
  const [errors, setErrors] = useState<ObjectStringKey>({});
  const { t } = useTranslation("auth");

  const form = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema: Yup.object().shape({
      code: Yup.string().required(
        t("translation:requiredMessage", { field: t("verificationCode") })
      ),
    }),
    onSubmit: async (values) => {
      // console.log({ values });
      const error = await dispatch(verify(values));
      // console.log(error);
      if (error) setErrors(error);
    },
  });

  const {
    errors: formErrors,
    touched,
    getFieldProps,
    handleSubmit,
    isSubmitting,
  } = form;
  const getError = (field: keyof typeof form.initialValues) =>
    !!(formErrors[field] && touched[field]);
  const getHelperText = (field: keyof typeof form.initialValues) =>
    touched[field] && formErrors[field];

  return (
    <form className={classesBase.root} onSubmit={handleSubmit} noValidate>
      <Typography className={classesBase.title} component="h1" align="center">
        {t("translation:verify")}
      </Typography>
      {/* Code */}
      <CustomTextField
        fullWidth
        variant="outlined"
        margin="dense"
        placeholder={t("verificationCode")}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <VpnKeyOutlined />
            </InputAdornment>
          ),
        }}
        error={getError("code")}
        helperText={getHelperText("code")}
        {...getFieldProps("code")}
      />
      {/* Resend */}
      {location.state?.email && (
        <>
          <Typography align="center">
            {t("codeSent")} {location.state.email}
          </Typography>
          <Typography align="center">
            {t("notReceiveCode")}
            <Typography component="span" className={classesBase.link}>
              {t("resend")}
            </Typography>
          </Typography>
        </>
      )}
      {/* Non Field Error */}
      <Box my={4} width="100%">
        <Typography align="center" color="error" variant="body2">
          {errors.msg}
        </Typography>
        {/* Submit */}
        <Button
          variant="contained"
          color="primary"
          disableElevation
          className={classesBase.button}
          type="submit"
          disabled={isSubmitting}
        >
          {t("signUp")}
        </Button>
      </Box>
    </form>
  );
}
